import React, { Fragment } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';
import moment from 'moment';
import 'moment/locale/ru';

registerLocale('ru', ru);

class SelectDateTime extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isShow: false };

    this.changeShow = this.changeShow.bind(this);
  }

  changeShow() {
    this.setState((prevState) => ({
      isShow: !prevState.isShow
    }));
  }

  render() {
    const { date, onChangeDate } = this.props;
    const { isShow } = this.state;
    const dateFormat = moment(date).format('LL');

    return (
      <Fragment>
        {isShow && (
          <DatePicker
            selected={date}
            onChange={onChangeDate}
            withPortal
            minDate={new Date()}
            locale="ru"
            onClickOutside={this.changeShow}
            inline>
            <div className="selectTimeWrap">
              <button className="buttonGreen small" onClick={this.changeShow}>
                Сохранить и закрыть
              </button>
            </div>
          </DatePicker>
        )}
        <div className="formGroupVk">
          <span className="labelVk">Дата заказа</span>
          <button
            type="button"
            name="title"
            className="buttonInputVK"
            onClick={this.changeShow}
          >
            {dateFormat}
          </button>
        </div>
      </Fragment>
    );
  }
}

export default SelectDateTime;
