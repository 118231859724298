import React, { Fragment } from 'react';
import { IOS, platform } from '@vkontakte/vkui';

import { ReactComponent as Logo } from '../../img/logo.svg';
import { ReactComponent as FilterIcon } from '../../img/filter.svg';

const osname = platform();

const PanelHeader = ({ children, left, big, bigSelectCity = false }) => {
  let styleFake = 'fbNoneLogo';
  if (big || bigSelectCity) {
    styleFake = 'fbLogoBig';
  }
  return (
    <Fragment>
      <div className={`${styleFake}`}/>
      {osname === IOS && (
        <div className="fbIos"/>
      )}
      <div className="panelHeaderWrap">
        <div className="panelHeaderFixed">
          {osname === IOS && (
            <div className="iOSHeader"/>
          )}
          {(big || bigSelectCity) && (
            <div className="panelHeaderLogo">
              <Logo height="50"/>
            </div>
          )}
          <div className="panelHeader">
            <div className="panelHeaderLeftBlock">
              {!!left && left}
            </div>
            <div className="panelHeaderTitle">
              {children}
            </div>
            <div className="panelHeaderRightBlock">
              {big && (
                <label className="filterHeader" htmlFor="hmtLeft">
                  <FilterIcon width="24" height="24"/>
                </label>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PanelHeader;
