import React from 'react';
import PropTypes from 'prop-types';
import OpenDialogLayout from './OpenDialogLayout';
import '../../scss/MainStyle.scss';
import apiList from '../../utils/apiMethods';
import {checkLocalStorage} from '../../utils/checkLocalStorage';


class OpenDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogList: null,
            userId: '',
            inputText: '',
            imgFile: null,
            isSendNewMessage: false
        };
        this.getDialogList = this.getDialogList.bind(this);
        this.getLastDialogList = this.getLastDialogList.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.onChange = this.onChange.bind(this);
        this.sendPicture = this.sendPicture.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.storageUpdate = this.storageUpdate.bind(this);
        this.interval = null;
        this.scrollbarRef = React.createRef();
    }

    componentDidMount() {
        const {loadSpinner} = this.props;
        loadSpinner(true);
        this.getDialogList();
        if (checkLocalStorage('userId')) {
            this.setState({
                userId: localStorage.getItem('userId')
            });
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        console.log('Останавливаем интервал');
    }

    handleKeyPress(e, idOrder) {
        e.preventDefault();
        this.sendMessage(idOrder);
    }

    getLastDialogList() {
        const {dialogList, isSendNewMessage} = this.state;
        const {uploadAmountMessages} = this.props;
        apiList.getLastChatList(dialogList.requestId, dialogList.lastId)
            .then(value => {
                console.log('value', value.chatList);
                if (!!value.chatList && value.chatList.length !== 0) {
                    let {dialogList} = this.state;
                    let {chatList} = dialogList;

                    chatList = {
                        ...chatList,
                        [value.lastId.toString()]: value.chatList[value.lastId.toString()]
                    };
                    dialogList = {
                        ...dialogList,
                        chatList,
                        lastId: value.lastId,
                    };
                    this.storageUpdate(dialogList.requestId, uploadAmountMessages, value.lastId);
                    this.setState({
                        dialogList
                    }, () => {
                      if (true)
                        this.scrollbarRef.current.scrollToBottom();
                    });
                }
            });
    }

    storageUpdate(idOrder, uploadAmountMessages, lastMessageId) {
        let lastIds = localStorage.getItem('lastIds');
        if (!!lastIds && lastIds !== 'undefined' && lastIds !== 'null') {
            lastIds = JSON.parse(lastIds);
            lastIds = {
                ...lastIds,
                [idOrder]: {
                    idMessage: lastMessageId.toString(),
                    isNew: false
                }
            };
        } else {
            lastIds = {
                [idOrder]: {
                    idMessage: lastMessageId.toString(),
                    isNew: false
                }
            };
        }
        localStorage.setItem('lastIds', JSON.stringify(lastIds));
        localStorage.setItem('messagesLastIds', JSON.stringify(lastIds));
        setTimeout(() => {
            uploadAmountMessages();
        }, 100);
    }

    getDialogList() {
        const {idOrder} = this.props.data;
        const {uploadAmountMessages} = this.props;
        apiList.getChatList(idOrder)
            .then(value => {
                this.storageUpdate(idOrder, uploadAmountMessages, value.lastId);
                this.setState({
                    dialogList: value
                }, () => {
                    //console.log(this.state.dialogList, 'dialogListFromAPI');
                    this.interval = setInterval(() => {
                        this.getLastDialogList();
                    }, 1000);
                });
            });

    }

    sendMessage(idOrder) {
        const {inputText} = this.state;
        const {uploadAmountMessages} = this.props;
        const data = new FormData();
        this.setState({
          isSendNewMessage: true
        });
        data.append('message', inputText);
        data.append('requestId', idOrder);
        if (!!inputText) {
            apiList.sendChatMessage(data)
                .then((value) => {
                    //this.storageUpdate(idOrder, uploadAmountMessages, value.lastId);
                    this.getLastDialogList();
                    this.setState({
                        inputText: ''
                    });
                });
        }
    }

    onChange(e) {
        const value = e.target.value;
        this.setState({
            inputText: value
        });
    }

    sendPicture(e, idOrder) {
        //console.log(e.target.file[0]);
        this.setState({
            inputText: e.target.file[0]
        }, () => {
            this.getLastDialogList(idOrder);
        });
    }


    render() {
        const {id, go, loadSpinner} = this.props;
        const {dialogList, userId} = this.state;
        //console.log('dialogList ----', dialogList)
        if (!!dialogList && !!userId) {
            loadSpinner(false);
            return (
                <OpenDialogLayout
                    id={id}
                    go={go}
                    onChange={this.onChange}
                    sendMessage={this.sendMessage}
                    sendPicture={this.sendPicture}
                    handleKeyPress={this.handleKeyPress}
                    scrollbarRef={this.scrollbarRef}
                    {...this.state}/>
            );
        } else {
            return null;
        }
    }
}

OpenDialog.propTypes = {
    id: PropTypes.string.isRequired,
    go: PropTypes.func.isRequired
};

export default OpenDialog;
