import React from 'react';
import apiList from "../../utils/apiMethods";
import ShopInfoLayout from '../shopInfo/ShopInfoLayout'


class ShopInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shop: null,
            bouquetList: null,
            gridView: 'list',
            isAllBouquet: false,
        };
        this.getShopInfo = this.getShopInfo.bind(this);
        this.changeGrid = this.changeGrid.bind(this);
        this.declOfNum = this.declOfNum.bind(this);
        this.showAllBouquet = this.showAllBouquet.bind(this);
    }

    componentDidMount() {
        this.getShopInfo()
    }

    getShopInfo() {
        const {loadSpinner} = this.props;
        const {shopId} = this.props.data;
        loadSpinner(true);
        apiList.getShopList()
            .then((data) => {
                data.shopList.forEach((shop) => {
                    if (shop.id === shopId.toString()) {
                        this.setState({
                            shop: shop,
                        }, () => {
                            console.log(this.state.shop, '---------------------------');
                        });
                        apiList.getBouquetListForShop(shopId)
                            .then((data) => {
                                this.setState({
                                    bouquetList: data.bouquetList,
                                })
                            });
                    }
                })
            })
            .finally(() => {
                loadSpinner(false);
            })
    }

    declOfNum(number, titles) {
        const cases = [2, 0, 1, 1, 1, 2];
        return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
    }

    changeGrid(grid) {
        this.setState({
            gridView: grid
        });
    }

    showAllBouquet() {
        this.setState({
            isAllBouquet: true,
        })
        console.log('lllllllllllllllllllllllllllllllllllllllllllllllll')
    }

    render() {
        const {go} = this.props;
        return (
            <ShopInfoLayout go={go} showAllBouquet={this.showAllBouquet} declOfNum={this.declOfNum} changeGrid={this.changeGrid} {...this.state}/>
        )
    }
}

export default ShopInfo;