import React from 'react';
import { Panel } from '@vkontakte/vkui';

import apiList from '../../utils/apiMethods';
import PanelHeader from '../utils/PanelHeader';
import ButtonIcon from '../utils/ButtonIcon';

class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: null,
      url: null
    };

    this.getPaymentInfo = this.getPaymentInfo.bind(this);
  }

  componentDidMount() {
    this.getPaymentInfo();
  }

  getPaymentInfo() {
    const { orderId } = this.props.data;
    apiList.getPayInfo(orderId)
      .then((data) => {
        console.log(data);
        this.setState({
          status: data.payInfo.payStatus,
          url: data.payInfo.payUrl
        });
      });
  }

  render() {
    const { id, data, goBack } = this.props;
    const { url } = this.state;

    console.log(data);
    return (
      <Panel id={id} className="Payment">
        <PanelHeader left={<ButtonIcon type="back" onClick={goBack}/>}>
          Заказа # {!!data && data.orderId}
        </PanelHeader>
        {!!url && (
          <iframe
            src={url}
            title='payment'
            frameBorder="0"
            className="w-100 h-100 position-absolute"
          />
        )}
      </Panel>
    );
  }
}

export default Payment;