import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {View, Alert} from '@vkontakte/vkui';
import connect1 from '@vkontakte/vkui-connect';

import {connect} from "react-redux";

import '../../scss/MainStyle.scss';
import {
    apiKey,
    api,
    apiGetCityListUrl,
    clientId,
    v
} from '../../constants/api';
import SelectCityLayout from './SelectCityLayout';
import {setIsBouquet} from "../../actions/bouquetAction";
import {setSearch} from "../../actions/searchAction";
import {setFilter} from "../../actions/filterAction";
import {setAllBouquet} from "../../actions/allBouquetAction";
import {setTypeBouquet} from "../../actions/typeBouquetAction";
import {checkLocalStorage} from "../../utils/checkLocalStorage";
import {getFavorites} from "../../actions/favoritesAction";

class SelectCity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            city: '',
            cityList: [],
            popout: null,
            search: '',
            isUploaded: false
        };
        this.getCityList = this.getCityList.bind(this);
        this.changeCity = this.changeCity.bind(this);
        this.onChange = this.onChange.bind(this);

        this.openDefault = this.openDefault.bind(this);
        this.closePopout = this.closePopout.bind(this);
        this.nextStep = this.nextStep.bind(this);
        this.confirmCity = this.confirmCity.bind(this);
    }

    componentDidMount() {
        const clearArray = [];
        this.props.onSetIsBouquet(clearArray);
        this.props.onSetFilter(clearArray);
        this.props.onSetAllBouquet(clearArray);
        this.props.onSetTypeBouquet(clearArray);
        this.props.onSetSearch("");
        setTimeout(() => {
            this.getCityList();
        }, 100);
    }

    componentWillUnmount() {
        this.props.onGetFavorites();
    }

    getCityList() {
        axios
            .get(`${api}${apiGetCityListUrl}`, {
                params: {
                    apiKey
                }
            })
            .then(response => {
                const codeCity = Object.keys(response.data.cityList).find(
                    key =>
                        response.data.cityList[key] === localStorage.getItem('nameCity')
                );

                // var tuples = [];
                //
                // for (var key in response.data.cityList) tuples.push([key, response.data.cityList[key]]);
                //
                // tuples.sort(function (a, b) {
                //     console.log('a', b[1]);
                //     if (b[0] !== '158')
                //         return a[1] > b[1] ? 1 : a[1] < b[1] ? -1 : 0
                // });
                // console.log('tuples', tuples);

                if (
                    codeCity !== undefined &&
                    localStorage.getItem('isConfirmCity') !== 'true'
                ) {
                    localStorage.setItem(
                        'codeCity',
                        Object.keys(response.data.cityList).find(
                            key =>
                                response.data.cityList[key] === localStorage.getItem('nameCity')
                        )
                    );
                    this.openDefault();
                }
                this.setState({
                    cityList: response.data.cityList,
                    isUploaded: true
                });
            })
            .catch(error => {
                console.log('error', error);
            });
    }

    changeCity(selectCity, indexCity) {
        localStorage.setItem('codeCity', indexCity);
        localStorage.setItem('nameCity', selectCity);
        localStorage.setItem('isConfirmCity', 'true');
        this.setState({
            city: selectCity
        });
        this.props.go('HomePage');
    }

    openDefault() {
        this.setState({
            popout: (
                <Alert
                    actions={[
                        {
                            title: 'Да, верно',
                            // autoclose: true,
                            //style: 'destructive',
                            //className: 'btnConfirmRight',
                            action: this.confirmCity
                        },
                        {
                            title: 'Выбрать другой',
                            // autoclose: true,
                            // style: 'cancel',
                            action: this.nextStep
                        }
                    ]}
                    onClose={this.nextStep}
                >
                    <h2>Ваш город</h2>
                    <h2 style={{fontSize: '20px', color: '#64B24B'}}>
                        {localStorage.getItem('nameCity')}?
                    </h2>
                </Alert>
            )
        });
    }

    confirmCity() {
        localStorage.setItem('isConfirmCity', 'true');
        this.props.go('HomePage');
    }

    onChange(e) {
        const search = e.target.value;
        this.setState({search});
    }

    get cityList() {
        const search = this.state.search.toLowerCase();
        const {cityList} = this.state;
        const result = Object.entries(cityList).filter(
            value => value[1].toLowerCase().indexOf(search) > -1
        );
        if (result.length !== 0)
            return result;
        // else
        //     return 'нет города'
    }

    closePopout() {
        // this.props.go('HomePage');
        this.setState({
            popout: null,
            activePanel: 'HomePage'
        });
    }

    nextStep() {
        this.setState({
            popout: null
        });
    }

    render() {
        const {go, id, prevState} = this.props;
        const {popout, city, cityList, search, isUploaded} = this.state;
        return (
            <View popout={popout} header={false} activePanel={id}>
                <SelectCityLayout
                    go={go}
                    id={id}
                    city={city}
                    cityList={this.cityList}
                    search={search}
                    isUploaded={isUploaded}
                    fullCityList={cityList}
                    changeCity={this.changeCity}
                    onChange={this.onChange}
                    prevState={prevState}
                />
            </View>
        );
    }
}

SelectCity.propTypes = {
    id: PropTypes.string.isRequired,
    go: PropTypes.func.isRequired,
};

export default (connect(
    state => ({
        setIsBouquet: state.main.setIsBouquet,
        filter: state.main.filter,
        allBouquet: state.main.allBouquet,
        typeBouquet: state.main.typeBouquet,
    }),
    dispatch => ({
        onSetIsBouquet: (value) => {
            dispatch(setIsBouquet(value));
        },
        onSetSearch: (value) => {
            dispatch(setSearch(value))
        },
        onSetFilter: (value) => {
            dispatch(setFilter(value))
        },
        onSetAllBouquet: (value) => {
            dispatch(setAllBouquet(value));
        },
        onSetTypeBouquet: (value) => {
            dispatch(setTypeBouquet(value))
        },
        onGetFavorites: (value) => {
            dispatch(getFavorites());
        }
    }),
)(SelectCity));
