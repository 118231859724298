import React from 'react';
import PropTypes from 'prop-types';
import {Panel} from '@vkontakte/vkui';

import LeftMenu from '../utils/LeftMenu';
import PanelHeader from '../utils/PanelHeader';
import ShopInMapLayout from './ShopInMapLayout';
import apiList from '../../utils/apiMethods';

class ShopInMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shopList: [],
            activeShop: null,
            displayFormat: 'list' // list & map
        };

        this.mapIsLoad = this.mapIsLoad.bind(this);
        this.showShop = this.showShop.bind(this);
        this.changeDisplayFormat = this.changeDisplayFormat.bind(this);
        this.mapInit = this.mapInit.bind(this);
    }

    componentDidMount() {
        const {loadSpinner} = this.props;
        loadSpinner(true);
        apiList.getShopList()
            .then((data) => {
                loadSpinner(false);
                this.setState({shopList: data.shopList});
            });
    }

    mapIsLoad() {
        const {loadSpinner} = this.props;
        loadSpinner(false);
    }

    mapInit() {
        const {loadSpinner} = this.props;
        loadSpinner(true);
    }

    showShop(index) {
        this.setState({activeShop: index});
    }

    changeDisplayFormat() {
        this.setState(prevState => ({
            displayFormat: prevState.displayFormat === 'list' ? 'map' : 'list'
        }));
    }

    render() {
        const {id, go, goForward, goAuth} = this.props;

        return (
            <Panel id={id}>
                <PanelHeader left={
                    <LeftMenu goForward={goForward} go={go} goAuth={goAuth}/>
                }>
                    Салоны рядом
                </PanelHeader>
                <ShopInMapLayout
                    {...this.state}
                    go={go}
                    mapIsLoad={this.mapIsLoad}
                    showShop={this.showShop}
                    mapInit={this.mapInit}
                    changeDisplayFormat={this.changeDisplayFormat}
                />
            </Panel>
        );
    }
}

ShopInMap.propTypes = {
    id: PropTypes.string.isRequired,
    go: PropTypes.func.isRequired
};

export default ShopInMap;
