import React from 'react';
import {connect as connectRedux} from 'react-redux';
import PropTypes from 'prop-types';
import apiList from '../../utils/apiMethods';
import ChatLayout from './ChatLayout';
import {checkLocalStorage} from '../../utils/checkLocalStorage';
import {getAmountMessage} from '../../actions/chatAction';

class Chat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentOrdersList: null,
            chatsList: null,
            lastIds: null
        };
        this.getLastChatList = this.getLastChatList.bind(this);
        this.getChatList = this.getChatList.bind(this);
        this.getChatListFromOrders = this.getChatListFromOrders.bind(this);
        this.goToDialog = this.goToDialog.bind(this);
        this.interval = null;
    }

    componentDidMount() {
        const {uploadAmountMessages, loadSpinner} = this.props;
        loadSpinner(true);
        this.getChatListFromOrders();
        uploadAmountMessages();

    }

    componentWillUnmount() {
        clearInterval(this.interval);
        console.log('Останавливаем интервал');
    }

    getLastChatList(idOrder) {
        const {lastIds} = this.state;
        const {loadSpinner} = this.props;
        console.log('Получаем последний id в getLastChatList', lastIds);
        if (checkLocalStorage('token')) {
            apiList.getLastChatList(idOrder, lastIds[idOrder.toString()].idMessage)
                .then(value => {
                    if (!!value.chatList && Object.keys(value.chatList).length !== 0) {

                        this.setState({
                            chatsList: {
                                ...this.state.chatsList,
                                [idOrder.toString()]: value
                            },
                            lastIds: {
                                ...this.state.lastIds,
                                [idOrder.toString()]: {
                                    isNew: true
                                }
                            }
                        }, () => {
                            console.log('Записываем в lastId полученные изменения', lastIds, this.state.chatsList);
                        });
                    }
                })
                .finally(() => {
                    loadSpinner(false);
                });
        }

    }

    goToDialog(idOrder) {
        const {go} = this.props;

        this.setState({
            lastIds: {
                ...this.state.lastIds,
                [idOrder]: {
                    idMessage: this.state.chatsList[idOrder].lastId,
                    isNew: false
                }
            }
        }, () => {
            const {lastIds} = this.state;
            let toLocal = JSON.stringify(lastIds);
            localStorage.setItem('messagesLastIds', toLocal);
            go('OpenDialog', {idOrder: idOrder});
        });
    }


    getChatList(idOrder) {
        let {lastIds} = this.state;
        const {loadSpinner, onGetAmountMessages} = this.props;
        console.log('Последний id для', idOrder, this.state.lastIds);
        apiList.getChatList(idOrder)
            .then((data) => {
                if (!!lastIds && lastIds[idOrder].idMessage === data.lastId) {
                    this.setState({
                        chatsList: {
                            ...this.state.chatsList,
                            [idOrder.toString()]: data
                        }
                    }, () => {
                        this.getLastChatList(idOrder);
                        this.interval = setInterval(() => {
                            this.getLastChatList(idOrder);
                        }, 5000);
                    });
                } else {
                    console.log('Добавление в lastId из getChatList------');
                    let isNew = false;
                    if (!!lastIds && lastIds[idOrder].idMessage !== data.lastId) {
                      isNew = true;
                        this.setState({
                            lastIds: {
                                ...this.state.lastIds,
                                [idOrder.toString()]: {
                                    idMessage: lastIds[idOrder].idMessage,
                                    isNew,
                                }
                            }
                        }, () => {
                            console.log('Последний id добавленный методом getChatList', this.state.lastIds);
                            this.getLastChatList(idOrder);
                            this.interval = setInterval(() => {
                                this.getLastChatList(idOrder);
                                onGetAmountMessages();
                            }, 5000);
                        });
                    } else {
                        this.setState({
                            chatsList: {
                                ...this.state.chatsList,
                                [idOrder.toString()]: data
                            },
                            lastIds: {
                                ...this.state.lastIds,
                                [idOrder.toString()]: {
                                    idMessage: data.lastId,
                                    isNew,
                                }
                            }
                        }, () => {
                            console.log('Последний id добавленный методом getChatList', this.state.lastIds);
                            this.interval = setInterval(() => {
                                this.getLastChatList(idOrder);
                                onGetAmountMessages();
                            }, 5000);
                        });
                    }
                }
            })
            .finally(() => {
                loadSpinner(false);
                console.log('LastId после обработки методом getChatList', lastIds);
            });
    }


    getChatListFromOrders() {
        const {loadSpinner} = this.props;
        localStorage.setItem('messagesLastIds', localStorage.getItem('lastIds'));
        apiList.getOrdersList()
            .then((data) => {
                this.setState({
                    currentOrdersList: data.requestList.current
                }, () => {
                    console.log('Все заказы', this.state.currentOrdersList);
                    this.state.currentOrdersList.forEach((n) => {
                        if (checkLocalStorage('messagesLastIds')) {
                            const lastIdsFromLocal = JSON.parse(localStorage.getItem('messagesLastIds'));
                            if (!!lastIdsFromLocal[n.id]) {
                                this.setState({
                                    lastIds: lastIdsFromLocal
                                }, () => {
                                    this.getChatList(n.id);
                                });
                                return;
                            }
                        } else {
                            this.getChatList(n.id);
                        }
                    });
                });
            })
            .finally(() => {
                loadSpinner(false);
            });
    }


    render() {
        const userId = localStorage.getItem('userId');
        const {id, go} = this.props;
        return <ChatLayout id={id} go={go} userId={userId} goDialog={this.goToDialog} {...this.state}/>;
    }
}

Chat.propTypes = {
    id: PropTypes.string.isRequired,
    go: PropTypes.func.isRequired
};


export default connectRedux(
    state => ({
        amountMessages: state.chat.amountMessages
    }),
    dispatch => ({
        onSetAmountMessages: (value) => {
            dispatch({type: 'COUNT_MESSAGE', payload: value});
        },
        onGetAmountMessages: () => {
            dispatch(getAmountMessage());
        }
    })
)(Chat);

