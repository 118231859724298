const initialState = {
    popout: null,
    cityList: [],
    filter: {},
    bouquet: [],
    allBouquet: [],
    typeBouquet: [],
    search: '',
    grid: 'list'
};

export default function main(state = initialState, action) {
    const value = action.payload;
    switch (action.type) {

        case 'SET_POPOUT': {
            return Object.assign({}, state, {
                popout: value,
            });
        }

        case 'SET_CITY_LIST': {
            return {
                ...state,
                cityList: value,
            };
        }

        case 'SET_GRID': {
            return {
                ...state,
                grid: value,
            };
        }

        case 'USER_LOGOUT':
            return Object.assign({}, state, {
                popout: null,
                cityList: [],
                filter: {},
                bouquet: [],
                allBouquet: [],
                typeBouquet: [],
                search: ''
            });

        case 'SET_IS_BOUQUET':
            return Object.assign({}, state, {
                bouquet: value,
            });
        case 'SET_SEARCH':
            return Object.assign({}, state, {
                search: value,
            });
        case 'SET_FILTER':
            return Object.assign({}, state, {
                filter: value,
            });
        case 'SET_ALL_BOUQUET':
            return Object.assign({}, state, {
                allBouquet: value,
            });
        case 'SET_TYPE_BOUQUET':
            return Object.assign({}, state, {
                typeBouquet: value,
            });
        // case 'SET_CATEGORY':
        //     return Object.assign({}, state, {
        //         category: value,
        //     });
        // case 'SET_COLOR':
        //     return Object.assign({}, state, {
        //         color: value,
        //     });
        // case 'SET_SIZE':
        //     return Object.assign({}, state, {
        //         color: value,
        //     });
        // case 'SET_PRICE':
        //     return Object.assign({}, state, {
        //         color: value,
        //     });
        // case 'SET_TYPE_PRICE':
        //     return Object.assign({}, state, {
        //         color: value,
        //     });
        // case 'SET_SALE':
        //     return Object.assign({}, state, {
        //         color: value,
        //     });
        // case 'SET_DELIVERY':
        //     return Object.assign({}, state, {
        //         color: value,
        //     });
        default:
    }
    return state;
}
