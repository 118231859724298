import React from 'react';
import PropTypes from 'prop-types';
import {
    Panel,
    Button
} from '@vkontakte/vkui';

import PanelHeader from '../utils/PanelHeader';
import ButtonIcon from '../utils/ButtonIcon';


class AccessError extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {id, go, goForward} = this.props;

        return (
            <Panel id={id}>

                <div className="connectionLoss">
                    <div className='connectionLossBgMsg'>
                        <h4 className='h1internetError'>Отсутствует интернет-соединение...</h4>
                    </div>
                </div>
            </Panel>
        );
    }
}

AccessError.propTypes = {
    id: PropTypes.string.isRequired,
    prevState: PropTypes.string.isRequired,
    go: PropTypes.func.isRequired,
    goAuth: PropTypes.func.isRequired
};

export default AccessError;
