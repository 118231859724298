import React from 'react';

const TabBar = ({firstNameMenu, secondNameMenu, selected, firstContent, secondContent, onChange}) => {
    const renderContent = () => {
        switch (selected) {
            case 0:
                return firstContent;
            case 1:
                return secondContent;
            default:
                return null;
        }
    };

    return (
        <div>
            <div className="tabBarMenuWrap">
                <div
                    className={`tabBarMenuItem ${selected === 0 ? 'active' : ''}`}
                    onClick={() => onChange(0)}
                >
                    {firstNameMenu}
                </div>
                <div
                    className={`tabBarMenuItem ${selected === 1 ? 'active' : ''}`}
                    onClick={() => onChange(1)}
                >
                    {secondNameMenu}
                </div>
            </div>
            {renderContent()}
        </div>
    );
};

export default TabBar;
