import React from 'react';

const ButtonIcon = ({ type, status, onClick, dataStory, dataTo, value }) => {
  return (
    <div className="buttonIconWrap">
      <button
        onClick={onClick}
        data-icon={type}
        data-story={dataStory}
        data-to={dataTo}
        className={`buttonIcon ${status}`}
      >
        <span>{value}</span>
      </button>
    </div>
  );
};

export default ButtonIcon;
