import React from 'react';

const GreenButton = ({ text, onClick }) => {
  return (
    <button onClick={onClick} className="buttonGreen">
      {text}
    </button>
  );
};

export default GreenButton;
