import React from 'react';
import PropTypes from 'prop-types';
import connect from '@vkontakte/vkui-connect';
import axios from 'axios';

import '../../scss/MainStyle.scss';
import {
    api,
    v,
    clientId,
    apiAuthUrl,
    apiKey,
    apiGetAuthSmsUrl,
    apiGetAuthSmsEmailUrl
} from '../../constants/api';
import AuthorizationForm from './AuthorizationForm';
import {connect as connectRedux} from "react-redux";
import {getFavorites} from "../../actions/favoritesAction";
import {getAmountMessage} from "../../actions/chatAction";

class Authorization extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            typeAuth: 'phone',
            phone: '',
            email: '',
            code: '',
            fetchedUser: null,
            validFromServer: {
                isValidSendField: false,
                isConfirmCode: false,
                msgServer: '',
            },
            disabledSend: {
                phone: true,
                email: true
            },
            disabledAuth: {
                phone: true,
                email: true
            },
            access_token: '',
            hash: '',
            isVisiblePhoneAccess: false,
            isVisibleEmailAccess: false,
        };

        this.changeAuth = this.changeAuth.bind(this);
        this.goAuth = this.goAuth.bind(this);
        this.sendSms = this.sendSms.bind(this);
        this.onChange = this.onChange.bind(this);
        this.sendSmsEmail = this.sendSmsEmail.bind(this);
        this.closeTooltip = this.closeTooltip.bind(this);
        this.focusPhone = this.focusPhone.bind(this);
        this.focusEmail = this.focusEmail.bind(this);
    }

    closeTooltip() {
        const {validFromServer} = this.state;
        validFromServer.isConfirmCode = false;
        validFromServer.isValidSendField = false;
        this.setState({
            validFromServer
        })
    }

    focusPhone(e) {
        console.log('Попытка взять телефон');
        //document.activeElement.blur();
        const {isVisiblePhoneAccess, disabledSend, phone} = this.state;
        if (!isVisiblePhoneAccess) {
            connect.send("VKWebAppGetPhoneNumber", {});
            connect.subscribe(e => {
                switch (e.detail.type) {
                    case 'VKWebAppGetPhoneNumberResult':
                        console.log('VKWebAppGetPhoneNumberResult');
                        disabledSend.phone = false;
                        this.setState({
                            phone: e.detail.data.phone_number,
                            disabledSend
                        });
                        break;
                    default:
                        console.log(e.detail.type);
                }
            });
        }
        this.setState({
            isVisiblePhoneAccess: true
        });
    }

    focusEmail() {
        const {isVisibleEmailAccess, disabledSend} = this.state;
        //document.activeElement.blur();
        if (!isVisibleEmailAccess) {
            connect.send("VKWebAppGetEmail", {});
            connect.subscribe(e => {
                switch (e.detail.type) {
                    case 'VKWebAppGetEmailResult':
                        disabledSend.email = false;
                        this.setState({
                            email: e.detail.data.email,
                            disabledSend
                        });
                        break;
                    default:
                        console.log(e.detail.type);
                }
            });
        }
        this.setState({
            isVisibleEmailAccess: true
        });
    }

    onChange(e) {
        const {disabledSend} = this.state;
        const name = e.target.name;
        const value = e.target.value;
        if (name === 'phone') {
            if (value.replace(/\D+/g, '').length === 11) {
                disabledSend.phone = false;
                this.setState({
                    disabledSend
                })
            } else {
                disabledSend.phone = true;
                this.setState({
                    disabledSend
                })
            }
        } else if (name === 'email') {
            const reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/; // /^[^\s@]+@[^\s@]+\.[^\s@]+$/
            if (value.length < 254) {
                if (reg.test(value) === true) {
                    disabledSend.email = false;
                    this.setState({
                        disabledSend
                    })
                } else {
                    disabledSend.email = true;
                    this.setState({
                        disabledSend
                    })
                }
                this.setState({
                    [name]: value
                });
            }
            return;
        } else if (name === 'code') {
            if (value.length < 5) {
                if (/^\d+$/.test(value) || value === "")
                    this.setState({
                        [name]: value
                    });
            }
            return;
        }
        this.setState({
            [name]: value
        });
    }

    sendSms() {
        const {phone, disabledAuth} = this.state;
        this.props.openPopout();
        axios
            .get(`${api}${apiGetAuthSmsUrl}`, {
                params: {
                    apiKey,
                    phone
                }
            })
            .then(response => {
                console.log('response', response);
                disabledAuth.phone = false;
                this.setState({
                    hash: response.data.hash,
                    disabledAuth
                });
                this.props.openPopout();
            })
            .catch(error => {
                console.log('error', error);
                this.props.openDefaultError();
            });
    }

    sendSmsEmail() {
        //this.props.openPopout();
        const {email, disabledAuth} = this.state;
        axios
            .get(`${api}${apiGetAuthSmsEmailUrl}`, {
                params: {
                    apiKey,
                    email
                }
            })
            .then(response => {
                console.log('response', response);
                disabledAuth.email = false;
                this.setState({
                    hash: response.data.hash,
                    disabledAuth
                });
                this.props.openPopout();
            })
            .catch(error => {
                console.log('error', error);
                this.props.openDefaultError();
            });
    }

    goAuth() {
        const {phone, email, hash, code, validFromServer, typeAuth} = this.state;
        const authParams = {
            apiKey,
            hash,
            code,
            appToken: clientId
        };

        if (typeAuth === 'phone') {
            authParams.phone = phone;
        } else {
            authParams.email = email;
        }

        axios
            .get(`${api}${apiAuthUrl}`, {
                params: authParams
            })
            .then(response => {
                if (response.data.message !== undefined) {
                    //console.log('response.data.chat.svg', response.data.message);

                    validFromServer.msgError = response.data.message;

                    if (validFromServer.msgError !== 'OK') {
                        if (validFromServer.msgError === 'Ошибка в коде подтверждения') {
                            validFromServer.isConfirmCode = true;
                        } else {
                            validFromServer.isValidSendField = true;
                        }
                        this.setState({
                            validFromServer,
                        });
                    }
                }
                if (response.data.token !== undefined) {
                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('userId', response.data.userId);
                    this.props.onGetFavorites();
                    this.props.onGetMessages();
                    this.props.goBack();
                    connect.send('VKWebAppGetUserInfo', {});
                    connect.subscribe(e => {
                        switch (e.detail.type) {
                            case 'VKWebAppGetUserInfoResult':
                                localStorage.setItem('nameCity', e.detail.data.city.title);
                                break;
                            default:
                                console.log(e.detail.type);
                        }
                    });
                }
            })
            .catch(error => {
                console.log('error', error);
            });

        // this.props.go('EpicNavigation')
    }

    changeAuth(e, type) {
        e.preventDefault();
        if (type === 'phone') {
            this.setState({
                typeAuth: 'phone',
                code: ''
            });
        } else if (type === 'email') {
            this.setState({
                typeAuth: 'email',
                code: ''
            });
        }
    }

    render() {
        const {id, go, goBack, goForward, validFromServer} = this.props;
        console.log('validFromServer', validFromServer);
        return (
            <AuthorizationForm
                {...this.state}
                id={id}
                go={go}
                goBack={goBack}
                goForward={goForward}
                changeAuth={this.changeAuth}
                goAuth={this.goAuth}
                sendSms={this.sendSms}
                onChange={this.onChange}
                sendSmsEmail={this.sendSmsEmail}
                closeTooltip={this.closeTooltip}
                focusPhone={this.focusPhone}
                focusEmail={this.focusEmail}
            />
        );
    }
}

Authorization.propTypes = {
    id: PropTypes.string.isRequired,
    go: PropTypes.func.isRequired,
    access_token: PropTypes.string,
};

export default (connectRedux(
    state => ({
        amountFavorites: state.favorites.amountFavorites
    }),
    dispatch => ({
        onGetFavorites: () => {
            dispatch(getFavorites());
        },
        onGetMessages: () => {
            dispatch(getAmountMessage());
        }
    }),
)(Authorization));
