import axios from 'axios';
import {
    api,
    apiGetAddressListUrl,
    apiGetProfile,
    apiKey,
    apiSendOrder,
    apiGetPromocodeInfo,
    apiGetOrders,
    apiGetChatList,
    apiGetLastChatList,
    apiSendChatMessage,
    apiGetPayInfo,
    apiSendFavoritesUrl,
    apiGetShopListUrl,
    apiGetFavorites, apiGetBouquetListUrl
} from '../constants/api';
import {connect} from "react-redux";
import {addFavorites} from "../actions/favoritesAction";

const apiList = {
    getAddressesList: () => new Promise((resolve, reject) => {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');
        axios.get(`${api}${apiGetAddressListUrl}`, {
            params: {
                apiKey,
                token,
                userId
            }
        }).then(response => {
            if (response.data.status === 200) {
                console.log('getAddressesList', response.data);
                resolve(response.data);
            } else {
                reject(response.data);
            }
        }).catch(error => {
            console.log('error', error);
            reject(error);
        });
    }),
    getProfile: () => new Promise((resolve, reject) => {
        axios.get((`${api}${apiGetProfile}`),
            {
                params: {
                    apiKey,
                    userId: localStorage.getItem('userId'),
                    token: localStorage.getItem('token')
                }
            })
            .then((response) => {
                console.log('response', response);
                resolve(response.data);
            })
            .catch((error) => {
                console.log('error', error);
                reject(error);
            });
    }),
    sendOrder: (data) => new Promise((resolve, reject) => {
        axios({
            method: 'post',
            url: `${api}${apiSendOrder}?apiKey=${apiKey}`,
            data,
            config: {headers: {'Content-Type': 'multipart/form-data'}}
        })
            .then((response) => {
                console.log('Создали заказа', response);
                resolve(response.data);
            })
            .catch((error) => {
                console.log('error', error);
                reject(error);
            });
    }),
    getPromocodeInfo: (promocode) => new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: `${api}${apiGetPromocodeInfo}`,
            params: {
                apiKey,
                token: localStorage.getItem('token'),
                idPromocode: promocode
            }
        })
            .then((response) => {
                console.log('Проверка промокода', response);
                resolve(response.data);
            })
            .catch((error) => {
                console.log('error', error);
                reject(error);
            });
    }),
    getOrdersList: () => new Promise((resolve, reject) => {
        axios.get((`${api}${apiGetOrders}`),
            {
                params: {
                    apiKey,
                    userId: localStorage.getItem('userId'),
                    token: localStorage.getItem('token'),
                }
            })
            .then((response) => {
                console.log('response', response);
                resolve(response.data);
            })
            .catch((error) => {
                console.log('error', error);
                reject(error);
            })
    }),
    getChatList: (idOrder) => new Promise((resolve, reject) => {
        axios.get((`${api}${apiGetChatList}`),
            {
                params: {
                    apiKey,
                    userId: localStorage.getItem('userId'),
                    token: localStorage.getItem('token'),
                    requestId: idOrder,
                }
            })
            .then((response) => {
                console.log('response', response);
                resolve(response.data);
            })
            .catch((error) => {
                console.log('error', error);
                reject(error);
            })
    }),
    getLastChatList: (idOrder, idLastMessage) => new Promise((resolve, reject) => {
        axios.get((`${api}${apiGetLastChatList}`),
            {
                params: {
                    apiKey,
                    userId: localStorage.getItem('userId'),
                    token: localStorage.getItem('token'),
                    requestId: idOrder,
                    lastId: idLastMessage,
                }
            })
            .then((response) => {
                console.log('response', response);
                resolve(response.data);
            })
            .catch((error) => {
                console.log('error', error);
                reject(error);
            })
    }),
    sendChatMessage: (data) => new Promise((resolve, reject) => {
        data.append('userId', localStorage.getItem('userId'));
        data.append('token', localStorage.getItem('token'));
        axios.post(`${api}${apiSendChatMessage}?apiKey=${apiKey}`,
            data,
            {
                headers: {'Content-Type': 'multipart/form-data'},
            }
        )
            .then((response) => {
                console.log('response', response);
                resolve(response.data);
            })
            .catch((error) => {
                console.log('error', error);
                reject(error);
            })
    }),
    getPayInfo: (requestId) => new Promise((resolve, reject) => {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');
        axios({
            method: 'get',
            url: `${api}${apiGetPayInfo}`,
            params: {
                apiKey,
                userId,
                token,
                requestId
            }
        })
            .then((response) => {
                console.log('Получение информации об оплате', response);
                resolve(response.data);
            })
            .catch((error) => {
                console.log('error', error);
                reject(error);
            });
    }),
    sendFavorites: (idBouquet) => new Promise((resolve, reject) => {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');
        const idCity = localStorage.getItem('codeCity');
        axios({
            method: 'GET',
            url: `${api}${apiSendFavoritesUrl}`,
            params: {
                apiKey,
                userId,
                token,
                idCity,
                idBouquet,
            }
        })
            .then((response) => {
                console.log('Добавление в избранное', response);
                resolve(response.data);
            })
            .catch((error) => {
                console.log('error', error);
                reject(error);
            });
    }),
    getShopList: () => new Promise((resolve, reject) => {
        const idCity = localStorage.getItem('codeCity');
        axios({
            method: 'GET',
            url: `${api}${apiGetShopListUrl}`,
            params: {
                apiKey,
                idCity
            }
        })
            .then((response) => {
                console.log('Получение списка магазинов', response);
                resolve(response.data);
            })
            .catch((error) => {
                console.log('error', error);
                reject(error);
            });
    }),
    getBouquetListForShop: (shop) => new Promise((resolve, reject) => {
        const idCity = localStorage.getItem('codeCity');
        const token = localStorage.getItem('token');
        axios({
            method: 'GET',
            url: `${api}${apiGetBouquetListUrl}`,
            params: {
                apiKey,
                token,
                idCity,
                shop
            }
        })
            .then((response) => {
                console.log('Получение списка букетов в магазине', response);
                resolve(response.data);
            })
            .catch((error) => {
                console.log('error', error);
                reject(error);
            });
    }),
    getFavorites: () => new Promise((resolve, reject) => {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');
        const idCity = localStorage.getItem('codeCity');
        axios({
            method: 'GET',
            url: `${api}${apiGetFavorites}`,
            params: {
                apiKey,
                userId,
                token,
                idCity,
            }
        })
            .then((response) => {
                console.log('response', response);
                resolve(response.data);
            })
            .catch((error) => {
                console.log('error', error);
                reject(error);
            });
    })
};

export default apiList;