import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import {PullToRefresh, Div, Panel} from '@vkontakte/vkui';


import Icon16Like from '@vkontakte/icons/dist/16/like';
import Icon16LikeOutline from '@vkontakte/icons/dist/16/like_outline';

import '../../scss/MainStyle.scss';
import freeDelivery from '../../img/card-bouquet/free.svg';
import sale from '../../img/sale.png';

import {star} from "../utils/starIcon";

import RightMenu from '../utils/RightMenu';
import Search from '../utils/Search';
import {setSearch} from '../../actions/searchAction';
import {api, apiGetBouquetListUrl, apiKey, apiSendFavoritesUrl} from '../../constants/api';

import {setFilter} from '../../actions/filterAction';
import {checkLocalStorage} from '../../utils/checkLocalStorage';
import ButtonIcon from '../utils/ButtonIcon';
import PanelHeader from '../utils/PanelHeader';
import apiList from "../../utils/apiMethods";
import BouquetCard from "../utils/BouquetCard";
import {setAllBouquet} from "../../actions/allBouquetAction";
import {addFavorites} from "../../actions/favoritesAction";

class ResultFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fetching: false,
            bouquetFiltered: [],
            search: !!this.props.filter && this.props.filter.search,
            total: '',
            limit: 50
        };
        this.getFilteredList = this.getFilteredList.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.sendFavorites = this.sendFavorites.bind(this);
        this.moreBouquet = this.moreBouquet.bind(this);
    }

    componentDidMount() {
        this.getFilteredList();
        const {loadSpinner} = this.props;
        loadSpinner(true);
    }


    moreBouquet() {
        const {limit} = this.state;
        let limitCopy = limit + limit;
        this.setState({
            limit: limitCopy
        });

        this.getFilteredList(limitCopy);

    }

    onKeyDown(e) {
        if (e.key === 'Enter') {

            const filter = {
                sorting: this.props.filter.sorting === '' ? 'relevation' : this.props.filter.sorting,
                price: {min: 0, max: 10000},
                category: this.props.filter.category === '' ? '' : this.props.filter.category,
                size: this.props.filter.size === '' ? '' : this.props.filter.size,
                color: this.props.filter.color === '' ? '' : this.props.filter.color,
                sale: this.props.filter.sale === '' ? '' : this.props.filter.sale,
                type: this.props.filter.type === '' ? 'all' : this.props.filter.type,
                delivery: this.props.filter.delivery === '' ? '' : this.props.filter.delivery,
            };
            if (!!this.state.search) {
                filter.search = this.state.search
            }
            this.props.onSetFilter(filter);
            setTimeout(() => {
                this.getFilteredList();
            }, 100)
        }
    }

    onRefresh() {
        this.setState({fetching: true});

        setTimeout(() => {
            this.setState({
                fetching: false
            });
        }, this.getFilteredList);
    }

    getFilteredList(limit = 50) {
        const {filter, loadSpinner} = this.props;
        loadSpinner(true);
        let copySale = 0;
        if (filter.sale !== undefined) {
            if (filter.sale === false)
                copySale = 0;
            if (filter.sale === true)
                copySale = 1;
        }

        let copyDelivery = 0;
        if (filter.delivery !== undefined) {
            if (filter.delivery === false)
                copyDelivery = 0;
            if (filter.delivery === true)
                copyDelivery = 1;
        }


        const sendJSON = {
            apiKey,
            idCity: localStorage.getItem('codeCity'),
            sorting: filter.sorting !== undefined ? filter.sorting : '',
            min: filter.price !== undefined ? filter.price.min : '',
            max: filter.price !== undefined ? filter.price.max : '',
            category: filter.category !== undefined ? filter.category : '',
            size: filter.size !== undefined ? filter.size : '',
            color: filter.color !== undefined ? filter.color : '',
            sale: copySale,
            type: filter.type !== undefined ? filter.type : '',
            free: copyDelivery,
            limit: limit,
            start: 0
        };
        if (sendJSON.max === 10000) {
            sendJSON.max = false
        }

        if (!!filter.search) {
            sendJSON.search = filter.search;
        }

        if (checkLocalStorage('userId') && localStorage.getItem('userId'))
            sendJSON.userId = localStorage.getItem('userId');

        axios
            .get(`${api}${apiGetBouquetListUrl}`, {
                params: sendJSON
            })
            .then(response => {
                this.setState({
                    bouquetFiltered: response.data.bouquetList,
                    total: response.data.total
                });
                loadSpinner(false);
            })
            .catch(error => {
                console.log('error', error);
                loadSpinner(false);
            });


        //document.getElementById('hmtLeft').checked = true;
    }

    sendFavorites(idBouquet, index, indexI) {

        const data = new FormData();
        data.append('userId', localStorage.getItem('userId'));
        data.append('token', localStorage.getItem('token'));
        data.append('codeCity', localStorage.getItem('codeCity'));
        data.append('idBouquet', idBouquet);
        apiList.sendFavorites(idBouquet)
            .then(response => {
                console.log('response', response);
            })
            .catch(error => {
                console.log('error', error);
            });
        this.props.onAddFavorites(this.state.bouquetFiltered[indexI].favorites === 1 ? -1 : 1);
        this.state.bouquetFiltered[indexI].favorites = +!this.state.bouquetFiltered[indexI].favorites;
        this.setState({
            bouquetFiltered: this.state.bouquetFiltered
        });
        this.props.allBouquet.map((value, index) => {
            return value.bouquetList.map((value1, index1) => {
                if (value1.id === idBouquet) {
                    this.props.allBouquet[index].bouquetList[index1].favorites = +!this.props.allBouquet[index].bouquetList[index1].favorites;
                    this.props.onSetAllBouquet(this.props.allBouquet);
                }
                return null;
            })
        });
    }


    onChange(e) {
        const name = e.target.name;
        const value = e.target.value;

        if (name === 'search') {
            // setTimeout(() => {
            //     this.getFilteredList();
            // }, 1500);

            //setSearch(value);
            this.setState({
                [name]: value
            });
        } else {
            this.setState({
                [name]: value
            });
        }
    }


    render() {
        const {id, go, goAuth} = this.props;
        const {fetching, bouquetFiltered, total, search} = this.state;

        return (
            <Panel id={id}>
                <PanelHeader
                    left={
                        <Fragment>
                            <ButtonIcon type="back" onClick={go} dataStory="HomePage"/>
                            <RightMenu go={go} goAuth={goAuth} getFilteredList={this.getFilteredList}/>
                        </Fragment>
                    }
                    big
                >

                    <Search
                        onKeyDown={this.onKeyDown}
                        name='search' value={search}
                        onChange={this.onChange}
                        placeholder="Найти букеты..."
                    />
                </PanelHeader>

                <div>
                    <div className="contentBlock unselectable">
                        <Div className='pt-0'>
                            <span
                                className='titleCategoryBouquet d-flex justify-content-start mt-2 unselectable'>
                                <b>Результаты поиска ({total})</b>
                                        </span>
                            <div className="row rowWrapList">
                                {
                                    bouquetFiltered.map((valueI, indexI) => (
                                        <Fragment key={indexI}>
                                            <BouquetCard
                                                urlBack='ResultFilter'
                                                go={go}
                                                index={indexI}
                                                value={valueI}
                                                changeFavorite={this.sendFavorites}
                                                indexCategory={indexI}
                                                gridView='scroll'
                                            />
                                            {(indexI === this.state.limit - 1) &&
                                            <div className='w-100 ml-3 mr-3'>
                                                <div
                                                    className='col-12 mb-2 mt-3 d-flex justify-content-center w-100'>
                                                    <button onClick={this.moreBouquet}
                                                            className='showMoreBouquet p-2'>
                                                        Смотреть ещё
                                                    </button>
                                                </div>
                                                <div className='divLine mb-3'/>
                                            </div>
                                            }

                                        </Fragment>
                                    ))
                                }
                            </div>


                        </Div>
                    </div>

                </div>
            </Panel>


        );
    }
}

ResultFilter.propTypes = {
    id: PropTypes.string.isRequired,
    go: PropTypes.func.isRequired
};

export default (connect(
    state => ({
        search: state.main.search,
        filter: state.main.filter,
        allBouquet: state.main.allBouquet,
    }),
    dispatch => ({
        onAddFavorites: (value) => {
            dispatch(addFavorites(value));
        },
        onSetSearch: (value) => {
            dispatch(setSearch(value));
        },
        onSetFilter: (value) => {
            dispatch(setFilter(value));
        },
        onSetAllBouquet: (value) => {
            dispatch(setAllBouquet(value));
        },
    })
)(ResultFilter));