import React from 'react';
import PropTypes from 'prop-types';
import '../../scss/MainStyle.scss';
import {
    Panel,
} from '@vkontakte/vkui';

import {api, apiKey} from '../../constants/api';
import PanelHeader from '../utils/PanelHeader';
import ButtonIcon from '../utils/ButtonIcon';
import {IOS, platform} from "@vkontakte/vkui/dist/vkui";


class StaticInfoPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {id, go, nameInfoPage, lastDataStory, loadSpinner} = this.props;
        const srcString = `${api}/${nameInfoPage}/?apiKey=${apiKey}`;
        const osname = platform();
        return (
            <Panel id={id}>
                <PanelHeader
                    left={
                        <ButtonIcon type="back" onClick={go} dataTo={lastDataStory}/>
                    }
                />
                <div className='wrapIframe'>
                    <iframe
                        src={srcString}
                        title='iframe'
                        frameBorder="0"
                        className="w-100 position-absolute unselectable staticInfoPageIframe"
                        style={osname !== IOS ? {height: 'calc(100vh - 55px)'} : {}}
                    />
                </div>
            </Panel>
        );
    }
}

StaticInfoPage.propTypes = {
    id: PropTypes.string.isRequired,
    go: PropTypes.func.isRequired,
    nameInfoPage: PropTypes.string.isRequired,
    fetchedUser: PropTypes.shape({
        photo_200: PropTypes.string,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        city: PropTypes.shape({
            title: PropTypes.string
        })
    })
};

export default StaticInfoPage;