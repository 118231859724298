const initialState = {
    addresses: []
};

export default function address(state = initialState, action) {
    const value = action.payload;
    switch (action.type) {
        case 'SET_ADDRESSES': {
            return {
                ...state,
                addresses: value,
            };
        }
        default:
    }
    return state;
}
