import React from 'react';
import PropTypes from 'prop-types';
import {
    Panel,
    Button
} from '@vkontakte/vkui';

import PanelHeader from '../utils/PanelHeader';
import ButtonIcon from '../utils/ButtonIcon';


class AccessError extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {id, go, goForward} = this.props;

        return (
            <Panel className="errorAccess" id={id}>
                <PanelHeader
                    left={
                        <ButtonIcon type="back" onClick={go} dataStory="HomePage"/>
                    }
                />

                <div className="accessError">
                    <h4>Для просмотра данной страницы требуется авторизация</h4>
                    <Button
                        onClick={() => goForward('Authorization')}
                        data-to="Authorization"
                        className="buttonGreen"
                    >
                        Авторизация
                    </Button>
                </div>


            </Panel>
        );
    }
}

AccessError.propTypes = {
    id: PropTypes.string.isRequired,
    prevState: PropTypes.string.isRequired,
    go: PropTypes.func.isRequired,
    goAuth: PropTypes.func.isRequired
};

export default AccessError;
