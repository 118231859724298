import React from 'react';
import PropTypes from 'prop-types';
import PromoCodeLayout from './PromoCodeLayout';
import '../../scss/MainStyle.scss';
import VKStorage from "vk-storage";
import connect from "@vkontakte/vkui-connect-promise";

class PromoCode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            promoCodeLis3140623t: null,
            openedPromoCode: "",
        };
    }

    componentDidMount() {
        VKStorage.init({access_token: localStorage.getItem('access_token'), connect}).catch(console.error)
            .then(() => {
                console.log("GetStorage", VKStorage.get('promoCodeList'))
                this.setState({
                    promoCodeList: JSON.parse(VKStorage.get("promoCodeList")),
                })
            });
    }

    componentWillUnmount(){
        VKStorage.set("promoCodeList", JSON.stringify(this.state.promoCodeList));
    }

    openPromoCodeDescription(promoCode) {
        this.setState({
            openedPromoCode: promoCode,
        })
    }


    render() {
        const {id, go, goAuth, goForward} = this.props;

        return <PromoCodeLayout
            goForward={goForward}
            id={id}
            go={go}
            goAuth={goAuth}
            openPromoCodeDescription={this.openPromoCodeDescription}
            {...this.state}
        />;
    }
}

PromoCode.propTypes = {
    id: PropTypes.string.isRequired,
    go: PropTypes.func.isRequired,
};

export default PromoCode;
