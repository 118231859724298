import React from 'react';

const CardShop = ({go, data, type, key }) => {
  if (type === 'small') {
    return (
      <div className="cardShopWrap small" key={key} onClick={()=> go('ShopInfo', {shopId: data.id})}>
        <img src={data.img} alt="" className="imageShop"/>
        <div className="infoShop">
          <div className="iconShop"/>
          <div className="centerBlock">
            <div className="titleShop">{data.title.replace(/&quot;/g, '"')}</div>
            <div className="minPriceShop">Букеты от {data.min_price.substring(0, data.min_price.length - 3)} <span className='rub'>&#8381;</span></div>
          </div>
          <div>
            <div className="labelWrap" data-color="yellow" data-rating="true">
              <span>{data.rating}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="cardShopWrap big" key={key} onClick={()=> go('ShopInfo', {shopId: data.id})}>
      <img src={data.img} alt="" className="imageShop"/>
      <div className="infoShop">
        <div className="iconShop"/>
        <div className="centerBlock">
          <div className="titleShop">{data.title.replace(/&quot;/g, '"')}</div>
        </div>
        <div style={{display: 'flex'}}>
          <div className="labelWrap" data-color="yellow" data-rating="true">
            <span>{data.rating}</span>
          </div>
          <div className="minPriceShop">Букеты от {data.min_price.substring(0, data.min_price.length - 3)} <span className='rub'>&#8381;</span></div>
        </div>
      </div>
    </div>
  );
};

export default CardShop;