import React from 'react';
import { FormLayout, Select, Textarea } from '@vkontakte/vkui';
import SelectDateTime from '../utils/SelectDateTime';
import { getListCurrentTime } from '../../utils/dateAndTime';

const CheckoutPickup = ({ dateTime, onChange, time, date, onChangeDate, error, comment }) => {
  return (
    <div className="infoBlockCheckout">
      <FormLayout>
        <SelectDateTime
          value={dateTime}
          time={time}
          date={date}
          onChangeDate={onChangeDate}
        />

        <Select
          placeholder="Выберите время заказа"
          top="Время заказа"
          value={time}
          onChange={onChange}
          name="time"
          status={!error.time ? 'default' : 'error'}
          bottom={!error.time ? '' : 'Пожалуйста, выберите Время'}
        >
          {getListCurrentTime(date).map((valueTime, index) => (
            <option value={valueTime} key={index}>{valueTime}</option>
          ))}
        </Select>

        <Textarea top="Особые комментарии к заказу" name="comment" onChange={onChange} value={comment}/>
      </FormLayout>
    </div>
  );
};

export default CheckoutPickup;
