import React from 'react';
import PropTypes from 'prop-types';
import axios from "axios/index";

import MyOrdersLayout from './MyOrdersLayout';
import {
    api,
    apiKey,
    apiGetOrders,
} from "../../constants/api";

class MyOrders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orderType: 0, // 0 - current, 1 - archive
            currentOrdersList: [],
            archiveOrdersList: [],
            fetching: false,
        };
        this.changeOrderType = this.changeOrderType.bind(this);
        this.getOrders = this.getOrders.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
    }

    componentDidMount() {
        const {loadSpinner} = this.props;
        loadSpinner(true);
        this.getOrders()
    }

    changeOrderType(orderType) {
        this.setState({
            orderType,
        });

    }

    onRefresh() {
        this.setState({
            fetching: true,
        });
        this.getOrders();
    }

    getOrders() {
        const {loadSpinner} = this.props;
        axios.get((`${api}${apiGetOrders}`),
            {
                params: {
                    apiKey: apiKey,
                    userId: localStorage.getItem('userId'),
                    token: localStorage.getItem('token'),
                }
            })
            .then((response) => {
                console.log('response', response);
                this.setState({
                    currentOrdersList: response.data.requestList.current,
                    archiveOrdersList: response.data.requestList.archive,
                    fetching: false,
                })
                console.log(this.state.currentOrdersList)
            })
            .catch((error) => {
                this.setState({
                    fetching: false,
                });
                console.log('error', error);
            })
            .finally(() => {
                loadSpinner(false);
            })
    }

    render() {
        const {id, go} = this.props;
        return <MyOrdersLayout id={id} go={go} changeOrderType={this.changeOrderType}
                               onRefresh={this.onRefresh} {...this.state}/>;
    }
}

MyOrders.propTypes = {
    id: PropTypes.string.isRequired,
    go: PropTypes.func.isRequired,
};

export default MyOrders;
