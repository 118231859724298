import React from 'react';
import PropTypes from 'prop-types';
import {View, ScreenSpinner} from '@vkontakte/vkui';
import axios from 'axios';
import connectVKUI from '@vkontakte/vkui-connect';
import {connect} from 'react-redux';
import {setIsBouquet} from '../../actions/bouquetAction';
import {setSearch} from "../../actions/searchAction";
import {setAllBouquet} from "../../actions/allBouquetAction";
import {setTypeBouquet} from "../../actions/typeBouquetAction";

import {checkAuthUser} from '../../utils/checkAuth';
import {
    apiKey,
    clientId,
    api,
    apiGetBouquetMainListUrl,
    apiGetBouquetInfoUrl,
    apiGetTypeList,
    v
} from '../../constants/api';
import HomePageLayout from './HomePageLayout';
import {setFilter} from "../../actions/filterAction";
import apiList from "../../utils/apiMethods";
import {checkLocalStorage} from "../../utils/checkLocalStorage";
import {setGrid} from "../../actions/selectedGridAction";
import {addFavorites, setFavorites} from "../../actions/favoritesAction";

class HomePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bouquetMainList: [],
            bouquetInfo: [],
            arrIndexBouquet: [],
            typeList: [],
            popout: null,
            gridView: 'list',
            fetching: false,
            search: /*this.props.filter !== undefined ? this.props.filter.search : */'',
            type: 'all',
        };

        this.getBouquetMainList = this.getBouquetMainList.bind(this);
        this.changeGrid = this.changeGrid.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
        this.updateBouquetList = this.updateBouquetList.bind(this);
        this.getBouquetInfo = this.getBouquetInfo.bind(this);
        this.declOfNum = this.declOfNum.bind(this);
        this.getTypeBouquet = this.getTypeBouquet.bind(this);
        this.onChange = this.onChange.bind(this);
        this.sortFunc = this.sortFunc.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.sendFavorites = this.sendFavorites.bind(this);
    }


    componentDidMount() {
        console.log('checkAuthUser', checkAuthUser);
        if (!!this.props.saveGrid) {
            this.setState({
                gridView: this.props.saveGrid
            });
        }

        if (this.props.allBouquet.length === 0 || !this.props.allBouquet) {
            this.getBouquetMainList();
        } else {
            this.setState({
                bouquetMainList: this.props.allBouquet
            })
        }

        if (this.props.typeBouquet.length === 0 || !this.props.typeBouquet) {
            this.getTypeBouquet();
        } else {
            this.setState({
                typeList: this.props.typeBouquet
            })
        }
    }

    sendFavorites(idBouquet, index, indexI) {
        const {bouquetMainList} = this.state;
        const data = new FormData();
        data.append('userId', localStorage.getItem('userId'));
        data.append('token', localStorage.getItem('token'));
        data.append('codeCity', localStorage.getItem('codeCity'));
        data.append('idBouquet', idBouquet);
        apiList.sendFavorites(idBouquet)
            .then(response => {
                console.log('response', response);
            })
            .catch(error => {
                console.log('error', error);
            });
        let copyBouquet = JSON.parse(JSON.stringify(bouquetMainList));
        copyBouquet[index].bouquetList[indexI].favorites = +!bouquetMainList[index].bouquetList[indexI].favorites;
        this.props.onSetAllBouquet(copyBouquet);
        this.props.onAddFavorites(bouquetMainList[index].bouquetList[indexI].favorites === 1 ? -1 : 1);
        this.setState({
            bouquetMainList: copyBouquet,
        });

        console.log('bouquetMainList[index].bouquetList[indexI].favorites', bouquetMainList[index].bouquetList[indexI].favorites);
        console.log('bouquetMainList', bouquetMainList);

    }

    onKeyDown(e) {
        if (e.key === 'Enter') {
            console.log('нажал enter');
            const filter = {
                sorting: 'relevation',
                price: {min: 0, max: 10000},
                category: '',
                size: '',
                color: '',
                sale: '',
                type: '',
                delivery: '',
                search: this.state.search,
            };
            console.log('filter', filter);
            this.props.onSetFilter(filter);
            this.props.go('ResultFilter');
        }
    }

    sortFunc(sortId) {
        const filter = {
            sorting: 'relevation',
            price: {min: 0, max: 10000},
            category: '',
            size: '',
            color: '',
            sale: '',
            type: sortId,
            delivery: '',
            search: ''
        };
        this.props.onSetFilter(filter);
        this.props.go('ResultFilter');
    }

    onChange(e) {
        const {name, value} = e.target;
        const {onSetFilter, onSetSearch} = this.props;

        if (name === 'search') {
            const filter = {
                sorting: 'relevation',
                price: '',
                category: '',
                size: '',
                color: '',
                sale: '',
                type: '',
                delivery: '',
                search: value,
            };
            onSetFilter(filter);
            onSetSearch(value);
            this.setState({
                [name]: value
            })
        } else {
            this.setState({
                [name]: value
            })
        }
    }

    getTypeBouquet() {
        axios
            .get(`${api}${apiGetTypeList}`, {
                params: {
                    apiKey,
                    idCity: checkLocalStorage('codeCity') ? localStorage.getItem('codeCity') : 158,
                }
            })
            .then(response => {
                console.log('typeList', response.data.typeList);

                function compare(a, b) {
                    if (a.sort < b.sort) {
                        return -1;
                    }
                    if (a.sort > b.sort) {
                        return 1;
                    }
                    return 0;
                }

                this.setState({
                    typeList: Object.values(response.data.typeList).sort(compare),
                });
                this.props.onSetTypeBouquet(Object.values(response.data.typeList).sort(compare));
            })
            .catch(error => {
                console.log('error', error);
            });
    }

    getBouquetInfo(idBouquet) {
        let sendJSON = {
            apiKey: apiKey,
            idBouquet: idBouquet,
        };
        if (checkLocalStorage('userId') && localStorage.getItem('userId'))
            sendJSON.userId = localStorage.getItem('userId');

        axios
            .get(`${api}${apiGetBouquetInfoUrl}`, {
                params: sendJSON
            })
            .then(response => {
                this.setState({
                    bouquetInfo: response.data,
                });
            })
            .catch(error => {
                console.log('error', error);
            });
    }

    declOfNum(number, titles) {
        const cases = [2, 0, 1, 1, 1, 2];
        return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
    }

    onRefresh() {
        this.setState({fetching: true});

        setTimeout(() => {
            this.setState({
                fetching: false
            });
        }, this.updateBouquetList());
    }

    changeGrid(grid) {
        this.setState({
            gridView: grid
        });
        this.props.onSetGrid(grid);
    }

    updateBouquetList() {
        this.getTypeBouquet();
        this.getBouquetMainList()
        // axios
        //     .get(`${api}${apiUpdateBouquetListUrl}`, {
        //         params: {
        //             apiKey,
        //             idCity: checkLocalStorage('codeCity') ? localStorage.getItem('codeCity') : 158, //если нет кода города, то указываем код Москвы
        //             time: new Date()
        //         }
        //     })
        //     .then(response => {
        //         console.log('После обновления пришло это', response.data);
        //         this.setState({
        //             bouquetMainList: response.data.bouquetMainList
        //         });
        //     })
        //     .catch(error => {
        //         console.log('error', error);
        //     });
    }

    getBouquetMainList() {
        this.setState({popout: <ScreenSpinner/>});
        axios
            .get(`${api}${apiGetBouquetMainListUrl}`, {
                params: {
                    apiKey,
                    idCity: checkLocalStorage('codeCity') ? localStorage.getItem('codeCity') : 158,
                    userId: localStorage.getItem('userId')
                }
            })
            .then(response => {
                console.log('получение букетов', response.data.bouquetMainList);
                this.setState({
                    bouquetMainList: response.data.bouquetMainList,
                    popout: null,
                });
                this.props.onSetAllBouquet(response.data.bouquetMainList);
            })
            .catch(error => {
                console.log('error', error);
            });
    }


    render() {
        const {id, go, goAuth, onSetIsBouquet, goForward} = this.props;
        console.log('this.props', this.props);

        // const validateAppUrl = (url, secret_key) => {
        //     // достаем параметры из url
        //     const query_params = url.slice(url.indexOf("?") + 1).split("&").reduce((a, x) => {
        //         const data = x.split("=");
        //         a[decodeURIComponent(data[0])] = decodeURIComponent(data[1]);
        //         return a;
        //     }, {});
        //
        //     // выбираем нужные (с приставкой "vk_") и сортируем их
        //     const sign_params = {};
        //     Object.keys(query_params).sort()
        //         .forEach((key) => {
        //             if(!key.startsWith("vk_")) return;
        //             sign_params[key] = query_params[key];
        //         });
        //
        //     // образуем строку вида param1=value1&param2=value2...
        //     const sign_str = Object.keys(sign_params).reduce((a, x) => {
        //         a.push(x + "=" + sign_params[x]);
        //         return a;
        //     }, []).join("&");
        //
        //     // подписываем
        //     let sign = require("crypto").createHmac("sha256", secret_key).update(sign_str);
        //     sign = sign.digest("binary");
        //     sign = require("buffer").Buffer.from(sign, "binary").toString("base64");
        //     sign = sign.split("+").join("-");
        //     sign = sign.split("/").join("_");
        //     sign = sign.replace(/=+$/, '');
        //
        //     // сравниваем подпись с оригинальной. если все окей, то возвращаем id пользователя, если нет - null
        //     return sign ;
        // };
        //
        // console.log('query_params', validateAppUrl.query_params);
        //
        // const {id, go, goAuth, onSetIsBouquet, goForward} = this.props;
        // console.log('this.props', this.props);
        //
        // const query_params = window.location.search.slice(window.location.search.indexOf("?") + 1).split("&").reduce((a, x) => {
        //     const data = x.split("=");
        //     a[decodeURIComponent(data[0])] = decodeURIComponent(data[1]);
        //     return a;
        // }, {});
        // console.log('query_params', query_params);
        // console.log('validateAppUrl', validateAppUrl(window.location.search, 'RTIWfdcgBcEHkdOO0SD7'));
        // console.log('window.location.search', window.location.search);
        // console.log('window.location.hash', window.location.hash);

        return (
            <View popout={this.state.popout} header={false} activePanel={id}>
                <HomePageLayout
                    goAuth={goAuth}
                    id={id}
                    go={go}
                    goForward={goForward}
                    {...this.state}
                    changeGrid={this.changeGrid}
                    onChange={this.onChange}
                    onRefresh={this.onRefresh}
                    declOfNum={this.declOfNum}
                    sortFunc={this.sortFunc}
                    onKeyDown={this.onKeyDown}
                    onSetIsBouquet={onSetIsBouquet}
                    sendFavorites={this.sendFavorites}
                />
            </View>
        );
    }
}

HomePage.propTypes = {
    id: PropTypes.string.isRequired,
    go: PropTypes.func.isRequired,
    goAuth: PropTypes.func.isRequired,
};

export default (connect(
    state => ({
        amountFavorites: state.favorites.amountFavorites,
        setIsBouquet: state.main.setIsBouquet,
        filter: state.main.filter,
        allBouquet: state.main.allBouquet,
        typeBouquet: state.main.typeBouquet,
        saveGrid: state.main.grid,
    }),
    dispatch => ({
        onAddFavorites: (value) => {
            dispatch(addFavorites(value));
        },
        onSetIsBouquet: (value) => {
            dispatch(setIsBouquet(value));
        },
        onSetSearch: (value) => {
            dispatch(setSearch(value))
        },
        onSetFilter: (value) => {
            dispatch(setFilter(value))
        },
        onSetAllBouquet: (value) => {
            dispatch(setAllBouquet(value));
        },
        onSetTypeBouquet: (value) => {
            dispatch(setTypeBouquet(value))
        },
        onSetGrid: (value) => {
            dispatch(setGrid(value));
        }
    }),
)(HomePage));
