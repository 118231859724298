import React from 'react';
import PropTypes from 'prop-types';
import { Panel, FormLayout, FormLayoutGroup, Input, Textarea, Button, Select } from '@vkontakte/vkui';
import axios from 'axios';
import { connect } from 'react-redux';
import { api, apiGetCityListUrl, apiKey, apiSendAddressUrl, apiDeleteAddressUrl } from '../../../constants/api';
import PanelHeader from '../../utils/PanelHeader';
import ButtonIcon from '../../utils/ButtonIcon';

class AddAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      idAddress: null,
      street: '',
      house: '',
      flat: '',
      comment: '',
      cityId: '',
      cityList: null,

      error: {
        title: false,
        street: false,
        house: false,
        cityId: false
      }
    };

    this.onChange = this.onChange.bind(this);
    this.saveAddress = this.saveAddress.bind(this);
    this.getListCity = this.getListCity.bind(this);
    this.isErrorForm = this.isErrorForm.bind(this);
    this.sendAddress = this.sendAddress.bind(this);
    this.onDeleteAddress = this.onDeleteAddress.bind(this);
    this.checkIsDifferent = this.checkIsDifferent.bind(this);
  }

  componentDidMount() {
    if (!!this.props.data && (!!this.props.data.idAddress || this.props.data.idAddress === 0)) {
      const { idAddress } = this.props.data;
      this.setState({
        ...this.props.addresses[idAddress],
        idAddress,
        cityId: this.props.addresses[idAddress].id_city
      });

    }
    this.getListCity();
  }

  onChange(e) {
    const {error} = this.state;
    const { name, value } = e.target;

    if (name === 'title' || name === 'street' || name === 'house' || name === 'cityId') {
      error[name] = value === '';
    }
    this.setState({ [name]: value });
  }

  getListCity() {
    axios.get(`${api}${apiGetCityListUrl}`, { params: { apiKey } })
      .then(response => {
        this.setState({ cityList: response.data.cityList });
      })
      .catch(error => {
        console.log('error', error);
      });
  }

  isErrorForm() {
    const { title, street, house, cityId, error } = this.state;
    if (!!!cityId || !!!street || !!!house || !!!title) {
      error.title = !!!title;
      error.street = !!!street;
      error.house = !!!house;
      error.cityId = !!!cityId;
      this.setState({
        error
      });

      return true;
    }

    return false;
  }

  checkIsDifferent(newData, activeData) {
    const { idAddress } = this.state;
    if (newData.get('title') !== activeData[idAddress].title) return true;
    else if (newData.get('street') !== activeData[idAddress].street) return true;
    else if (newData.get('house') !== activeData[idAddress].house) return true;
    else if (newData.get('flat') !== activeData[idAddress].flat) return true;
    else if (newData.get('comment') !== activeData[idAddress].comment) return true;
    else if (newData.get('cityId') !== activeData[idAddress].cityId) return true;
    else return false;
  }

  saveAddress() {
    const { title, street, house, flat, comment, cityId, idAddress, id } = this.state;
    const userId = localStorage.getItem('userId');

    if (!this.isErrorForm()) {
      const formData = new FormData();
      formData.append('title', title);
      formData.append('street', street);
      formData.append('house', house);
      formData.append('flat', flat);
      formData.append('comment', comment);
      formData.append('cityId', cityId);
      formData.append('userId', userId);
      if (idAddress !== null) {
        formData.append('addressId', id);
      }

      this.sendAddress(formData);
    } else {
      window.scrollTo(0, 0);
    }
  }

  sendAddress(formData) {
    const { go, loadSpinner } = this.props;
    loadSpinner(true);
    const token = localStorage.getItem('token');
    axios({
      method: 'post',
      url: `${api}${apiSendAddressUrl}`,
      params: {
        apiKey,
        token
      },
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    })
      .then((response) => {
        // handle success
        loadSpinner(false);
        console.log(response);
        go('MyAddresses');
      })
      .catch((response) => {
        loadSpinner(false);
        // handle error
        console.log(response);
      });
  }

  onDeleteAddress(e) {
    const { id } = this.state;
    const { go } = this.props;
    const userId = localStorage.getItem('userId');
    const formData = new FormData();
    formData.append('id', id);
    formData.append('userId', userId);
    const token = localStorage.getItem('token');
    axios({
      method: 'post',
      url: `${api}${apiDeleteAddressUrl}`,
      data: formData,
      params: {
        apiKey,
        token
      }
    })
      .then((response) => {
        console.log(response);
        go('MyAddresses');
      });
  }

  render() {
    const { id, go } = this.props;
    const { title, street, house, flat, comment, cityList, cityId, error, idAddress } = this.state;
    return (
      <Panel id={id}>
        <PanelHeader left={<ButtonIcon type="back" onClick={go} dataStory="MyAddresses"/>}>
          {idAddress !== null && idAddress !== undefined ? 'Редактировать адрес' : 'Добавить адрес'}
        </PanelHeader>
        {cityList !== null && (
          <FormLayout>
            <Input
              type="text"
              name="title"
              top="Наименование адреса"
              onChange={this.onChange}
              status={!error.title ? '' : 'error'}
              bottom={!error.title ? '' : 'Пожалуйста, введите "Наименование адреса"'}
              value={title}
            />

            <Select
              top="Город"
              placeholder="Город"
              name="cityId"
              value={cityId}
              status={!error.cityId ? '' : 'error'}
              bottom={!error.cityId ? '' : 'Пожалуйста, выберите "Город"'}
              onChange={this.onChange}>
              {Object.keys(cityList).map((key) => (
                <option key={key} value={key}>{cityList[key]}</option>
              ))}
            </Select>

            <Input
              top="Улица"
              type="text"
              name="street"
              onChange={this.onChange}
              value={street}
              status={!error.street ? '' : 'error'}
              bottom={!error.street ? '' : 'Пожалуйста, введите "Улицу"'}
            />

            <Input
              top="Дом"
              type="text"
              name="house"
              onChange={this.onChange}
              value={house}
              status={!error.house ? '' : 'error'}
              bottom={!error.house ? '' : 'Пожалуйста, введите "Дом"'}
            />

            <FormLayoutGroup top="Квартира">
              <Input type="text" name="flat" onChange={this.onChange} value={flat}/>
            </FormLayoutGroup>
            <Textarea top="Комментарий" name="comment" onChange={this.onChange} value={comment}/>

            <Button className={idAddress !== null ? 'btnSignIn' : 'btnSignIn mb-3'} size="xl"
                    onClick={this.saveAddress}>{idAddress !== null ? 'Сохранить' : 'Добавить'}</Button>
            {idAddress !== null && <Button className="btnRed mb-3 mt-0" size="xl" onClick={this.onDeleteAddress}
                                           data-story="MyAddresses">Удалить</Button>}

          </FormLayout>
        )}
      </Panel>
    );
  }
}

AddAddress.propTypes = {
  id: PropTypes.string.isRequired,
  go: PropTypes.func.isRequired
};

export default connect(
  state => ({
    addresses: state.address.addresses
  })
)(AddAddress);
