/*export const setCategory = (value) => (dispatch) => {
    dispatch({ type: 'SET_CATEGORY', payload: value });
};
export const setColor = (value) => (dispatch) => {
    dispatch({ type: 'SET_COLOR', payload: value });
};
export const setSize = (value) => (dispatch) => {
    dispatch({ type: 'SET_SIZE', payload: value });
};
export const setPrice = (value) => (dispatch) => {
    dispatch({ type: 'SET_PRICE', payload: value });
};
export const setTypePrice = (value) => (dispatch) => {
    dispatch({ type: 'SET_TYPE_PRICE', payload: value });
};
export const setSale = (value) => (dispatch) => {
    dispatch({ type: 'SET_SALE', payload: value });
};
export const setDelivery = (value) => (dispatch) => {
    dispatch({ type: 'SET_DELIVERY', payload: value });
};
*/

export const setFilter = (value) => (dispatch) => {
    dispatch({ type: 'SET_FILTER', payload: value });
};