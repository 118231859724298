import React from 'react';

const InputCount = ({value, onChange, size = 'small', index = 0}) => {
    const onChangeInput = (type, index) => {
        console.log('value', value);
        if (type === 'plus')
            if (value < 1001) {
                onChange(type, '', index)
            }
        if (type === 'minus')
            if (value > 0) {
                onChange(type, '', index)
            }
    };

    return (
        <div className={`inputCountCartWrap ${size}`}>
            <button
                onClick={() => onChangeInput('minus', index)}
                className="buttonCountCart"
                disabled={value < 2}
            >
                -
            </button>
            <input
                className="inputCountCart"
                value={value}
                type="number"
                min="1"
                max="1001"
                onChange={(e) => {
                    onChange('change', e.target.value, index)
                }}
            />
            <button
                onClick={() => {
                    onChangeInput('plus', index)
                }}
                className="buttonCountCart"
            >
                +
            </button>
        </div>
    );
};

export default InputCount;
