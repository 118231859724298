import React from 'react';

const PromoCode = ({ name, value, onChange, onClick, type, minSum, promoCodeNominal, sum, errorMessage }) => {
  let helper = '';
  let error = '';
  if (promoCodeNominal !== 0) {
    if (type) {
      helper = `Скидка по промокоду: -${sum * promoCodeNominal / 100} р. (${promoCodeNominal}%). `;
    } else {
      helper = `Скидка по промокоду: -${promoCodeNominal} р. `;
    }
    helper += `Мин. сумма заказа ${minSum} р.`;
  }

  if(parseFloat(sum) < minSum) {
    error = 'Сумма Вашего заказа меньше, чем требует купон';
  }

  if (!!errorMessage) {
    error = errorMessage;
  }

  return (
    <div>
      <div className="promoCodeWrap">
        <input
          type="text"
          className="promoCodeInput"
          value={value}
          name={name}
          placeholder="Промокод"
          onChange={onChange}
        />
        <button
          onClick={onClick}
          className="promoCodeButton"
          disabled={!value}>
          ✓
        </button>
      </div>
      <div className="promoCodeHelper">
        {helper}
      </div>
      <div className="promoCodeError">
        {error}
      </div>
    </div>
  );
};

export default PromoCode;
