import React from 'react';
import PropTypes from 'prop-types';
import {
  Panel
} from '@vkontakte/vkui';
import * as connectVKUI from '@vkontakte/vkui-connect';
import axios from 'axios';

import { api, apiGetBouquetInfoUrl, apiKey } from '../../constants/api';
import InfoProductLayout from './InfoProductLayout';
import PanelHeader from '../utils/PanelHeader';
import { getPrice } from '../../utils/prepare';
import ButtonIcon from '../utils/ButtonIcon';
import { checkLocalStorage } from '../../utils/checkLocalStorage';
import apiList from '../../utils/apiMethods';
import { setAllBouquet } from '../../actions/allBouquetAction';
import { connect } from 'react-redux';
import { addFavorites } from '../../actions/favoritesAction';


class InfoProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bouquetInfo: null,
      shopInfo: null,
      goodsInfo: [],
      slideIndex: 0,
      countCart: 1,
      isOpenImg: false,
      isOpenInfoShop: false
    };

    this.getInfoBouqet = this.getInfoBouqet.bind(this);
    this.changeSlideIndex = this.changeSlideIndex.bind(this);
    this.onChangeCount = this.onChangeCount.bind(this);
    this.onChangePreviewCount = this.onChangePreviewCount.bind(this);
    this.getSumTotal = this.getSumTotal.bind(this);
    this.addExtraProduct = this.addExtraProduct.bind(this);
    this.goToCheckout = this.goToCheckout.bind(this);
    this.openVkGallery = this.openVkGallery.bind(this);
    this.changeFavorite = this.changeFavorite.bind(this);
    this.openInfoShop = this.openInfoShop.bind(this);
    this.closeInfoShop = this.closeInfoShop.bind(this);
    this.triggerClickOutsideInfoBlock = this.triggerClickOutsideInfoBlock.bind(this);
    this.removeListeners = this.removeListeners.bind(this);
  }

  componentDidMount() {
    this.getInfoBouqet();
    // this.triggerClickOutsideInfoBlock();
    document.addEventListener('click', (e) => this.triggerClickOutsideInfoBlock(e), { passive: false });
  }

  componentWillUnmount() {
    this.removeListeners();
  }

  removeListeners() {
    document.removeEventListener('click', (e) => this.triggerClickOutsideInfoBlock(e));
  }

  triggerClickOutsideInfoBlock(e) {
    if (e.target.id === 'helpShop' || e.target.id === 'hiddenInfoShop') {
      this.openInfoShop();
    } else {
      this.closeInfoShop();
    }
  }

  closeInfoShop() {
    this.setState({
      isOpenInfoShop: false
    });
  }

  openInfoShop() {
    this.setState({
      isOpenInfoShop: true
    });
  }

  changeFavorite(idBouquet) {

    const data = new FormData();
    data.append('userId', localStorage.getItem('userId'));
    data.append('token', localStorage.getItem('token'));
    data.append('codeCity', localStorage.getItem('codeCity'));
    data.append('idBouquet', idBouquet);
    apiList.sendFavorites(idBouquet)
      .then(response => {
        console.log('response', response);
      })
      .catch(error => {
        console.log('error', error);
      });
    this.props.onAddFavorites(this.state.bouquetInfo.favorites === 1 ? -1 : 1);
    this.state.bouquetInfo.favorites = +!this.state.bouquetInfo.favorites;
    this.setState({
      bouquetFiltered: this.state.bouquetFiltered
    });
    this.props.allBouquet.map((value, index) => {
      return value.bouquetList.map((value1, index1) => {
        if (value1.id === idBouquet) {
          this.props.allBouquet[index].bouquetList[index1].favorites = +!this.props.allBouquet[index].bouquetList[index1].favorites;
          this.props.onSetAllBouquet(this.props.allBouquet);
        }
        return null;
      });
    });
  }

  onChangeCount(type, value) {
    switch (type) {
      case 'change':
        if (parseInt(value, 10) < 1002 && parseInt(value, 10) > 0)
          this.setState({ countCart: parseInt(value, 10) });
        break;
      case 'plus':
        this.setState(prevState => ({
          countCart: prevState.countCart < 1002 ? prevState.countCart + 1 : prevState.countCart
        }));
        break;
      case 'minus':
        this.setState(prevState => ({
          countCart: prevState.countCart > 1 ? prevState.countCart - 1 : prevState.countCart
        }));
        break;
      default:
        break;
    }
    console.log('countCart', this.state.countCart);
  }

  onChangePreviewCount(type, value, index) {
    const { goodsInfo } = this.state;
    let product = goodsInfo[index];


    switch (type) {
      case 'change':
        let amount = parseInt(value, 10);
        if (amount < 1002 && amount > 0)
          product = {
            ...product,
            count: parseInt(value, 10)
          };
        break;
      case 'plus':
        if (product.count < 1002)
          product = {
            ...product,
            count: product.count + 1
          };
        break;
      case 'minus':
        if (product.count > 0)
          product = {
            ...product,
            count: product.count > 1 ? product.count - 1 : product.count
          };
        break;
      default:
        break;
    }

    goodsInfo[index] = product;
    this.setState({ goodsInfo });
  }

  getInfoBouqet() {
    const idBouquet = this.props.data.productId;
    const { loadSpinner } = this.props;
    loadSpinner(true);
    axios.get(`${api}${apiGetBouquetInfoUrl}`, {
      params: {
        apiKey,
        idBouquet,
        token: localStorage.getItem('token'),
        userId: localStorage.getItem('userId')
      }
    }).then(response => {
      if (response.data.status === 200) {
        this.setState({
          bouquetInfo: response.data.bouquetInfo,
          shopInfo: response.data.shopInfo,
          goodsInfo: response.data.goodsInfo.map((value => ({
            ...value,
            count: 1,
            isAdd: false
          })))
        });
        loadSpinner(false);
      }
    }).catch(error => {
      console.log('error', error);
    });
  }

  getSumTotal() {
    const { goodsInfo, countCart, bouquetInfo } = this.state;
    let sum = Math.round(bouquetInfo.new_price) * countCart;
    goodsInfo.forEach((value) => {
      sum += value.isAdd ? value.price * value.count : 0;
    });

    return getPrice(sum);
  }

  goToCheckout() {
    const { goForward } = this.props;
    const { bouquetInfo, goodsInfo, countCart } = this.state;

    if (!checkLocalStorage('token') || !checkLocalStorage('userId')) {
      goForward('Authorization');
    } else {
      goForward('Checkout', { bouquetInfo, goodsInfo, countCart });
    }
  }

  addExtraProduct(index) {
    const { goodsInfo } = this.state;
    goodsInfo[index] = {
      ...goodsInfo[index],
      isAdd: !goodsInfo[index].isAdd
    };

    this.setState({ goodsInfo });
  }

  changeSlideIndex(slideIndex) {
    this.setState({ slideIndex });
  }

  openVkGallery() {
    const { bouquetInfo, isOpenImg } = this.state;
    if (!isOpenImg) {
      this.setState({ isOpenImg: true });
      connectVKUI.send('VKWebAppShowImages', {
        images: bouquetInfo.img
      });
      setTimeout(() => {
        this.setState({ isOpenImg: false });
      }, 500);
    }
  }

  render() {
    const { id, go, data, onStoryChange, goForward } = this.props;
    const { bouquetInfo } = this.state;
    return (
      <Panel id={id}>
        <PanelHeader
          left={
            <ButtonIcon type="back" onClick={() => go(data.urlBack, { shopId: bouquetInfo.id_shop })}
                        dataStory={`${data.urlBack}`}/>
          }
        />
        {bouquetInfo !== null && (
          <InfoProductLayout
            {...this.state}
            goForward={goForward}
            changeSlideIndex={this.changeSlideIndex}
            onChangeCount={this.onChangeCount}
            onChangePreviewCount={this.onChangePreviewCount}
            getSumTotal={this.getSumTotal}
            go={go}
            goToCheckout={this.goToCheckout}
            addExtraProduct={this.addExtraProduct}
            openVkGallery={this.openVkGallery}
            changeFavorite={this.changeFavorite}
            openInfoShop={this.openInfoShop}
          />
        )}
      </Panel>
    );
  }
}

InfoProduct.propTypes = {
  id: PropTypes.string.isRequired,
  go: PropTypes.func.isRequired
};

export default (connect(
  state => ({
    allBouquet: state.main.allBouquet
  }),
  dispatch => ({
    onAddFavorites: (value) => {
      dispatch(addFavorites(value));
    },
    onSetAllBouquet: (value) => {
      dispatch(setAllBouquet(value));
    }
  })
)(InfoProduct));

