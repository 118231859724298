import React from 'react';
import {Panel} from '@vkontakte/vkui';
import {connect} from 'react-redux';
import moment from 'moment';
import 'moment/locale/ru';

import CheckoutLayout from './CheckoutLayout';
import PanelHeader from '../utils/PanelHeader';
import apiList from '../../utils/apiMethods';
import ButtonIcon from '../utils/ButtonIcon';
import {calcSumWithPromoCode} from '../../utils/prepare';

class Checkout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            bouquetId: 1,
            amount: 1,
            goodsInfo: '',
            dateTime: '',
            time: '',
            date: new Date(),
            phone: '',
            name: '',
            addressId: '',
            address: '',
            addressList: [],
            comment: '',
            profile: '',
            isSendBouqet: false,
            promoCode: '',
            promoCodeSum: 0,
            promoCodeNominal: 0,
            promoCodeMinSum: 0,
            promoCodeMessage: '',
            promoCodeType: -1,
            bouqetSum: 1890,
            sum: 1290,
            selected: 0,
            typePayment: 'vkpay',
            error: {
                address: false,
                time: false,
                name: false,
                phone: false
            }
        };

        this.onChange = this.onChange.bind(this);
        this.getAddressListAndProfile = this.getAddressListAndProfile.bind(this);
        this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.onChangeActiveTab = this.onChangeActiveTab.bind(this);
        this.prepareData = this.prepareData.bind(this);
        this.sendOrder = this.sendOrder.bind(this);
        this.checkPromoCode = this.checkPromoCode.bind(this);
        this.isErrorForm = this.isErrorForm.bind(this);
    }

    componentDidMount() {
        console.log('Должен скроллить');
        setTimeout(() => {
            window.scrollTo(0,0);
        }, 700);

        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');
        if (token !== 'undefined' && userId !== 'undefined') {
            this.getAddressListAndProfile();
            this.prepareData();
        }
    }

    prepareData() {
        const {data} = this.props;

        const goodsInfoStr = JSON.stringify(data.goodsInfo.filter((x) => {
            return x.isAdd;
        }).map((x) => {
            return `[${x.id},${x.count}]`;
        })).replace(/"/g, '');

        let sumGoods = 0;
        data.goodsInfo.forEach((x) => {
            sumGoods += x.isAdd ? parseFloat(x.count) * parseFloat(x.price) : 0;
        });

        const totalSum = (parseFloat(data.bouquetInfo.new_price) * data.countCart) + sumGoods;
        this.setState({
            bouquetId: data.bouquetInfo.id,
            goodsInfo: goodsInfoStr,
            amount: data.countCart,
            bouqetSum: totalSum,
            sum: totalSum
        });
    }

    onChangeActiveTab(selected) {
        this.setState({selected});
    }

    onChange(e) {
        const {name, value} = e.target;
        const {error, addressList, bouqetSum, promoCodeType, promoCodeNominal} = this.state;


        if (name === 'time' || name === 'address' || name === 'name') {
            error[name] = value === '';
        } else if (name === 'phone') {
            error[name] = !(value.length === 18) && !(value.length === 10);
        }

        const address = addressList.filter((x) => x.id === value)[0];
        if (name === 'addressId' && !!addressList && !!address) {
            error.address = value === '';
            this.setState({
                address: `${address.street} ${address.house}`
            });
        }

        if (name === 'typePayment') {
            this.setState((prevState) => ({
                sum: value === 'cash' ? prevState.bouqetSum : calcSumWithPromoCode(bouqetSum, promoCodeType, promoCodeNominal),
            }));
        }

        this.setState({
            [name]: value,
            error
        });
    }

    isErrorForm() {
        const {error, address, time, name, selected} = this.state;

        let {phone} = this.state;

        phone = phone.replace(/[() -]/g, '');
        error.address = !!!address;
        error.time = !!!time;
        error.name = !!!name;
        error.phone = !(phone.length === 12 || phone.length === 10);
        this.setState({error});

        if (selected === 0) {
            return error.addressId || error.time || error.phone;
        }
        return error.time;
    }

    checkPromoCode() {
        const {promoCode, bouqetSum} = this.state;
        const {loadSpinner} = this.props;

        loadSpinner(true);
        apiList.getPromocodeInfo(promoCode)
            .then((data) => {
                loadSpinner(false);
                const {promocodeInfo, status, message} = data;

                if (status === 200) {
                    this.setState({
                        promoCodeNominal: parseFloat(promocodeInfo.nominal),
                        promoCodeMinSum: promocodeInfo.min_sum,
                        promoCodeType: parseInt(promocodeInfo.type),
                        sum: calcSumWithPromoCode(bouqetSum, promocodeInfo.type, promocodeInfo.nominal),
                        promoCodeMessage: ''
                    });
                } else {
                    this.setState({promoCodeMessage: message});
                }

            })
            .catch((error) => {
                console.log(error);
                loadSpinner(false);
            });
    }

    onChangeDate(date) {
        this.setState({
            date
        });
    }

    onChangeCheckbox(e) {
        const {name} = e.target;
        this.setState((prevState) => ({
            [name]: !prevState[name]
        }));
    }

    sendOrder() {
        const {
            bouquetId, amount, promoCode, date, time, comment, isSendBouqet,
            selected, phone, addressId, typePayment, goodsInfo, name,
            address
        } = this.state;
        const {loadSpinner, onStoryChange, go} = this.props;
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');
        const formData = new FormData();

        if (!this.isErrorForm()) {
            formData.append('bouquetId', bouquetId);
            formData.append('userId', userId);
            formData.append('token', token);
            formData.append('amount', amount);
            formData.append('promoCode', promoCode);
            formData.append('date', moment(date).format('L'));
            formData.append('time', time);
            formData.append('description', comment);
            formData.append('photo', +isSendBouqet);
            formData.append('deliveryType', selected);
            formData.append('paymentType', +(typePayment === 'cash'));
            if (!!goodsInfo) {
                formData.append('goods', goodsInfo);
            }

            if (selected === 0) {
                formData.append('name', name);
                formData.append('phone', phone);
                formData.append('address', address);
                formData.append('addressId', addressId);
            }
            console.log(...formData);

            loadSpinner(true);
            apiList.sendOrder(formData)
                .then(value => {
                    loadSpinner(false);
                    if (value.status === 200) {
                        onStoryChange('OpenDialog', {idOrder: value.requestId});
                        go('EpicNavigation');
                    }
                });
        } else {
            window.scrollTo(0, 0);
        }
    }

    getAddressListAndProfile() {
        const {loadSpinner} = this.props;

        loadSpinner(true);
        Promise.all([apiList.getAddressesList(), apiList.getProfile()]).then(values => {
            loadSpinner(false);
            console.log(values);
            this.setState({
                addressList: values[0].addressList,
                profile: values[1].profile,
                phone: values[1].profile.phone,
                name: values[1].profile.name
            });
        });
    }

    render() {
        const {id, go, goBack} = this.props;
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');

        return (
            <Panel id={id}>
                <PanelHeader
                    left={
                        <ButtonIcon type="back" onClick={goBack}/>
                    }
                >
                    Оформление заказа
                </PanelHeader>
                {(token === 'undefined' || userId === 'undefined') && (
                    <div>
                        Для оформления заказа, пожалуйста, авторизируйтесь!
                        <button onClick={go} data-to="Authorization" className="buttonGreen">
                            Вход/Регистрация
                        </button>
                    </div>
                )}
                {(token !== 'undefined' && userId !== 'undefined') && (
                    <CheckoutLayout
                        {...this.state}
                        onChange={this.onChange}
                        onChangeCheckbox={this.onChangeCheckbox}
                        onChangeDate={this.onChangeDate}
                        onChangeActiveTab={this.onChangeActiveTab}
                        sendOrder={this.sendOrder}
                        checkPromoCode={this.checkPromoCode}
                    />
                )}
            </Panel>
        );
    }
}

export default (connect(
    state => ({
        isPreloader: state.main.isPreloader
    })
)(Checkout));
