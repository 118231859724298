import React from "react";
import {Panel, Input, IOS, platform} from "@vkontakte/vkui";
import '../../scss/_dialog.scss';
import moment from 'moment';
import 'moment/locale/ru';
import PanelHeader from '../utils/PanelHeader';
import ButtonIcon from '../utils/ButtonIcon';
import {Scrollbars} from 'react-custom-scrollbars';
import sendMessageButton from '../../img/sendMessageButton.png'
import defaultImgShop from '../../img/defaultShopImg.png'
import defaultProfileImg from '../../img/defaultProfileImg.png'
import parse from 'html-react-parser';


const osname = platform();

export default class OpenDialogLayout extends React.Component {
    componentDidMount() {
        const {scrollbarRef} = this.props;
        scrollbarRef.current.scrollToBottom();

    }

    renderDialogMessage(message) {
        if (message.indexOf('http') !== -1 && message.indexOf('.jpg') !== -1) {
            return true
        }
        else {
            return false;
        }
    }

    checkHtmlInMessage(message) {

    }

    render() {
        const {id, go, dialogList, userId, inputText, onChange, sendMessage, handleKeyPress, scrollbarRef} = this.props;
        moment.locale('ru');
        console.log(dialogList, 'dialogList============================');
        let date = moment().calendar();
        if (!!dialogList)
            date = moment(new Date(dialogList.chatList[dialogList.lastId].time * 1000)).calendar();
        const chatList = dialogList.chatList;
        return (
            <Panel id={id}>
                <PanelHeader
                    left={
                        <ButtonIcon type="back" onClick={go} dataStory="Chat"/>
                    }
                >
                </PanelHeader>
                <div className="dialogWrap" style={osname !== IOS ? {height: 'calc(100vh - 48px - 55px)'} : {}}>
                    <div className="dialogTime" onClick={() => go('OrderInformation', {idOrder: dialogList.requestId})}>
                        <div className="dialogIdOrder">
                            # {dialogList.requestId}
                        </div>
                        {date}
                    </div>
                    <div className='dialogContent'>
                        <Scrollbars className='dialogScrollbar' ref={scrollbarRef}>
                            {Object.keys(chatList).map((key, index) => (
                                chatList[key].from.userId === userId ?
                                    <div className='dialogMessageBlockLine'>
                                        <div className='dialogMessageBlockFrom'>
                                            <div className='dialogMessageWithImg'>
                                                <div className='dialogMessage'>
                                                    {this.renderDialogMessage(chatList[key].message)  ?
                                                        <img alt='msg' src={chatList[key].message}
                                                             className='dialogImgInMessage'/>
                                                        :
                                                        parse(chatList[key].message)}
                                                </div>
                                                <div className='dialogShopImg'>
                                                    <img alt='profileImg' src={defaultProfileImg}/>
                                                </div>
                                            </div>
                                            <div className='dialogMessageTime'>
                                                {moment(new Date(chatList[key].time * 1000)).format('LT')}
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className='dialogMessageBlockLine'>
                                        <div className='dialogMessageBlockTo'>
                                            <div className='dialogMessageWithImg'>
                                                <div className='dialogShopImg'>
                                                    <img alt='imgShop' src={defaultImgShop}/>
                                                </div>
                                                <div className='dialogMessage'>
                                                    {this.renderDialogMessage(chatList[key].message) ?
                                                        <img alt='msg' src={chatList[key].message}
                                                             className='dialogImgInMessage'/>
                                                        :
                                                        chatList[key].message.indexOf('link-white') !== -1 ?
                                                            <div className='dialogPayButton' onClick={() => go('OrderInformation',{idOrder: dialogList.requestId})}>Оплатить</div>
                                                            :
                                                            chatList[key].message
                                                    }
                                                </div>
                                            </div>
                                            <div className='dialogMessageTime'>
                                                {moment(new Date(chatList[key].time * 1000)).format('LT')}
                                            </div>
                                        </div>
                                    </div>
                            ))}
                        </Scrollbars>
                    </div>
                    <form className='dialogSendMessage' onSubmit={(e) => handleKeyPress(e, dialogList.requestId)}>
                        {/*<Input className='dialogInputMessage' placeholder='Сообщение...' value={inputText}
                               onChange={onChange} onBlur={() => this.forceUpdate()}/>*/}
                        <div className='dialogInputMessage promoCodeWrap'>
                            <input
                                type="text"
                                className="promoCodeInput"
                                value={inputText}
                                placeholder="Сообщение..."
                                onChange={onChange}
                                onBlur={() => this.forceUpdate()}
                            />
                        </div>
                        <img alt='sendMsg' className='dialogImgBlockSendMessage' src={sendMessageButton}
                             onClick={() => sendMessage(dialogList.requestId)}/>
                    </form>
                </div>
            </Panel>
        );
    }

}