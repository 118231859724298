export const getPrice = (price) => `${Math.round(price).toLocaleString()}`;

export const calcSumWithPromoCode = (bouqetSum, type, nominal) => {
  if (type === -1) {
    return bouqetSum;
  }

  return parseInt(type, 10) ? bouqetSum - (bouqetSum * parseFloat(nominal) / 100) : bouqetSum - (parseFloat(nominal, 10));
};

export const getQueryVariable = (variable) => {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  console.log('Query variable %s not found', variable);
};
