import React from 'react';
import {ReactComponent as IconStar} from '../../img/card-bouquet/star.svg';

export const star = (rating, froms) => {
     const sizeSvg = 8;
    return (
        <span className={froms === 'list' ? 'ratingList mr-1' : 'rating' }>
            {rating}
            <IconStar style={{marginLeft: "2px", verticalAlign: "top"}} width={sizeSvg} height={sizeSvg}/>
        </span>
    )
};