import React from "react";
import {
    Avatar,
    FormLayout,
    Input,
    Panel,
    Button
} from "@vkontakte/vkui";
import InputMask from 'react-input-mask';

import PanelHeader from '../utils/PanelHeader';

export default function AuthorizationForm(props) {
    const {id, surname, name, patronymic, email, phone, saveProfile, onChange, avatarChange, imgFile} = props;

    return (
        <Panel id={id}>
            <PanelHeader>
                Профиль
            </PanelHeader>
            <div className="d-flex justify-content-center mt-3">
                <Avatar size={120} style={{marginBottom: 8, objectFit: "cover"}} src={imgFile}></Avatar>
            </div>
            <input accept=".gif,.jpg,.jpeg,.png,.bmp,.dib,.svg" id="imgInput" type="file" name="addImg" onChange={avatarChange} className="collapse"/>
            <label for="imgInput" className="d-flex justify-content-center text-muted addImg-link" ><u>Добавить фото</u></label>
            <FormLayout>
                <Input top="Фамилия" name="surname" value={surname} onChange={onChange}/>
                <Input top="Имя" name="name" value={name} onChange={onChange}/>
                <Input top="Отчество" name="patronymic" value={patronymic} onChange={onChange}/>
                {/*<Input top="Телефон" name="phone" value={phone} onChange={onChange} disabled/>*/}
                <InputMask disabled top="Телефон" onChange={onChange} value={phone} mask="+7 (999) 999-99-99" maskChar=" ">
                    {() => (
                        <Input name='phone' type="tel" placeholder="+7 " />
                    )}
                </InputMask>
                <Input top="Email" type="email" name="email" value={email} onChange={onChange}/>
                <Button type='submit' className="btnSignIn mb-3" onClick={saveProfile} size="xl">Сохранить</Button>
            </FormLayout>
        </Panel>
    )
}
