import React from 'react';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import {Checkbox, Div, Radio, Select, Cell, Button, FormLayoutGroup, platform, IOS} from '@vkontakte/vkui';
import axios from "axios";
import {connect} from "react-redux";
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';

import {api, apiGetFilterListUrl, apiKey} from "../../constants/api";
import {setFilter} from "../../actions/filterAction";
import {checkLocalStorage} from "../../utils/checkLocalStorage";

import '../../scss/utils/_rangeSlider.scss'

const osname = platform();

class RightMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filterList: [],
            filterColor: '',
            filterSize: '',
            filterCategory: '',
            filterSort: 'relevation',
            filterDelivery: 0,
            filterSale: 0,
            // filterPrice: [0, 10000],
            filterPrice: {min: 0, max: 10000}
        };
        this.onChange = this.onChange.bind(this);
        this.onChangeRangeSlider = this.onChangeRangeSlider.bind(this);
        this.filterSend = this.filterSend.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
        this.getCategoryBouquet = this.getCategoryBouquet.bind(this);
        this.setStateBouquet = this.setStateBouquet.bind(this);
        this.closeRightMenu = this.closeRightMenu.bind(this);
        this.editOverflow = this.editOverflow.bind(this);
        this.onChangeComplete = this.onChangeComplete.bind(this);
    }
    targetRef = React.createRef();
    targetElement = null;

    componentDidMount() {
        this.targetElement = this.targetRef.current;
        this.setStateBouquet();
        this.getCategoryBouquet();

        if (this.props.reset === 'true') {
            const filter = {
                category: '',
                color: '',
                size: '',
                price: {min: 0, max: 10000},
                sorting: 'relevation',
                delivery: '',
                sale: '',
                search: '',
            };
            this.props.onSetFilter(filter);
            this.setState({
                filterColor: '',
                filterSize: '',
                filterCategory: '',
                filterSort: 'relevation',
                filterDelivery: '',
                filterSale: '',
                filterPrice: {min: 0, max: 10000},
            })
        }
    }

    componentWillUnmount() {
        document.body.style.overflow = 'auto';
        document.body.style.position = 'static';
        document.getElementById('inputSearch').disabled = false;
    }

    onChangeComplete() {
        console.log('onChangeComplete');
        document.getElementById('idHiddenMenu').style.overflow = 'auto';
    }

    editOverflow(e) {
        document.body.style.overflow = 'hidden';
        document.body.style.position = 'fixed';
        document.getElementById('inputSearch').disabled = true;
    }


    closeRightMenu() {
        document.getElementById('hmtLeft').checked = false;
        document.getElementById('inputSearch').disabled = false;
        document.body.style.overflow = 'auto';
        document.body.style.position = 'static';
    }

    setStateBouquet() {
        const {filter} = this.props;
        const isNoneNull = JSON.stringify(filter) !== '{}';
        this.setState({
            filterColor: isNoneNull ? filter.color : '',
            filterSize: isNoneNull ? filter.size : '',
            filterCategory: isNoneNull ? filter.category : '',
            filterSort: isNoneNull ? filter.sorting : 'relevation',
            filterDelivery: isNoneNull ? filter.delivery : '',
            filterSale: isNoneNull ? filter.sale : '',
            // filterPrice: [0, 10000],
            filterPrice: isNoneNull ? filter.price : {min: 0, max: 10000}
        })
    }

    getCategoryBouquet() {
        axios.get((`${api}${apiGetFilterListUrl}`),
            {
                params: {
                    apiKey,
                    idCity: checkLocalStorage('codeCity') ? localStorage.getItem('codeCity') : 158
                }
            })
            .then((response) => {
                this.setState({
                    filterList: response.data.filterList
                });
            })
            .catch((error) => {
                console.log('error', error);
            });

    }


    resetFilter() {
        const filter = {
            category: '',
            color: '',
            size: '',
            price: {min: 0, max: 10000},
            sorting: 'relevation',
            delivery: '',
            sale: ''
        };
        if (!!this.props.filter)
            filter.search = this.props.filter.search;
        this.props.onSetFilter(filter);
        this.setState({
            filterColor: '',
            filterSize: '',
            filterCategory: '',
            filterSort: 'relevation',
            filterDelivery: '',
            filterSale: '',
            filterPrice: {min: 0, max: 10000},
        })
    }

    onChange(e) {
        const value = e.target.value;
        const name = e.target.name;
        if (name === 'filterDelivery' || name === 'filterSale') {
            console.log('currentTarget', e.currentTarget.checked);
            this.setState({
                [name]: e.currentTarget.checked
            });
        } else {
            this.setState({
                [name]: value
            });
        }
    }

    onChangeRangeSlider(value) {
        document.getElementById('idHiddenMenu').style.overflow = 'hidden';
        this.setState({
            filterPrice: value
        });

    }

    async filterSend() {
        const {go, onSetFilter, getFilteredList} = this.props;
        const {filterSize, filterColor, filterPrice, filterCategory, filterSort, filterDelivery, filterSale} = this.state;

        const filter = {
            category: filterCategory,
            color: filterColor,
            size: filterSize,
            price: filterPrice,
            sorting: filterSort,
            delivery: filterDelivery,
            sale: filterSale,
            type: this.props.filter.type === '' ? 'all' : this.props.filter.type,
            search: this.props.filter.search
        };

        console.log('filter', filter);

        await onSetFilter(filter);
        document.getElementById('hmtLeft').checked = false;
        document.getElementById('inputSearch').disabled = false;
        await (getFilteredList === undefined ? go('ResultFilter') : getFilteredList());
        document.body.style.overflow = 'auto';
        document.body.style.position = 'static';
        // setTimeout(() => {
        //     getFilteredList === undefined ? go('ResultFilter') : getFilteredList();
        // }, 100);
    }

    render() {
        const {filterColor, filterList, filterCategory, filterPrice, filterSize, filterDelivery, filterSale, filterSort} = this.state;
        return (
            <div ref={this.targetRef}>
                <input onChange={this.editOverflow} type="checkbox" id="hmtLeft" className="hiddenMenuTickerRight"/>
                <Div id='idHiddenMenu' className="hiddenMenuRight unselectable p-0">
                    <div className={osname === IOS ? "headerRightMenuIOS" : "headerRightMenu"}>
                        <div className='d-flex align-items-end h-100'>
                            <div className='blockHeaderFilter'>
                                <div>
                                    <div className='d-flex align-items-center styleBlockCloseRightMenu'>
                                        <label className="mb-0" onClick={this.closeRightMenu}>
                                            <i className="material-icons btnMenu">
                                                close
                                            </i>
                                        </label>
                                    </div>
                                </div>
                                <div>
                                    <div className='d-flex justify-content-center w-100 position-absolute'>
                                        <span>Фильтры</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Div>
                        <Select name='filterCategory' onChange={this.onChange} value={filterCategory}
                                className='selectFilter'
                                placeholder="Категория">
                            {!!filterList.category && filterList.category.map((value, index) => (
                                <option key={index} value={value.id_page}>{value.name}</option>
                            ))}
                        </Select>
                        <Select name='filterColor' onChange={this.onChange} value={filterColor} placeholder="Цвет">
                            {!!filterList.color && filterList.color.map((value, index) => (
                                <option key={index} value={value.id}>{value.title}</option>
                            ))}
                        </Select>
                        <Select name='filterSize' onChange={this.onChange} value={filterSize} placeholder="Размер">
                            {!!filterList.size && filterList.size.map((value, index) => (
                                <option key={index} value={value.name}>{value.title}</option>
                            ))}
                        </Select>
                    </Div>

                    <Cell>Цена</Cell>

                    <InputRange
                        step={500}
                        maxValue={10000}
                        minValue={0}
                        value={filterPrice}
                        formatLabel={value => value === 10000 ? '∞' : value}
                        onChangeComplete={this.onChangeComplete}
                        onChange={this.onChangeRangeSlider}/>


                    <FormLayoutGroup>
                        <Checkbox name='filterDelivery' checked={filterDelivery} onChange={this.onChange}
                                  className='mb-0'>Бесплатная
                            доставка</Checkbox>
                        <Checkbox name='filterSale' checked={filterSale} onChange={this.onChange} className=''>Со
                            скидкой</Checkbox>

                    </FormLayoutGroup>


                    <Cell>Сортировать по:</Cell>


                    <FormLayoutGroup value={filterSort} onChange={this.onChange}>
                        <Radio defaultChecked={filterSort === 'relevation'} className='mb-0' name="filterSort"
                               value="relevation">
                            Популярные
                        </Radio>
                        <Radio defaultChecked={filterSort === 'cheap'} className='mb-0' name="filterSort" value="cheap">
                            Сначала дешевые
                        </Radio>
                        <Radio defaultChecked={filterSort === 'dearly'} className='mb-0' name="filterSort"
                               value="dearly">
                            Сначала дорогие
                        </Radio>
                    </FormLayoutGroup>

                    <Button onClick={this.filterSend}
                            className="buttonSignRightMenu mb-4"
                    >
                        Применить
                    </Button>
                    <Button onClick={this.resetFilter} htmlFor="hmtLeft"
                            className="btnResetFilter mt-0 mb-4"
                    >
                        Сбросить
                    </Button>
                </Div>
                <div
                    onClick={this.closeRightMenu}
                    className="darkShadowRight"
                />
            </div>
        )

    }
}

export default (connect(
    state => ({
        filter: state.main.filter,
    }),
    dispatch => ({
        onSetFilter: (value) => {
            dispatch(setFilter(value))
        },
    }),
)(RightMenu));







