export const api = 'https://flawery.ru/api_v3';
export const apiKey = 'K36FRJ85';

export const apiGetCityListUrl = '/getCityList/';
export const apiGetBouquetMainListUrl = '/getBouquetMainList/';
export const apiGetTypeListUrl = '/getTypeList/';
export const apiGetBouquetListUrl = '/getBouquetList/';
export const apiUpdateBouquetListUrl = '/updateBouquetList/';
export const apiGetTypeList = '/getTypeList/';
export const apiGetBouquetInfoUrl = '/getBouquetInfo/';
export const apiGetFilterListUrl = '/getFilterList/';

export const apiSendFavoritesUrl = '/sendFavorites/';
export const apiGetFavorites = '/getFavorites/';

export const apiGetShopListUrl = '/getShopList/';

/* profile */
export const apiGetProfile = '/getProfile/';
export const apiSendProfile = '/sendProfile/';

/* orders */
export const apiGetOrders = '/getRequestList/';
export const apiGetOrderInfoFull = '/getRequestInfoFull/';
export const apiSendOrder = '/sendOrder/';
export const apiGetPayInfo = '/getPayInfo/';

/* promocode */
export const apiGetPromocodeInfo = '/getPromocodeInfo/';


/* chat */
export const apiGetChatList = '/getChatList/';
export const apiGetLastChatList = '/getLastChatList/';
export const apiSendChatMessage = '/sendChatMessage/';

/* Authorization */
export const apiGetAuthSmsUrl = '/sendAuthSms/';
export const apiGetAuthSmsEmailUrl = '/sendAuthEmail/';
export const apiAuthUrl = '/userAuth/';

/* Адресы */
export const apiGetAddressListUrl = '/getAddressList/';
export const apiSendAddressUrl = '/sendAddress/';
export const apiDeleteAddressUrl = '/deleteAddress/';

/* api VK */


/* api StaticPages */


// export const apiGetAccessTokenUrl = 'https://oauth.vk.com/authorize/';
export const clientId = parseInt(process.env.REACT_APP_CLIENT_ID, 10); // ;////6958687; //6981544

export const v = 5.101;

export const apiVK = 'https://api.vk.com/method';
