import React from 'react';
import PropTypes from 'prop-types';
import LikesLayout from './LikesLayout';
import apiList from "../../utils/apiMethods";
import {connect} from "react-redux";
import {setAllBouquet} from "../../actions/allBouquetAction";
import {setGrid} from "../../actions/selectedGridAction";
import {addFavorites} from "../../actions/favoritesAction";


class Likes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            favoritesList: null,
            gridView: 'list',
        };
        this.getFavoritesList = this.getFavoritesList.bind(this);
        this.changeGrid = this.changeGrid.bind(this);
        this.deleteFavorites = this.deleteFavorites.bind(this);
    }

    componentDidMount() {
        const {saveGrid} = this.props;
        if (!!saveGrid) {
            this.setState({
                gridView: saveGrid
            });
        }
        this.getFavoritesList();
    }

    changeGrid(grid) {
        this.setState({
            gridView: grid
        });
        this.props.onSetGrid(grid);
    }

    getFavoritesList() {
        const {loadSpinner} = this.props;
        loadSpinner(true);
        apiList.getFavorites()
            .then(value => {
                this.setState({
                    favoritesList: value.favorites,
                });
                loadSpinner(false);
            })
    }

    deleteFavorites(idBouquet) {
        this.props.allBouquet.map((value, index) => {
            return value.bouquetList.map((value1, index1) => {
                if (value1.id === idBouquet) {
                    this.props.allBouquet[index].bouquetList[index1].favorites = +!this.props.allBouquet[index].bouquetList[index1].favorites;
                    this.props.onSetAllBouquet(this.props.allBouquet);
                }
                return null;
            })
        });
        apiList.sendFavorites(idBouquet)
            .then(() => {
                this.getFavoritesList();
                this.props.onAddFavorites(-1);
            })
    }

    render() {
        const {id, go, loadSpinner} = this.props;
        const {favoritesList} = this.state;
        if (!!favoritesList) {
            return <LikesLayout
                {...this.state}
                id={id}
                go={go}
                changeGrid={this.changeGrid}
                deleteFavorites={this.deleteFavorites}
            />;
        } else {
            return null;
        }

    }
}

Likes.propTypes = {
    id: PropTypes.string.isRequired,
    go: PropTypes.func.isRequired,
};

export default (connect(
    state => ({
        allBouquet: state.main.allBouquet,
        saveGrid: state.main.grid
    }),
    dispatch => ({
        onSetAllBouquet: (value) => {
            dispatch(setAllBouquet(value));
        },
        onSetGrid: (value) => {
            dispatch(setGrid(value));
        },
        onAddFavorites: (value) => {
            dispatch(addFavorites(value))
        },
    }),
)(Likes));
