import React from 'react';
import {Map, YMaps, Placemark} from 'react-yandex-maps';

import CardShop from '../CardShop';
import FloatButton from '../FloatButton';
import placemarkIcon from '../../../img/map/mapPlacemark.png';

class ShopInMapLayout extends React.Component {
    componentDidMount() {
        const {mapInit} = this.props;
        mapInit();
    }

    render() {
        const {go, mapIsLoad, shopList, showShop, activeShop, changeDisplayFormat, displayFormat} = this.props;
        console.log('activeShop', activeShop);
        return (
            <div className="showInMapWrap">
                <YMaps>
                    <Map
                        defaultState={{
                            center: [shopList[0].lat, shopList[0].lng],
                            zoom: 11
                        }}
                        width="100%"
                        height="calc(100vh - 45px - 75px)"
                        onLoad={mapIsLoad}
                        onClick={() => showShop(null)}
                    >
                        {shopList.map((value, index) => (
                            <Placemark
                                key={value.id}
                                geometry={[value.lat, value.lng]}
                                defaultOptions={{
                                    iconLayout: 'default#image',
                                    iconImageHref: placemarkIcon
                                }}
                                onClick={() => showShop(index)}
                            />
                        ))}
                    </Map>
                </YMaps>
                {!!!activeShop && <FloatButton onClick={changeDisplayFormat}>Вернуться к списку</FloatButton>}
                {!!activeShop && (
                    <div className="aboutStore">
                        <CardShop go={go} data={shopList[activeShop]} type="small"/>
                    </div>
                )}
            </div>
        );
    }
};

export default ShopInMapLayout;