import React from 'react';
import {Epic, Tabbar, TabbarItem, View, ScreenSpinner} from '@vkontakte/vkui';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';


import {ReactComponent as IconHome} from '../../img/tabMenu/home.svg';
import {ReactComponent as IconHomeActive} from '../../img/tabMenu/home-active.svg';
import {ReactComponent as IconLike} from '../../img/tabMenu/like.svg';
import {ReactComponent as IconLikeActive} from '../../img/tabMenu/like-active.svg';
import {ReactComponent as IconChat} from '../../img/tabMenu/chat.svg';
import {ReactComponent as IconChatActive} from '../../img/tabMenu/chat-active.svg';
import {ReactComponent as IconOrder} from '../../img/tabMenu/order.svg';
import {ReactComponent as IconOrderActive} from '../../img/tabMenu/order-active.svg';
import {ReactComponent as IconProfile} from '../../img/tabMenu/profile.svg';
import {ReactComponent as IconProfileActive} from '../../img/tabMenu/profile-active.svg';

import ProfileUser from '../profile/ProfileUser';
import HomePage from '../homepage/HomePage';
import InfoProduct from '../infoProduct/InfoProduct';
import Likes from '../likes/Likes';
import Chat from '../chat/Chat';
import ShopInMap from '../shopInMap/ShopInMap';
import MyAddresses from '../myAddresses/MyAddresses';
import MyOrders from '../myOrders/MyOrders';
import SelectCity from '../selectCity/SelectCity';
import OpenDialog from '../chat/OpenDialog';
import AccessError from '../accessError/AccessError';
import ShopInfo from '../shopInfo/ShopInfo';

import CategoryBouquets from '../categoryBouquets/CategoryBouquets';
import ResultFilter from '../resultFilter/ResultFilter';

import AddAddress from '../myAddresses/AddAddress/AddAddress';


import '../../scss/MainStyle.scss';
import PromoCode from '../promoCode/PromoCode';
import OrderInformation from '../orderInfo/OrderInformation';
import {setFavorites} from "../../actions/favoritesAction";


class EpicNavigation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            prevState: 'HomePage',
            activeStory: localStorage.getItem('isConfirmCity') === 'true' ? 'OpenDialog' : 'SelectCity',
            idProduct: null,
            popout: null,
            data: null
        };
        this.onStoryChange = this.onStoryChange.bind(this);
        this.loadSpinner = this.loadSpinner.bind(this);
        this.setMessagesAmount = this.setMessagesAmount.bind(this);
    }

    setMessagesAmount(amount) {
        this.setState({
            messagesAmount: amount
        });
    }

    onStoryChange(e, data = null) {
        console.log(e, data);
        console.log('onStoryChange');
        this.props.onStoryChange(e, data);
    }

    loadSpinner(isLoad) {
        this.setState({
            popout: isLoad ? <ScreenSpinner/> : null
        });
    }


    render() {
        const {id, go, popout, goForward, activeStory, prevState, data, loadSpinner, amountMessages, uploadAmountMessages, amountFavorites, onSetFavorites} = this.props;

        const homeSelected = (activeStory === 'AccessError' && prevState === 'AccessError') || activeStory === 'HomePage' ||
            activeStory === 'InfoProduct' || activeStory === 'PromoCode' || activeStory === 'ShopInMap' ||
            activeStory === 'MyAddresses' || activeStory === 'ResultFilter' || activeStory === 'AddAddress' || activeStory === 'CategoryBouquets' || activeStory === 'SelectCity';

        const likeSelected = activeStory === 'Likes' || (prevState === 'Likes' && activeStory === 'AccessError');
        const chatSelected = activeStory === 'Chat' || (prevState === 'Chat' && activeStory === 'AccessError') || activeStory === 'OpenDialog';
        const orderSelected = activeStory === 'MyOrders' || activeStory === 'OrderInformation' || (prevState === 'MyOrders' && (activeStory === 'AccessError' || activeStory === 'OrderInformation'));
        const profileSelected = activeStory === 'ProfileUser' || (prevState === 'ProfileUser' && activeStory === 'AccessError');

        return (
            <Epic
                id={id}
                activeStory={activeStory}
                tabbar={
                    <Tabbar className="TabbarStyle">
                        <TabbarItem
                            onClick={this.onStoryChange}
                            className='cursorPointer'
                            data-story={
                                'HomePage' || 'InfoProduct' || 'PromoCode' || 'ShopInMap' || 'MyAddresses' || 'AddAddress' ||
                                'SelectCity' || 'ResultFilter' || 'AccessError' || 'CategoryBouquets'
                            }
                        >
                            {!homeSelected && (
                                <IconHome width="28" height="28"/>
                            )}
                            {homeSelected && (
                                <IconHomeActive width="28" height="28"/>
                            )}
                        </TabbarItem>
                        <TabbarItem
                            onClick={this.onStoryChange}
                            data-story={'Likes' || 'AccessError'}
                            className='cursorPointer'
                            label={amountFavorites === 0 ? null : amountFavorites}
                        >
                            {!likeSelected && (
                                <IconLike width="28" height="28"/>
                            )}
                            {likeSelected && (
                                <IconLikeActive width="28" height="28"/>
                            )}
                        </TabbarItem>
                        <TabbarItem
                            onClick={this.onStoryChange}
                            data-story={'Chat' || 'AccessError' || 'OpenDialog'}
                            className='cursorPointer'
                            label={amountMessages === 0 ? null : amountMessages}
                        >
                            {!chatSelected && (
                                <IconChat width="26" height="26"/>
                            )}
                            {chatSelected && (
                                <IconChatActive width="26" height="26"/>
                            )}
                        </TabbarItem>
                        <TabbarItem
                            onClick={this.onStoryChange}
                            className='cursorPointer'
                            data-story={'MyOrders' || 'AccessError'}
                        >
                            {!orderSelected && (
                                <IconOrder width="26" height="26"/>
                            )}
                            {orderSelected && (
                                <IconOrderActive width="26" height="26"/>
                            )}
                        </TabbarItem>
                        <TabbarItem
                            onClick={this.onStoryChange}
                            className='cursorPointer'
                            data-story={'ProfileUser' || 'AccessError'}
                        >
                            {!profileSelected && (
                                <IconProfile width="26" height="26"/>
                            )}
                            {profileSelected && (
                                <IconProfileActive width="26" height="26"/>
                            )}
                        </TabbarItem>
                    </Tabbar>
                }
            >
                <View id="HomePage" header={false} activePanel="HomePage" popout={popout}>
                    <HomePage goForward={goForward} data={data} id="HomePage" goAuth={go} go={this.onStoryChange}
                              loadSpinner={loadSpinner}/>
                </View>

                <View id="CategoryBouquets" header={false} activePanel="CategoryBouquets" popout={popout}>
                    <CategoryBouquets goForward={goForward} data={data} id="CategoryBouquets" goAuth={go}
                                      go={this.onStoryChange}
                                      loadSpinner={loadSpinner}/>
                </View>

                <View id="InfoProduct" header={false} popout={popout} activePanel="InfoProduct">
                    <InfoProduct id="InfoProduct" data={data} go={this.onStoryChange} goForward={goForward}
                                 loadSpinner={loadSpinner} onStoryChange={this.onStoryChange}/>
                </View>

                <View id="SelectCity" header={false} activePanel="SelectCity">
                    <SelectCity id="SelectCity" prevState={this.state.prevState} go={this.onStoryChange}/>
                </View>

                <View id="PromoCode" header={false} activePanel="PromoCode">
                    <PromoCode id="PromoCode" goAuth={go} go={this.onStoryChange}/>
                </View>

                <View id="ShopInMap" header={false} activePanel="ShopInMap">
                    <ShopInMap goForward={goForward} id="ShopInMap" go={this.onStoryChange} goAuth={go}
                               loadSpinner={loadSpinner}/>
                </View>

                <View id="MyAddresses" header={false} activePanel="MyAddresses" popout={popout}>
                    <MyAddresses goForward={goForward} id="MyAddresses" goAuth={go} go={this.onStoryChange}
                                 loadSpinner={loadSpinner}/>
                </View>

                <View id="Likes" header={false} activePanel="Likes" popout={popout}>
                    <Likes id="Likes" go={this.onStoryChange} loadSpinner={loadSpinner}/>
                </View>

                <View id="ProfileUser" header={false} activePanel="ProfileUser" popout={popout}>
                    <ProfileUser id="ProfileUser" go={this.onStoryChange} loadSpinner={loadSpinner}/>
                </View>

                <View id="Chat" header={false} activePanel="Chat">
                    <Chat id="Chat" go={this.onStoryChange} uploadAmountMessages={uploadAmountMessages}
                          loadSpinner={loadSpinner}/>
                </View>

                <View id="OpenDialog" header={false} activePanel="OpenDialog" popout={popout}>
                    <OpenDialog id="OpenDialog" data={data} go={this.onStoryChange}
                                uploadAmountMessages={uploadAmountMessages}
                                loadSpinner={loadSpinner}/>
                </View>

                <View id="MyOrders" header={false} activePanel="MyOrders" popout={popout}>
                    <MyOrders id="MyOrders" go={this.onStoryChange} loadSpinner={loadSpinner}/>
                </View>

                <View id="OrderInformation" header={false} activePanel="OrderInformation">
                    <OrderInformation id="OrderInformation" loadSpinner={loadSpinner} goForward={goForward} data={data}
                                      go={this.onStoryChange}/>
                </View>

                <View id="ResultFilter" header={false} activePanel="ResultFilter" popout={popout}>
                    <ResultFilter id="ResultFilter" go={this.onStoryChange} loadSpinner={loadSpinner}/>
                </View>

                <View id="AddAddress" header={false} activePanel="AddAddress" popout={popout}>
                    <AddAddress id="AddAddress" data={data} go={this.onStoryChange} loadSpinner={loadSpinner}/>
                </View>

                <View id="ShopInfo" header={false} activePanel="ShopInfo" popout={popout}>
                    <ShopInfo id="ShopInfo" data={data} go={this.onStoryChange} loadSpinner={loadSpinner}/>
                </View>

                <View id="AccessError" header={false} activePanel="AccessError">
                    <AccessError
                        prevState={this.state.prevState}
                        id="AccessError"
                        goAuth={this.props.go}
                        goForward={goForward}
                        go={this.onStoryChange}
                    />
                </View>
            </Epic>
        );
    }
}

EpicNavigation.propTypes = {
    id: PropTypes.string.isRequired,
    go: PropTypes.func.isRequired
};

export default (connect(
    state => ({
        popout: state.main.popout,
        amountFavorites: state.favorites.amountFavorites,
        amountMessages: state.chat.amountMessages
    }),
    dispatch => ({
        onSetFavorites: (value) => {
            dispatch(setFavorites(value));
        }
    }),
)(EpicNavigation));
