import React from 'react';
import PropTypes from 'prop-types';
import OrderInformationLayout from './OrderInformationLayout';
import '../../scss/MainStyle.scss';
import {
    api,
    apiKey,
    apiGetOrderInfoFull, clientId
} from '../../constants/api';
import axios from 'axios/index';
import connect from '@vkontakte/vkui-connect';
import apiList from '../../utils/apiMethods'


class OrderInformation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orderInfoList: null,
            fetching: false,
            name: "",
            address: "",
        }
        this.getOrderInfo = this.getOrderInfo.bind(this);
        this.payWithVk = this.payWithVk.bind(this);
    }

    componentDidMount() {
        this.getOrderInfo();
    }


    payWithVk(orderId) {
        apiList.getPayInfo(orderId)
            .then((data) => {
                connect.subscribe((e) => {
                    switch (e.detail.type) {
                        case 'VKWebAppOpenPayFormResult':
                            console.log(e.detail.type);
                            break;
                        case 'VKWebAppOpenPayFormFailed ':
                            console.log(e.detail.type);
                            break;
                        default:
                            console.log(e.detail.type);
                            break;
                    }
                });
                connect.send("VKWebAppOpenPayForm", {
                    "app_id": clientId,
                    "action": "pay-to-service",
                    "params": data.payInfo.params
                });
            });
    }

    getOrderInfo() {
        const {idOrder} = this.props.data;
        const {loadSpinner} = this.props;
        loadSpinner(true);
        axios.get((`${api}${apiGetOrderInfoFull}`),
            {
                params: {
                    apiKey: apiKey,
                    userId: localStorage.getItem('userId'),
                    token: localStorage.getItem('token'),
                    requestId: idOrder,
                }
            })
            .then((response) => {
                console.log('response', response);
                this.setState({
                    orderInfoList: response.data.requestInfo,
                    name: response.data.requestInfo.delivery.recipient_name,
                    address: response.data.requestInfo.delivery.recipient_address,
                    fetching: false,
                })
                loadSpinner(false);
            })
            .catch((error) => {
                this.setState({
                    fetching: false,
                });
                console.log('error', error);
                loadSpinner(false);
            })
    }

    render() {
        const {id, go, goForward} = this.props;
        const {idOrder} = this.props.data;
        const {orderInfoList} = this.state;

        if (!!orderInfoList) {
            return (
                <OrderInformationLayout
                    id={id}
                    go={go}
                    idOrder={idOrder}
                    goForward={goForward}
                    payWithVk={this.payWithVk}
                    {...this.state}/>
            );
        }
        return null;
    }
}

OrderInformation.propTypes = {
    id: PropTypes.string.isRequired,
    go: PropTypes.func.isRequired
};

export default OrderInformation;