import React from 'react';
import PropTypes from 'prop-types';
import ProfileUserForm from "./ProfileUserForm";
import "../../scss/MainStyle.scss"
import {
    api,
    apiKey,
    apiGetProfile,
    apiSendProfile,
} from "../../constants/api";
import axios from 'axios';

class ProfileUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            surname: "",
            name: "",
            patronymic: "",
            phone: "",
            email: "",
            imgFile: null,
        };

        this.onChange = this.onChange.bind(this);
        this.saveProfileChanges = this.saveProfileChanges.bind(this);
        this.avatarChange = this.avatarChange.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
    }


    componentDidMount() {
        const {loadSpinner} = this.props;
        loadSpinner(true);
        axios.get((`${api}${apiGetProfile}`),
            {
                params: {
                    apiKey: apiKey,
                    userId: localStorage.getItem('userId'),
                    token: localStorage.getItem('token'),
                }
            })
            .then((response) => {
                console.log('response Получение данных от Flawery API', response);
                this.setState({
                    surname: response.data.profile.surname,
                    name: response.data.profile.name,
                    patronymic: response.data.profile.patronymic,
                    phone: response.data.profile.phone,
                    email: response.data.profile.email,
                    imgFile: response.data.profile.img,
                });
                loadSpinner(false);
            })
            .catch((error) => {
                console.log('error', error);
                loadSpinner(false);
            })
    }

    onChange(e) {
        const value = e.target.value;
        const name = e.target.name;

        this.setState({
            [name]: value
        })
    }

    avatarChange(event) {
        this.setState({
            imgFile: URL.createObjectURL(event.target.files[0]),
            sendImgFile: event.target.files[0]
        })
    }

    validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    saveProfileChanges() {
        const {loadSpinner}= this.props;
        loadSpinner(true);
        const data = new FormData();
        const {sendImgFile, name,surname, patronymic, email}= this.state;
        data.append('userId', localStorage.getItem('userId'));
        data.append('token', localStorage.getItem('token'));
        data.append('name', name);
        data.append('surname', surname);
        data.append('patronymic', patronymic);
        data.append('email', email);
        sendImgFile && data.append('img', sendImgFile);
        //data.append('_method', "put");
        console.log('sendImgFile', sendImgFile);
        console.log('data.email', email);
        console.log('validateEmail', this.validateEmail(email));

/*        for (const pair of data.entries()) {
            console.log(pair[0] + pair[1]);
        }*/
        console.log(localStorage.getItem('userId'), localStorage.getItem('token'));

        if (this.validateEmail(email) && !!email) {
            axios
              .post(`${api}${apiSendProfile}?apiKey=${apiKey}`,
                data,
                {
                    headers: { 'Content-Type': 'multipart/form-data' },
                }
              )
              .then(response => {
                  loadSpinner(false);
                  console.log('response post для sendProfile', response);
              })
              .catch(error => {
                  loadSpinner(false);
                  console.log('error', error);
              });
        } else {
            loadSpinner(false);
        }
    }

    render() {
        let {id, go} = this.props;
        return (
            <ProfileUserForm
                id={id}
                go={go}
                saveProfile={this.saveProfileChanges}
                onChange={this.onChange}
                avatarChange={this.avatarChange}
                {...this.state}/>
        );
    }
}

ProfileUser.propTypes = {
    id: PropTypes.string.isRequired,
    go: PropTypes.func.isRequired,
    fetchedUser: PropTypes.shape({
        photo_200: PropTypes.string,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        city: PropTypes.shape({
            title: PropTypes.string
        })
    })
};

export default ProfileUser;
