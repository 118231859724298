import React from 'react';
import CardShop from '../CardShop';
import FloatButton from '../FloatButton';

export default function ShopsList({go, shopList, changeDisplayFormat }) {
  return (
    <div className="shopInListWrap">
      {shopList.map((value) => (
        <CardShop go={go} data={value} key={value.id} type="big"/>
      ))}
      <FloatButton onClick={changeDisplayFormat}>Посмотреть на карте</FloatButton>
    </div>
  );
}