import React from 'react';
import { Gallery, Tooltip } from '@vkontakte/vkui';

import PreviewProducts from './PreviewProducts';
import InputCount from '../utils/InputCount';
import { getPrice } from '../../utils/prepare';

import Icon16Like from '@vkontakte/icons/dist/16/like';
import Icon16LikeOutline from '@vkontakte/icons/dist/16/like_outline';
import { checkLocalStorage } from '../../utils/checkLocalStorage';

const InfoProductLayout =
  ({
     slideIndex, changeSlideIndex, bouquetInfo, shopInfo, onChangeCount, countCart,
     goodsInfo, onChangePreviewCount, getSumTotal, addExtraProduct, go, goForward,
     goToCheckout, openVkGallery, changeFavorite, isOpenInfoShop, openInfoShop
   }) => {
    return (
      <div className="infoProductWrap">
        <div className='blockGallery'>
          <Gallery
            slideWidth="100%"
            className='galleryProduct'
            bullets="light"
            align='left'
            slideIndex={slideIndex}
            onClick={() => openVkGallery()}
            onChange={index => changeSlideIndex(index)}
          >
            {bouquetInfo.img.map((value, index) => (
              <img key={value} className="galleryImg" src={value} alt={`Превью ${index}`}/>
            ))}
          </Gallery>
          {console.log('bouquetInfo', bouquetInfo)}
          {checkLocalStorage('token') && checkLocalStorage('userId') &&
          <div onClick={() => changeFavorite(bouquetInfo.id)}
               className='roundLikeList mt-2 ml-2 d-flex justify-content-center'>
            {bouquetInfo.favorites === 0 ?
              <Icon16LikeOutline className='styleLike'/> :
              <Icon16Like className='styleActiveLike'/>
            }
          </div>
          }
          {bouquetInfo.discount !== '0' && <div className="sale"/>}
          <div className="priceInfoProduct">
            <div className="currentPrice">{getPrice(bouquetInfo.new_price)}<span className="rub">&#8381;</span>
            </div>
            {bouquetInfo.discount !== '0' && (
              <div className="oldPrice">{getPrice(bouquetInfo.price)}<span className="rub">&#8381;</span></div>
            )}
          </div>
        </div>
        <div className="infoBlock">
          <div className="title">{bouquetInfo.title.replace(/&quot;/g, '"')}</div>

          <div className="group">
            <div className="groupName">Состав:</div>
            <div className="groupInfo">{bouquetInfo.flowers.replace(/&quot;/g, '"')}</div>
          </div>

          <div className="group">
            <div className="groupName">Описание:</div>
            <div className="groupInfo">{bouquetInfo.information.replace(/&quot;/g, '"')}</div>
          </div>
        </div>

        <div className="infoBlock">
          <div className='d-flex'>
            <div className="title">{shopInfo.title.replace(/&quot;/g, '"')}</div>

            {isOpenInfoShop && <div id='hiddenInfoShop' className='blockInfoShop'>
              <div id='hiddenInfoShop'>ИНН: {shopInfo.recipient.replace(/&quot;/g, '"')}</div>
              <div id='hiddenInfoShop'>ИНН: {shopInfo.inn}</div>
              <div id='hiddenInfoShop'>ОГРН: {shopInfo.ogrn}</div>
            </div>}
            <div id='helpShop' className='helpShop' title='Информация о магазине' onClick={openInfoShop}>
              <i id='helpShop' className="material-icons">
                help_outline
              </i>
            </div>
          </div>
          <div className="labelWrap" data-color="yellow" data-rating="true">
            <span>{shopInfo.rating.replace(/&quot;/g, '"')}</span>
          </div>

          <div className="group">
            <div className="groupInfo">Время работы салона: <b>{shopInfo.today_work_time}</b></div>
            <div className="groupInfo">Время доставки: <b>{shopInfo.today_delivery_time}</b></div>
            <div className="groupInfo">
              Стоимость доставки: <b>{!!shopInfo.delivery_price ? shopInfo.delivery_price : 'Не указана'}
              {/*<span className="rub">₽</span>*/}
            </b>
            </div>
          </div>
        </div>

        {goodsInfo.length !== 0 && (
          <div className="infoBlock">
            <div className="title-small">Добавить к букету:</div>
            <PreviewProducts
              products={goodsInfo}
              onChangeCount={onChangePreviewCount}
              addExtraProduct={addExtraProduct}
            />
          </div>
        )}

        <div className="infoBlock">
          <div>
            Итого:
            <b>{` ${getSumTotal()} `}<span className="rub">&#8381;</span></b>
          </div>
          <div className="countCartWrap">
            <InputCount size="big" onChange={onChangeCount} value={countCart}/>
            <button onClick={goToCheckout} className="cartButton big green">
              Купить
            </button>
          </div>
        </div>
      </div>
    );
  };

export default InfoProductLayout;
