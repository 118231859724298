import React from 'react';

const CartButton = ({onClick, text = "Купить", size = "big", color = "green", dataStory = '', dataTo}) => {
    return (
        <button onClick={onClick} data-to={dataTo} className={`cartButton ${size} ${color}`} data-story={dataStory}>
            {text}
        </button>
    );
};

export default CartButton;
