import React from "react";
import {Panel} from "@vkontakte/vkui";
import LeftMenu from "../utils/LeftMenu";
import PanelHeader from '../utils/PanelHeader';

const MyAddressesLayout = ({id, go, addresses, goForward, goAuth}) => {
    return (
        <Panel id={id}>
            <PanelHeader left={
                <LeftMenu go={go} goAuth={goAuth} goForward={goForward}/>}>
                Мои адреса
            </PanelHeader>

            <div className="MyAddressesWrap">
                {addresses.map((value, index) => (
                    <button key={value.id} className="MyAddressBlock" onClick={(e) => go(e, {idAddress: index})} data-story="AddAddress">
                        {value.title}
                    </button>
                ))}

                <button className="addAddress" onClick={go} data-story="AddAddress">
                    Добавить адрес
                </button>
            </div>
        </Panel>
    );
};

export default MyAddressesLayout;
