import apiList from "../utils/apiMethods";

export const setFavorites = (value) => (dispatch) => {
    dispatch({ type: 'SET_FAVORITES', payload: value });
};

export const addFavorites = (value) => (dispatch) => {
    dispatch({ type: 'ADD_FAVORITES', payload: value });
};

export const getFavorites = (value) => (dispatch) => {
    apiList.getFavorites()
        .then((data) => {
            dispatch({ type: 'SET_FAVORITES', payload: data.total });
        });
};