import React from 'react';
import {Button, Div} from '@vkontakte/vkui';
import {connect} from 'react-redux';
import connectVKUI from '@vkontakte/vkui-connect';
import icon1 from '../../img/iconMenu1.jpg';
import icon2 from '../../img/iconMenu2.jpg';
import icon3 from '../../img/iconMenu3.jpg';
//import icon4 from '../../img/iconMenu4.jpg';
import icon5 from '../../img/iconMenu5.jpg';
import {clientId, v} from '../../constants/api';
import {checkLocalStorage} from '../../utils/checkLocalStorage';

import {setLogout} from '../../actions/logoutAction';
import {ReactComponent as MenuIcon} from '../../img/menu/menu.svg';
import {setFavorites} from "../../actions/favoritesAction";
import {setMessages} from "../../actions/chatAction";

class LeftMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.clearStorageVK = this.clearStorageVK.bind(this);
        this.closeLeftMenu = this.closeLeftMenu.bind(this);
        this.editOverflow = this.editOverflow.bind(this);
        this.goNavigationMenu = this.goNavigationMenu.bind(this);
    }

    componentWillUnmount() {
        document.body.style.overflow = 'auto';
        document.body.style.position = 'static';
        //document.documentElement.style.overflow = 'auto';
    }

    goNavigationMenu(url, go) {
        go(url);
        document.body.style.overflow = 'auto';
        document.body.style.position = 'static';
        document.getElementById('hmt').checked = false;
    }

    getInfo = () =>
        new Promise(resolve => {
            connectVKUI.subscribe(e => {
                switch (e.detail.type) {
                    case 'VKWebAppCallAPIMethodResult':
                        break;
                    case 'VKWebAppCallAPIMethodFailed':
                        console.log('e.request_idrequest_idrequest_idrequest_id', e.detail.request_id);
                        console.log('VKWebAppCallAPIMethodFailed', e.detail.data);
                        break;
                    default:
                        console.log(e.detail.type);
                }
            });

            connectVKUI.send('VKWebAppCallAPIMethod', {
                method: 'storage.set',
                params: {
                    key: 'token',
                    value: '',
                    user_ids: clientId,
                    v,
                    access_token: localStorage.getItem('access_token'),
                    request_id: 'delDataVK'
                }
            });
            connectVKUI.send('VKWebAppCallAPIMethod', {
                method: 'storage.set',
                params: {
                    key: 'userId',
                    value: '',
                    user_ids: clientId,
                    v,
                    access_token: localStorage.getItem('access_token'),
                    request_id: 'delDataVK'
                }
            });
        });

    clearStorageVK(goForward) {
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        this.getInfo();
        this.props.onSetLogOut('');
        this.props.onSetMessages(null);
        this.props.onSetFavorites(null);
        goForward('Authorization');
    }

    closeLeftMenu() {
        document.getElementById('hmt').checked = false;
        document.body.style.overflow = 'auto';
        document.body.style.position = 'static';
        //document.documentElement.style.overflow = 'auto';
    }

    editOverflow(e) {
        document.body.style.overflow = 'hidden';
        document.body.style.position = 'fixed';
        //document.documentElement.style.overflow = 'hidden';
    }

    render() {
        const {go, goAuth, goForward} = this.props;
        // let nameCityShow;
        // if (checkLocalStorage('name') && checkLocalStorage('code')) {
        //     nameCityShow = localStorage.getItem('name');
        // } else if (!checkLocalStorage('name') && checkLocalStorage('code')){
        //
        // }

        return (
            <div style={{height: '100%'}}>
                <input onChange={this.editOverflow} type="checkbox" id="hmt" className="hiddenMenuTicker "/>
                <label className="btnMenu align-self-center unselectable"
                       htmlFor="hmt">
                    <MenuIcon height="24" width="24"/>
                </label>
                <Div className="hiddenMenu p-0">
                    <div className="headerLeftMenu"/>
                    <div className='leftMenuLayout unselectable'>
                        <div className="mainMenuWrap">
                            <div className="mainMenuItem">
                                <Button
                                    onClick={() => this.goNavigationMenu('HomePage', go)}
                                    align="left"
                                    className="itemButtonMenu"
                                >
                                    <img
                                        alt="Выбрать букет"
                                        className="iconMenu"
                                        height="25px"
                                        src={icon1}
                                    />
                                    <span className="itemButtonMenuText">Выбрать букет</span>
                                </Button>
                            </div>
                            <div className="mainMenuItem">
                                <Button
                                    onClick={() => this.goNavigationMenu('ShopInMap', go)}
                                    align="left"
                                    className="itemButtonMenu"
                                >
                                    <img
                                        alt="Салоны рядом"
                                        className="iconMenu"
                                        height="25px"
                                        src={icon2}
                                    />
                                    <span className="itemButtonMenuText">Салоны рядом</span>
                                </Button>
                            </div>
                            <div className="mainMenuItem">
                                <Button
                                    onClick={() => this.goNavigationMenu('MyAddresses', go)}
                                    data-story="MyAddresses"
                                    align="left"
                                    className="itemButtonMenu"
                                >
                                    <img
                                        alt="Мои адреса"
                                        className="iconMenu"
                                        height="25px"
                                        src={icon3}
                                    />
                                    <span className="itemButtonMenuText">Мои адреса</span>
                                </Button>
                            </div>
                            <div className="mainMenuItem">
                                <Button
                                    onClick={goAuth}
                                    data-to="StaticInfoPage"
                                    name="pageDelivery"
                                    align="left"
                                    className="itemButtonMenu">
                                    <img
                                        alt="Наши правила"
                                        className="iconMenu"
                                        height="25px"
                                        src={icon5}
                                    />
                                    <span className="itemButtonMenuText">Наши правила</span>
                                </Button>
                            </div>
                        </div>
                        <Div className="blockAuthLeftMenu">
                            {checkLocalStorage('userId') && checkLocalStorage('token') ?
                                <Button
                                    onClick={() => this.clearStorageVK(goForward)}
                                    data-to="Authorization"
                                    className="buttonSignLeftMenu mb-4"
                                >Выход
                                </Button> :
                                <Button
                                    onClick={() => this.clearStorageVK(goForward)}
                                    data-to="Authorization"
                                    className="buttonSignLeftMenu mb-4"
                                >
                                    Вход/Регистрация
                                </Button>

                            }
                            <br/>
                            <span
                                onClick={go}
                                data-story="SelectCity"
                                className="cityDelivery d-flex justify-content-center mb-3 unselectable"
                            >
              Город доставки:
              <u className="selectCityDelivery">
                {checkLocalStorage('nameCity') && checkLocalStorage('codeCity') ?
                    localStorage.getItem('nameCity') : 'Москва'}
              </u>
            </span>
                        </Div>
                    </div>

                </Div>
                <div
                    onClick={this.closeLeftMenu}
                    className="darkShadow"
                />
            </div>
        );
    }
}

export default (connect(
    state => ({
        setLogout: state.main.setLogout
    }),
    dispatch => ({
        onSetLogOut: (value) => {
            dispatch(setLogout(value));
        },
        onSetFavorites: (value) => {
            dispatch(setFavorites(value));
        },
        onSetMessages: (value) => {
            dispatch(setMessages(value));
        }
    })
)(LeftMenu));
