import React from "react";
import {Panel, PullToRefresh} from "@vkontakte/vkui";
import TabBar from "../utils/TabBar"
import OrderCardForm from './OrderCardForm';
import PanelHeader from '../utils/PanelHeader';
import '../../scss/_orders-list.scss'

export default function MyOrdersLayout(props) {
    const {id, go, orderType, changeOrderType, onRefresh, fetching, currentOrdersList, archiveOrdersList} = props;
    return (
        <Panel id={id}>
            <PanelHeader>
                Мои заказы
            </PanelHeader>
            <TabBar
                firstNameMenu="Текущие"
                secondNameMenu="Архив"
                onChange={changeOrderType}
                selected={orderType}>
            </TabBar>
            <PullToRefresh onRefresh={onRefresh} isFetching={fetching}>
                {orderType === 0 ?
                    (!!currentOrdersList && currentOrdersList.length !== 0 ?
                        currentOrdersList.map((value, index) => (
                            <OrderCardForm value={value} index={index} go={go}></OrderCardForm>
                        ))
                        :
                        <div className='orderNull'>Нет текущих заказов</div>)
                    :
                    (!!archiveOrdersList && archiveOrdersList.length !== 0 ?
                        archiveOrdersList.map((value, index) => (
                            <OrderCardForm value={value} index={index} go={go}></OrderCardForm>
                        ))
                        :
                        <div className='orderNull'>Нет архивных заказов</div>)
                }
            </PullToRefresh>
        </Panel>
    )
}