import React from 'react';
import { FormLayout, Radio } from '@vkontakte/vkui';
import CheckoutDelivery from './CheckoutDelivery';
import CheckoutPickup from './CheckoutPickup';
import PromoCode from '../utils/PromoCode';
import GreenButton from '../utils/GreenButton';
import TabBar from '../utils/TabBar';
import { getPrice } from '../../utils/prepare';

class CheckoutLayout extends React.Component {
  constructor(props) {
    super(props);

    this.deliveryRender = this.deliveryRender.bind(this);
    this.pickupRender = this.pickupRender.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  deliveryRender() {
    return (
      <CheckoutDelivery
        {...this.props}
      />
    );
  }

  pickupRender() {
    return (
      <CheckoutPickup
        {...this.props}
      />
    );
  }

  render() {
    const {
      onChange, promoCode, typePayment, onChangeActiveTab, selected, sendOrder,
      bouqetSum, promoCodeNominal, sum, checkPromoCode, promoCodeType, promoCodeMinSum, promoCodeMessage
    } = this.props;

    const promoCodeSum = promoCodeType ? getPrice(bouqetSum * promoCodeNominal / 100) : getPrice(promoCodeNominal);
    return (
      <div>
        <TabBar
          firstNameMenu="Доставка"
          secondNameMenu="Самовывоз"
          firstContent={this.deliveryRender()}
          secondContent={this.pickupRender()}
          onChange={onChangeActiveTab}
          selected={selected}
        />
        <div className="infoBlock">
          <FormLayout>
            {typePayment === 'online' && (
              <PromoCode
                name="promoCode"
                onChange={onChange}
                type={promoCodeType}
                minSum={promoCodeMinSum}
                promoCodeNominal={promoCodeNominal}
                errorMessage={promoCodeMessage}
                sum={bouqetSum}
                value={promoCode}
                onClick={checkPromoCode}
              />
            )}

            <div className="title">Сумма заказа</div>
            <div className="group">
              <div className="groupInfo">Букет: <b>{getPrice(bouqetSum)}<span
                className="rub">&#8381;</span></b></div>
              <div className="groupInfo">Промокод: - <b>{typePayment === 'online' ? promoCodeSum : 0}<span
                className="rub">&#8381;</span></b></div>
              <div className="groupInfo mt-4">Итого: <b>{getPrice(sum)}<span
                className="rub">&#8381;</span></b></div>
            </div>
          </FormLayout>
        </div>

        <div className="infoBlock">
          <FormLayout>
            <Radio name="typePayment" value="vkpay" checked={typePayment === 'vkpay'} onChange={onChange}>
              Оплата через VK Pay (кешбэк 15%)
            </Radio>
            <Radio name="typePayment" value="online" checked={typePayment === 'online'} onChange={onChange}>Оплата
              онлайн</Radio>
            <Radio name="typePayment" value="cash" checked={typePayment === 'cash'} onChange={onChange}>Оплата
              наличными</Radio>
          </FormLayout>
        </div>

        <div className="checkoutButtonWrap">
          <GreenButton text="Оформить заказ" onClick={sendOrder}/>
        </div>
      </div>
    );
  }
};

export default CheckoutLayout;
