export const getListCurrentTime = (date) => {
  console.log(date);
  const startTime = date > new Date() ? 0 : date.getHours();
  const times = [];

  if (startTime !== 0) {
    times.push('Ближайшее время');
  }
  for (let i = startTime + 1; i <= 23; i++) {
    times.push(`с ${i}:00 до ${i+1}:00`);
  }
  return times;
};
