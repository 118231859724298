import React from 'react';
import {Gallery, Panel, Div} from '@vkontakte/vkui';
import ButtonIcon from '../utils/ButtonIcon';
import PanelHeader from '../utils/PanelHeader'
import BouquetCard from '../utils/BouquetCard'


export default function ShopInfoLayout({id, go, shop, bouquetList, gridView, changeGrid, declOfNum, isAllBouquet, showAllBouquet}) {
    const OpenAllBouquet = (isOpen) => {
        if(isOpen){
            return (
                Object.keys(bouquetList).slice(6, bouquetList.length).map((key, index) => (
                    <BouquetCard
                        go={go}
                        key={key}
                        index={index}
                        value={bouquetList[key]}
                        gridView={gridView}
                        urlBack='ShopInfo'
                    />
                ))
            )
        }
        else
        {
            return(
                bouquetList.length > 6 &&
                <div className='w-100 ml-3 mr-3'>
                    <div className='col-12 mb-2 mt-3 d-flex justify-content-center w-100'>
                        <button  className='showMoreBouquet p-2' onClick={showAllBouquet}>
                            Смотреть
                            ещё {bouquetList.length - 6 + ''} {declOfNum(bouquetList.length - 6, ['букет', 'букета', 'букетов'])}
                        </button>
                    </div>
                    <div className='divLine mb-3'/>
                </div>
            )
        }
    };

    return (

        <Panel id={id}>
            <PanelHeader
                left={
                    <ButtonIcon type="back" onClick={go} dataStory="ShopInMap" value='Все магазины'/>
                }
            >
            </PanelHeader>
            <div className='shopInfoPanel'>
                {!!shop &&
                <div className='shopInfoMain'>
                    <div className='shopInfoImg'>
                        <img src={shop.img}/>
                    </div>
                    <div className="infoBlock">
                        <div className="title">{shop.title.replace(/&quot;/g, '"')}</div>
                        <div className="labelWrap" data-color="yellow" data-rating="true">
                            <span>{shop.rating}</span>
                        </div>

                        <div className="group">
                            <div className="groupInfo">Время работы салона: <b>{shop.today_work_time}</b></div>
                            <div className="groupInfo">Время доставки: <b>{shop.today_delivery_time}</b></div>
                            <div className="groupInfo">Стоимость доставки: <b>{!!shop.delivery_price ? shop.delivery_price : 'Не указана'}</b></div>
                        </div>
                    </div>
                </div>
                }
                {!!bouquetList &&
                <div className='m-3'>
                    <Div className="blockInfoCategory p-0">
                        <div className='mb-1'>
                            <span className='titleCategoryBouquet'>Все букеты ({Object.keys(bouquetList).length})</span>
                        </div>
                        <div>
                            <div className='pl-0 d-flex justify-content-end'>
                                {<div className={gridView === 'list' ? 'gridTypeViewIcon active' : 'gridTypeViewIcon'}
                                      onClick={() => changeGrid('list')}/>
                                }
                                {<div className={gridView === 'scroll' ? 'listTypeViewIcon active' : 'listTypeViewIcon'}
                                      onClick={() => changeGrid('scroll')}/>}
                            </div>
                        </div>
                    </Div>
                    <div className={gridView === 'list' ? 'row rowWrap' : 'row rowWrapList'}>
                        {Object.keys(bouquetList).slice(0, 6).map((key, index) => (
                            <BouquetCard
                                go={go}
                                key={key}
                                index={index}
                                value={bouquetList[key]}
                                gridView={gridView}
                                urlBack='ShopInfo'
                            />
                        ))}
                        {OpenAllBouquet(isAllBouquet)}
                    </div>
                </div>}
            </div>
        </Panel>
    )
}