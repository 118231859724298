import apiList from '../utils/apiMethods';
import {checkLocalStorage} from '../utils/checkLocalStorage';

export const setMessages = (value) => (dispatch) => {
    dispatch({type: 'SET_MESSAGES', payload: value});
};

export const getAmountMessage = () => (dispatch) => {
    let lastIds = localStorage.getItem('lastIds');
    let amount = 0;
    console.log('Последние ID-----', lastIds);
    lastIds = JSON.parse(lastIds);
    apiList.getOrdersList()
        .then(data => {
            let currentOrdersList = data.requestList.current;
            let archiveOrdersList = data.requestList.archive;
            if (checkLocalStorage('lastIds')) {
                console.log('Все заказы===================', currentOrdersList);
                currentOrdersList.forEach((currentOrder) => {
                    if (!!lastIds[currentOrder.id] && !!lastIds[currentOrder.id].idMessage) {
                        apiList.getLastChatList(currentOrder.id, lastIds[currentOrder.id].idMessage)
                            .then((lastChat) => {
                                console.log('lastChatList in App', lastChat.chatList);
                                amount += Object.keys(lastChat.chatList).length;
                                dispatch({type: 'SET_MESSAGES', payload: amount});
                                console.log('amountMessage', amount);
                            });
                    } else {
                        apiList.getChatList(currentOrder.id)
                            .then((chatListResponse) => {
                                console.log('chatList in App', chatListResponse.chatList);
                                amount += Object.keys(chatListResponse.chatList).length;
                                dispatch({type: 'SET_MESSAGES', payload: amount});
                                console.log('amountMessage', amount);
                            });
                    }
                });
                archiveOrdersList.forEach((archiveOrder) => {
                    if (!!lastIds[archiveOrder.id]) {
                        delete lastIds[archiveOrder.id];
                    }
                });
                //localStorage.setItem('lastIds', JSON.stringify(lastIds));

            } else {
                /* console.log('currentOrdersList', currentOrdersList);
                console.log('archiveOrdersList', archiveOrdersList);
                let amount = 0;
                currentOrdersList.forEach(value => {
                    apiList.getChatList(value.id)
                        .then(result => {
                            amount += Object.keys(result.chatList).length;
                            dispatch({type: 'SET_MESSAGES', payload: amount});
                        });
                }); */
            }
        });
};
