import React, {Fragment} from 'react';
import {
    Panel,
    PullToRefresh,
    Checkbox,
    Button,
} from '@vkontakte/vkui';
import '../../scss/_orders-list.scss';
import moment from 'moment';
import 'moment/locale/ru';
import GreenButton from '../utils/GreenButton';
import freeDelivery from '../../img/free-delivery.png';
import speechBubble from '../../img/speech-bubble.png';
import PanelHeader from '../utils/PanelHeader';
import ButtonIcon from '../utils/ButtonIcon';
import VkPayIcon from '../../img/vkpay-icon.png'

export default function OrderInformationLayout({id, go, orderInfoList, onRefresh, fetching, onChange, name, address, goForward, idOrder, payWithVk}) {
    moment.locale('ru');
    const date = moment(new Date(orderInfoList.info.time * 1000)).format('LLL');
    return (
        <Panel id={id}>
            <PanelHeader
                left={
                    <ButtonIcon type="back" onClick={go} dataStory="MyOrders" value="Мои заказы"/>
                }
            >
            </PanelHeader>
            <PullToRefresh onRefresh={onRefresh} isFetching={fetching}>
                <div className="orderFullInfo">
                    <div className='row m-2 numberOrder'>
                        <div className='d-flex col-8 p-0'>
                            <div className='orderTime'>{date}</div>
                        </div>
                        <div className='d-flex col-4 p-0 justify-content-end'>
                            <p className='orderId mb-0 font-weight-bold'># {orderInfoList.info.id}</p>
                        </div>
                    </div>
                    <div className="orderBodyCard  m-2 mt-3">
                        <img alt='иконка букета' className="orderImg" src={orderInfoList.bouquet.img}/>
                        <div className="ml-2 orderDescription">
                            <div className='ml-2 mt-2 orderTitle mb-0'>
                                {orderInfoList.info.title.replace(/&quot;/g, '"')}
                            </div>
                            <div className='ml-2 orderStructure'>
                                <span className="font-weight-bold">Состав: </span>
                                {orderInfoList.bouquet.flowers.replace(/&quot;/g, '"')}
                            </div>
                            {orderInfoList.delivery.check === 0 ?
                                <img alt='доставка' className="ml-2 orderDeliveryImg" src={freeDelivery}/>
                                :
                                null
                            }
                            <div className='ml-2 orderPrice'>
                                {Math.round(orderInfoList.info.price).toLocaleString()}
                                <span className="rub">&#8381;</span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Checkbox checked={orderInfoList.delivery.check === 0} className="orderNeedDelivery">
                            Нужна доставка
                        </Checkbox>
                    </div>
                    <div value="Имя получателя" className="inputOrdersInfo">
                        Имя получателя
                        <div>
                            {name}
                        </div>
                    </div>
                    <div className="inputOrdersInfo divOrderAddressBlock">
                        Адрес и комментарий для курьера
                        <div className="divOrderAddress">
                            {address}
                        </div>
                    </div>
                    <div className="flowerChatBlock">
                        <div className="imgSpeechBubbleBlock">
                            <img alt='Окно' className="imgSpeechBubble"
                                 onClick={() => go('OpenDialog', {idOrder: idOrder})}
                                 src={speechBubble}/>
                        </div>
                        <div className="imgSpeechBubbleTextBlock">
                            <div onClick={() => go('OpenDialog', {idOrder: idOrder})}>
                                Открыть чат с флористом
                            </div>
                        </div>
                    </div>
                    {orderInfoList.payStatus === '0' && (
                        <Fragment>
                            <GreenButton onClick={() => goForward('Payment', {orderId: orderInfoList.info.id})}
                                         text="Оплатить"/>
                            <Button onClick={() => payWithVk(orderInfoList.info.id)} size='l' level='primary' className="orderVkPayButton">
                                Оплатить через
                                <img className='vkPayIcon' src={VkPayIcon}/>
                            </Button>
                        </Fragment>
                    )}
                </div>
            </PullToRefresh>
        </Panel>
    );
};