import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {
    Div,
    Panel,
} from '@vkontakte/vkui';


import '../../scss/MainStyle.scss';


import Icon16Like from '@vkontakte/icons/dist/16/like';
import Icon16LikeOutline from '@vkontakte/icons/dist/16/like_outline';

import {connect} from 'react-redux';
import RightMenu from '../utils/RightMenu';
import PanelHeader from '../utils/PanelHeader';
import {setIsBouquet} from '../../actions/bouquetAction';
import {setFilter} from '../../actions/filterAction';
import {setAllBouquet} from '../../actions/allBouquetAction';
import apiList from '../../utils/apiMethods';
import ButtonIcon from '../utils/ButtonIcon';
import Search from "../utils/Search";
import freeDelivery from "../../img/card-bouquet/free.svg";
import sale from "../../img/sale.png";
import {checkLocalStorage} from "../../utils/checkLocalStorage";
import {star} from "../utils/starIcon";
import axios from "axios";
import {api, apiGetBouquetListUrl, apiKey} from "../../constants/api";
import BouquetCard from "../utils/BouquetCard";
import {addFavorites, getFavorites} from "../../actions/favoritesAction";


class CategoryBouquets extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fetching: false,
            typeList: [],
            bouquetList: this.props.bouquet,
            bouquetFiltered: [],
            search: '',
            filter: null,
            limit: 50,
            total: 0
        };
        this.onRefresh = this.onRefresh.bind(this);
        this.changeGrid = this.changeGrid.bind(this);
        this.sendFavorites = this.sendFavorites.bind(this);
        this.onChangeSearch = this.onChangeSearch.bind(this);
        this.onChange = this.onChange.bind(this);
        this.getFilterBouquet = this.getFilterBouquet.bind(this);
        this.moreBouquet = this.moreBouquet.bind(this);
    }

    componentDidMount() {
        //console.log('this.props.filter', this.props.filter);
        // this.setState({
        //     search: this.props.filter.search
        // });
        this.getFilterBouquet(50);
    }


    sendFavorites(idBouquet, index, indexI) {

        const data = new FormData();
        data.append('userId', localStorage.getItem('userId'));
        data.append('token', localStorage.getItem('token'));
        data.append('codeCity', localStorage.getItem('codeCity'));
        data.append('idBouquet', idBouquet);
        apiList.sendFavorites(idBouquet)
            .then(response => {
                console.log('response', response);
            })
            .catch(error => {
                console.log('error', error);
            });
        this.props.onAddFavorites(this.state.bouquetFiltered[indexI].favorites === 1 ? -1 : 1);
        this.state.bouquetFiltered[indexI].favorites = +!this.state.bouquetFiltered[indexI].favorites;
        this.setState({
            bouquetFiltered: this.state.bouquetFiltered
        });
        this.props.onGetFavorites();
        this.props.allBouquet.map((value, index) => {
            return value.bouquetList.map((value1, index1) => {
                if (value1.id === idBouquet) {
                    this.props.allBouquet[index].bouquetList[index1].favorites = +!this.props.allBouquet[index].bouquetList[index1].favorites;
                    this.props.onSetAllBouquet(this.props.allBouquet);
                }
                return null;
            })
        });
    }

    getFilterBouquet(limit = null) {
        const {bouquetList} = this.state;
        const {loadSpinner} = this.props;

        loadSpinner(true);
        const filtersBouquet = bouquetList.filter;
        console.log('filtersBouquet', filtersBouquet);
        const filter = {
            apiKey,
            idCity: localStorage.getItem('codeCity'),
            userId: localStorage.getItem('userId'),
            sorting: !!filtersBouquet.sorting ? filtersBouquet.sorting : 'relevation',
            //price: filtersBouquet.min || filtersBouquet.max ? {min: filtersBouquet.min, max: filtersBouquet.max}: {min: 0, max: 10000},
            category: !!filtersBouquet.category ? filtersBouquet.category : '',
            size: !!filtersBouquet.size ? filtersBouquet.size : '',
            color: !!filtersBouquet.color ? filtersBouquet.color : '',
            sale: !!filtersBouquet.sale ? filtersBouquet.sale : '',
            type: !!filtersBouquet.type ? filtersBouquet.type : 'all',
            delivery: !!filtersBouquet.delivery ? filtersBouquet.delivery : '',
        };
        if (filtersBouquet.min === false && filtersBouquet.max !== false) {
            filter.price = {min: 0, max: filtersBouquet.max}
        } else if (filtersBouquet.min !== false && filtersBouquet.max === false) {
            filter.price = {min: filtersBouquet.min, max: 10000}
        } else if (filtersBouquet.min === false && filtersBouquet.max === false) {
            filter.price = {min: 0, max: 10000}
        } else {
            filter.price = {min: 0, max: 10000}
        }

        if (this.state.search !== '') {
            filter.search = this.state.search;
        }
        if (limit !== null) {
            filter.limit = limit;
        } else {
            filter.limit = 50;
        }

        if (filter.max === 10000) {
            filter.max = false
        }



        axios
            .get(`${api}${apiGetBouquetListUrl}`, {
                params: filter
            })
            .then(response => {
                console.log('response.data.bouquetList 1111', response);
                this.setState({
                    bouquetFiltered: response.data.bouquetList,
                    total: response.data.total
                });
            })
            .catch(error => {
                console.log('error', error);
            })
            .finally(() => {
                loadSpinner(false);
            });
    }

    moreBouquet() {
        const {limit} = this.state;
        let limitCopy = limit;
        console.log('limit', limitCopy);
        limitCopy = limit + limit;
        this.setState({
            limit: limitCopy
        });

        this.getFilterBouquet(limitCopy);
    }

    onChangeSearch(e) {
        const {bouquetList} = this.state;
        const filtersBouquet = bouquetList.filter;
        if (e.key === 'Enter') {
            console.log('нажал enter');
            // this.props.onSetFilter({
            //     search: this.state.search
            // });
            this.getFilterBouquet(50);
        }
    }

    onChange(e) {
        const {name, value} = e.target;

        if (name === 'search') {
            this.setState({
                [name]: value,
            })
        } else {
            this.setState({
                [name]: value
            })
        }
    }


    onRefresh() {
        this.setState({fetching: true});

        setTimeout(() => {
            this.setState({
                fetching: false
            });
        }, this.updateBouquetList);
    }

    changeGrid(grid) {
        this.setState({
            gridView: grid
        });
    }

    render() {
        const {id, go, goAuth} = this.props;
        const {bouquetList, search, bouquetFiltered, total} = this.state;

        return (
            <Panel id={id}>
                <PanelHeader
                    left={
                        <Fragment>
                            <ButtonIcon type="back" onClick={go} dataStory="HomePage"/>
                            <RightMenu go={go} goAuth={goAuth}/>
                        </Fragment>
                    }
                    bigSelectCity
                >
                    <Search
                        onKeyDown={(e) => this.onChangeSearch(e)}
                        name='search'
                        value={search}
                        onChange={this.onChange}
                        placeholder="Найти букеты..."
                    />
                </PanelHeader>

                <div>
                    <div className="contentBlock">
                        <Div className='pt-0'>
                            <span
                                className='titleCategoryBouquet d-flex justify-content-start mt-1'>
                                <b>{bouquetList.name} ({total})</b>
                                        </span>

                            <div className="row">
                                {
                                    bouquetFiltered.map((valueI, indexI) => (
                                        <Fragment key={indexI}>
                                            <BouquetCard
                                                urlBack='CategoryBouquets'
                                                go={go}
                                                index={indexI}
                                                value={valueI}
                                                changeFavorite={this.sendFavorites}
                                                indexCategory={indexI}
                                                gridView='scroll'
                                            />
                                            {(indexI === this.state.limit - 1) &&
                                            <div className='w-100 ml-3 mr-3'>
                                                <div
                                                    className='col-12 mb-2 d-flex justify-content-center w-100'>
                                                    <button onClick={this.moreBouquet}
                                                            className='showMoreBouquet p-2'>
                                                        Смотреть ещё
                                                    </button>
                                                </div>
                                                <div className='divLine mb-3'/>
                                            </div>
                                            }

                                        </Fragment>
                                    ))
                                }
                            </div>


                        </Div>
                    </div>

                </div>
            </Panel>

        );
    }
}

CategoryBouquets.propTypes = {
    id: PropTypes.string.isRequired,
    go: PropTypes.func.isRequired
};

export default (connect(
    state => ({
        bouquet: state.main.bouquet,
        allBouquet: state.main.allBouquet,
        filter: state.main.filter,
    }),
    dispatch => ({
        onAddFavorites: (value) => {
            dispatch(addFavorites(value));
        },
        onSetIsBouquet: (value) => {
            dispatch(setIsBouquet(value));
        },
        onSetAllBouquet: (value) => {
            dispatch(setAllBouquet(value));
        },
        onSetFilter: (value) => {
            dispatch(setFilter(value))
        },
        onGetFavorites: () => {
            dispatch(getFavorites());
        },
    }),
)(CategoryBouquets));
