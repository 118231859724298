import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';

import MyAddressesLayout from './MyAddressesLayout';
import '../../scss/MainStyle.scss';
import { api, apiGetAddressListUrl, apiKey } from '../../constants/api';

class MyAddresses extends React.Component {
  constructor(props) {
    super(props);

    this.getAddresses = this.getAddresses.bind(this);
  }

  componentDidMount() {
    console.log(!!localStorage.getItem('token'));
    if (!!!localStorage.getItem('token') && !!!localStorage.getItem('userId')) {
      this.props.go('AccessError');
    } else {
      this.getAddresses();
    }
  }

  getAddresses() {
    const { onChangeAddresses, loadSpinner } = this.props;
    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem('token');

    loadSpinner(true);
    axios.get(`${api}${apiGetAddressListUrl}`, {
      params: {
        apiKey,
        userId,
        token
      }
    }).then(response => {
      loadSpinner(false);
      if (response.data.status === 200) {
        onChangeAddresses(response.data.addressList);
      }
    }).catch(error => {
      loadSpinner(false);
      console.log('error', error);
    });
  }

  render() {
    const { id, go, addresses, goForward, goAuth  } = this.props;

    return (
      <MyAddressesLayout
        id={id}
        go={go}
        addresses={addresses}
        goForward={goForward}
        goAuth={goAuth}
      />
    );
  }
}

MyAddresses.propTypes = {
  id: PropTypes.string.isRequired,
  go: PropTypes.func.isRequired
};

export default connect(
  state => ({
    addresses: state.address.addresses
  }),
  dispatch => ({
    onChangeAddresses: (value) => {
      dispatch({ type: 'SET_ADDRESSES', payload: value });
    }
  })
)(MyAddresses);
