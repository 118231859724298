import React from "react";
import {
    Panel,
} from "@vkontakte/vkui";
import '../../scss/_chat-list.scss';
import moment from 'moment';
import "moment/locale/ru";
import PanelHeader from '../utils/PanelHeader';
import defaultImgShop from '../../img/defaultShopImg.png'


export default function ChatLayout(props) {
    const {id, chatsList, lastIds, goDialog, userId} = props;
    moment.locale('ru');
    //console.log(chatsList, 'chatlist',);
    console.log('lastId isNew ----', lastIds);
    return (
        <Panel id={id}>
            <PanelHeader>
                Чаты
            </PanelHeader>

            {!!chatsList ?
                Object.keys(chatsList).map((key, index) => {
                    const lastMessage = chatsList[key].chatList[chatsList[key].lastId];
                    const date = moment(new Date(lastMessage.time * 1000)).format('LT');
                    return (
                        <div key={index} className="chatCardForm" onClick={() => goDialog(key)}>
                            <div className='chatImgBlock'>
                                <img alt='chatImg' className="chatImg" src={defaultImgShop}/>
                            </div>
                            <div className="chatInformation">
                                <div className='chatOrderId'>
                                    # {key}
                                </div>
                                <div className='chatName'>
                                    {lastMessage.from.userId === userId ?
                                        lastMessage.to.name :
                                        lastMessage.from.name}
                                </div>
                                <div className='chatMessage'>
                                    {lastMessage.message.indexOf('link-white') !== -1 ?
                                        "Выставлени счёт на оплату"
                                        :
                                        lastMessage.message
                                    }
                                </div>
                            </div>
                            <div className='chatTime'>
                                <div className='chatDate'>
                                    {date}
                                </div>
                                {lastIds[key].isNew ?
                                    <div className='chatIsNew'>
                                        <div className='chatIsNewText'>
                                            {Object.keys(chatsList[key].chatList).length}
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        </div>
                    );
                })
                :
                <div className="chatNull">Нет новых диалогов</div>
            }

        </Panel>
    )
}
