import 'core-js/es6/map';
import 'core-js/es6/set';
import React from 'react';
import ReactDOM from 'react-dom';
import connect from '@vkontakte/vkui-connect';
import { Provider } from 'react-redux';
import mVKMiniAppsScrollHelper from '@vkontakte/mvk-mini-apps-scroll-helper';
import unselectable from './utils/unselectable';
import {
  platform,
  IOS
} from '@vkontakte/vkui';

import store from './store';
import App from './App';

const osname = platform();
if (osname === IOS) {
// Корневой элемент приложения
  const root = document.getElementById('root');

// Вызываем хелпер, передавая в аргумент корневой элемент приложения
  mVKMiniAppsScrollHelper(root);
}

// import registerServiceWorker from './sw';

// Init VK App
connect.send('VKWebAppInit', {});
connect.send('VKWebAppSetViewSettings', { 'status_bar_style': 'dark', 'action_bar_color': '#64b24a' });

unselectable(document);

// Если вы хотите, чтобы ваше веб-приложение работало в оффлайне и загружалось быстрее,
// расскомментируйте строку с registerServiceWorker();
// Но не забывайте, что на данный момент у технологии есть достаточно подводных камней
// Подробнее про сервис воркеры можно почитать тут — https://vk.cc/8MHpmT
// registerServiceWorker();

ReactDOM.render(
  <Provider store={store}>
    <App/>
  </Provider>
  , document.getElementById('root'));


