import React from 'react';
import {HorizontalScroll} from "@vkontakte/vkui";

import InputCount from '../utils/InputCount';
import CartButton from '../utils/CartButton';

const PreviewProducts = ({products, onChangeCount, addExtraProduct}) => {
    return (
        <HorizontalScroll>
            <div className='' style={{display: 'flex'}}>
                {products.map((value, index) => (
                    <div className="previewProductBlock" key={value.id}>
                        <img src={value.img} className="previewProductImg" alt=""/>
                        <div className="titlePreviewProduct">{value.title}</div>
                        <div className="countCartWrap">
                            <InputCount size="small" onChange={onChangeCount} index={index} value={value.count}/>
                            <CartButton
                                onClick={() => addExtraProduct(index)}
                                text={value.isAdd ? "Отменить" : "Добавить"}
                                color={value.isAdd ? "gray" : "green"}
                                size="small"
                            />
                        </div>
                    </div>
                ))}
            </div>
        </HorizontalScroll>
    );
};

export default (PreviewProducts);
