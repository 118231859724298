import React, { Fragment } from 'react';
import { Div } from '@vkontakte/vkui';
import '../../scss/utils/_bouquet-card.scss';
import { checkLocalStorage } from '../../utils/checkLocalStorage';
import Icon16Like from '@vkontakte/icons/dist/16/like';
import Icon16LikeOutline from '@vkontakte/icons/dist/16/like_outline';
import freeDeliveryImg from '../../img/card-bouquet/free.svg';
import sale from '../../img/sale.png';
import { star } from '../utils/starIcon';
// import { ReactComponent as IconRuble } from '../../img/rubleIcons.svg'

export default function BouquetCard(props) {
  const {
    go,
    index,
    value,
    gridView,
    changeFavorite,
    indexCategory,
    urlBack
  } = props;
  return (
    <Fragment key={index}>
      {gridView === 'list' ?
        <div className='col-6 col-sm-4 pl-2 pr-2 mt-2 mb-3 cursorPointer unselectable'>
          <div className="card mainWrap">
            <div className="card-body p-0" onClick={() => go('InfoProduct', {
              productId: value.id,
              urlBack: urlBack
            })}>
              <div onClick={(e) => e.stopPropagation()} className='photoWrap'>
                {!!changeFavorite && checkLocalStorage('token') && checkLocalStorage('userId') &&
                <div onClick={() => changeFavorite(value.id, indexCategory, index)}
                     className='roundLikeList d-flex justify-content-center'>
                  {value.favorites === 0 ?
                    <Icon16LikeOutline className='styleLike'/> :
                    <Icon16Like className='styleActiveLike'/>
                  }
                </div>
                }
                <img
                  alt='Букет' onClick={() => go('InfoProduct', {
                  productId: value.id,
                  urlBack: urlBack
                })}
                  className="card-img-top imgBouquet"
                  src={value.img}
                  alt='Букет'>
                </img>
                <div onClick={() => go('InfoProduct', {
                  productId: value.id,
                  urlBack: urlBack
                })} className="overlay"/>
              </div>
              {value.free_delivery === '1' &&
              <img
                className=" imgFreeDelivery d-flex justify-content-end"
                src={freeDeliveryImg}
                alt='Бесплатная доставка'>
              </img>
              }

              {value.new_price !== value.price &&
              <img className='imgSale' src={sale} alt='Скидка'/>}
              <div onClick={() => go('InfoProduct', {
                productId: value.id,
                urlBack: urlBack
              })}
                   className='position-relative d-flex ml-2 mt-2'>
                <p className='titleBouquet mb-0'>{value.title.replace(/&quot;/g, '"')}</p>
              </div>
              <div
                className='d-flex align-items-center ml-2 blockPrices'>
                                <span
                                  className='newPrice'>{Math.round(value.new_price).toLocaleString()}
                                  <span className="rub">&#8381;</span>
                                </span>
                {value.new_price !== value.price &&
                <div className='oldPrice ml-2 font-weight-bold'>
                  {Math.round(value.price).toLocaleString()}
                  <span className="rub">&#8381;</span>
                </div>}
              </div>
              <div
                className='w-100 footerCardList d-flex ml-2'>
                <div className='d-flex align-items-end'>
                                    <span className='countSales'>
                                        Купили <b>{value.number_sales}</b> раз(а)
                                    </span>
                  {star(value.rating, 'list')}

                  {/*    <span className='ratingList mr-1'>*/}
                  {/*    {value.rating}*/}
                  {/*        <i className="material-icons ratingStar">star_rate</i>*/}
                  {/*</span>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
        :
        <Div
          className={index === 0 ? 'card w-100 rounded mt-2 mb-3 ml-3 mr-3 p-0 mainWrap cursorPointer unselectable' :
            'card w-100 rounded mt-0 mb-3 ml-3 mr-3 p-0 mainWrap cursorPointer unselectable'}>
          <div className="card-body p-0 row" onClick={() => go('InfoProduct', {
            productId: value.id,
            urlBack: urlBack
          })}>
            <div className='col-6 col-sm-4 position-relative'>
              <div className="blockImg photoWrap">
                <div onClick={() => go('InfoProduct', {
                  productId: value.id,
                  urlBack: urlBack
                })} className="overlay"/>
                <img
                  onClick={() => go('InfoProduct', {
                    productId: value.id,
                    urlBack: urlBack
                  })}
                  className="imgBouquetList"
                  src={value.img}
                  alt='Букет'>
                </img>
                {value.free_delivery === '1' &&
                <img
                  className=" imgFreeDelivery d-flex justify-content-end"
                  src={freeDeliveryImg}
                  alt='Бесплатная доставка'>
                </img>
                }

                {value.new_price !== value.price &&
                <img className='imgSale' src={sale}
                     alt='Скидка'/>}
              </div>
            </div>
            <div className='col-6 col-sm-8 pl-0'>
              <div onClick={() => go('InfoProduct', {
                productId: value.id,
                urlBack: urlBack
              })}
                   className='titleListBouquet mt-2'>{value.title.length > 22 ?
                value.title.slice(0, 22).replace(/&quot;/g, '"') + '...' : value.title.replace(/&quot;/g, '"')}<br/>
              </div>

              {star(value.rating, 'grid')}
              <div className='d-flex align-items-center mt-3'>
                                <span className='newPrice font-weight-bold'>
                                    {Math.round(value.new_price).toLocaleString()}
                                  <span className="rub">&#8381;</span>
                                </span>
                {value.new_price !== value.price &&
                <div
                  className='oldPrice ml-2 font-weight-bold'>{Math.round(value.price).toLocaleString()}<span
                  className='rub'>&#8381;</span></div>}
              </div>
              <div className='w-100 footerCard footerScroll d-flex' onClick={(e) => e.stopPropagation()}>
                <div className='d-flex align-items-end'>
                  <span className='countSales'>Купили <b>{value.number_sales}</b> раз(а)</span>
                </div>
                {!!changeFavorite && checkLocalStorage('token') && checkLocalStorage('userId') &&
                <div
                  onClick={() => changeFavorite(value.id, indexCategory, index)}
                  className='roundLike d-flex justify-content-center'>
                  {value.favorites === 0 ?
                    <Icon16LikeOutline className='styleLike'/>
                    :
                    <Icon16Like className='styleActiveLike'/>
                  }
                </div>
                }
              </div>
            </div>
          </div>
        </Div>
      }
    </Fragment>
  );
}