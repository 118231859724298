import {combineReducers} from 'redux';

import main from './main';
import address from './address';
import chat from './chat';
import favorites from './favorites';

export default combineReducers({
    main,
    address,
    chat,
    favorites,
});
