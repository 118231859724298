import React, { Fragment } from 'react';
import {
  PullToRefresh,
  Div,
  HorizontalScroll,
  Panel,
  platform, IOS,
} from '@vkontakte/vkui';
import { connect } from 'react-redux';
import Img from 'react-image';

import '../../scss/MainStyle.scss';

import LeftMenu from '../utils/LeftMenu';
import RightMenu from '../utils/RightMenu';
import Search from '../utils/Search';
import PanelHeader from '../utils/PanelHeader';
import BouquetCard from '../utils/BouquetCard';

function goCategory(selectBouquets, index, onSetIsBouquet, go) {
  console.log('selectBouquets', selectBouquets);
  onSetIsBouquet(selectBouquets);
  go('CategoryBouquets', { index: index });
}

function HomePageLayout(props) {
  const {
    id, go, sortFunc, onKeyDown, sendFavorites, bouquetMainList, search, typeList, goAuth, gridView,
    changeGrid, fetching, onRefresh, declOfNum, onSetIsBouquet, onChange, goForward
  } = props;
  const itemStyle = {
    flexShrink: 0,
    width: 220,
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    paddingLeft: '15px'
  };

  return (
    <Panel className='PanelStyle' id={id}>
      <PanelHeader
        left={
          <Fragment>
            <LeftMenu goForward={goForward} go={go} goAuth={goAuth}/>
            <RightMenu reset='true' go={go} goAuth={goAuth}/>
          </Fragment>
        }
        big
      >
        <Search
          onKeyDown={onKeyDown}
          name='search'
          value={search}
          onChange={onChange}
          placeholder="Найти букеты..."
        />
      </PanelHeader>

      <PullToRefresh onRefresh={onRefresh} isFetching={fetching}>
        <div className="contentBlock">
          {/*<div className="horizontalScroll">
            {typeList !== undefined && Object.entries(typeList).map((value, index) => (
              <div data-story='CategoryBouquets unselectable' key={index}
                   style={itemStyle}>
                <div className='card cardHScroll cursorPointer' onClick={() => sortFunc(value[1].type)}>
                  <div className='row'>
                    <div className='col-5 m-0 pr-0'>

                      <Img loader={
                        <div style={{ background: '#ececed', height: '60px', width: '82px' }}>
                          <div className='loader'/>
                        </div>
                      } alt='Букет' className='styleImgHScroll'
                           height='60px' width='82.08px'
                           src={value[1].ico}/>
                    </div>
                    <div className='d-flex align-items-center col-7 pl-2 m-0'>
                      <span className='titleUpMenu'>{value[1].title}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>*/}
          <div className="HorizontalStyleWrap">
            <HorizontalScroll className='HorizontalStyle pt-3'>
              <div className='' style={{ display: 'flex' }}>
                {typeList !== undefined && Object.entries(typeList).map((value, index) => (
                  <div data-story='CategoryBouquets unselectable' key={index}
                       style={itemStyle}>
                    <div className='card cardHScroll cursorPointer' onClick={() => sortFunc(value[1].type)}>
                      <div className='row'>
                        <div className='col-5 m-0 pr-0'>

                          <Img loader={
                            <div style={{ background: '#ececed', height: '60px', width: '82px' }}>
                              <div className='loader'/>
                            </div>
                          } alt='Букет' className='styleImgHScroll'
                               height='60px' width='82.08px'
                               src={value[1].ico}/>
                        </div>
                        <div className='d-flex align-items-center col-7 pl-2 m-0'>
                          <span className='titleUpMenu'>{value[1].title}</span>
                        </div>
                      </div>
                    </div>

                  </div>
                ))}
              </div>
            </HorizontalScroll>
          </div>

          <Div className='mt-2'>
            {bouquetMainList !== undefined && bouquetMainList.map((value, index) => (
              <div key={index}>
                <Div className="blockInfoCategory p-0 unselectable">
                  <div className='mt-3'>
                                        <span
                                          className='titleCategoryBouquet'><b>{value.name.replace(/&quot;/g, '"')}</b> {value.name.replace(/&quot;/g, '"') !== 'Рекомендуем' ? `(${value.count})` : ''}</span>
                  </div>
                  <div>
                    <div className='pl-0 d-flex justify-content-end'>
                      {index === 0 &&
                      <div
                        className={gridView === 'list' ? 'gridTypeViewIcon active' : 'gridTypeViewIcon'}
                        onClick={() => changeGrid('list')}/>
                      }
                      {index === 0 &&
                      <div
                        className={gridView === 'scroll' ? 'listTypeViewIcon active' : 'listTypeViewIcon'}
                        onClick={() => changeGrid('scroll')}/>}
                    </div>
                  </div>
                </Div>
                <div className={gridView === 'list' ? 'row rowWrap' : 'row rowWrapList'}>
                  {
                    value.bouquetList.slice(0, 6).map((valueI, indexI) => (
                      <Fragment key={indexI}>
                        <BouquetCard
                          urlBack='HomePage'
                          go={go}
                          index={indexI}
                          value={valueI}
                          changeFavorite={sendFavorites}
                          indexCategory={index}
                          gridView={gridView}
                        />
                        {(indexI === 5 && value.bouquetList.length - 6 !== 0) &&
                        <div className='w-100 ml-3 mr-3'>
                          <div
                            className='col-12 mb-2 mt-3 d-flex justify-content-center w-100'>
                            <button onClick={() => {
                              goCategory(value, index, onSetIsBouquet, go);
                            }} className='showMoreBouquet p-2'>
                              Смотреть
                              ещё {value.count - 6 + ''} {declOfNum(value.count - 6, ['букет', 'букета', 'букетов'])}
                            </button>
                          </div>
                          <div className='divLine'/>
                        </div>
                        }
                      </Fragment>
                    ))
                  }
                </div>
              </div>
            ))}

          </Div>
        </div>
      </PullToRefresh>
    </Panel>
  );
}

export default (connect(
  state => ({
    setIsBouquet: state.main.setIsBouquet
  })
)(HomePageLayout));
