import React, { Fragment } from 'react';
import { Checkbox, FormLayout, Input, Select, Textarea, FormLayoutGroup } from '@vkontakte/vkui';
import InputMask from 'react-input-mask';
import SelectDateTime from '../utils/SelectDateTime';
import { getListCurrentTime } from '../../utils/dateAndTime';

const CheckoutDelivery =
  ({
     onChange, addressId, addressList, phone, comment, isSendBouqet,
     onChangeCheckbox, dateTime, time, date, onChangeDate, error, name, address
   }) => {
    return (
      <Fragment>
        <div className="infoBlockCheckout">
          <FormLayout>
            <SelectDateTime
              value={dateTime}
              time={time}
              date={date}
              onChangeDate={onChangeDate}
            />

            <Select
              placeholder="Выберите время заказа"
              top="Время заказа"
              value={time}
              onChange={onChange}
              name="time"
              status={!error.time ? 'default' : 'error'}
              bottom={!error.time ? '' : 'Пожалуйста, выберите Время'}
            >
              {getListCurrentTime(date).map((valueTime, index) => (
                <option value={valueTime} key={index}>{valueTime}</option>
              ))}
            </Select>

            <FormLayoutGroup top="Телефон получателя">
              <InputMask
                onChange={onChange}
                value={phone}
                mask="+7 (999) 999-99-99"
                maskChar=" ">
                {() => (

                  <Input
                    type="text"
                    value={phone}
                    name="phone"
                    status={!error.phone ? 'default' : 'error'}
                    bottom={!error.phone ? '' : 'Пожалуйста, укажите Телефон'}
                    placeholder="+7 ()"
                  />
                )}
              </InputMask>
            </FormLayoutGroup>

            <Input
              top="Имя получателя"
              name="name"
              value={name}
              onChange={onChange}
              status={!error.name ? 'default' : 'error'}
              bottom={!error.name ? '' : 'Пожалуйста, укажите Имя'}
            />

            {addressList.length !== 0 && (
              <Select
                top="Адрес"
                placeholder={addressList.length === 0 ? 'Добавьте адрес' : 'Выберите адрес'}
                name="addressId"
                value={addressId}
                onChange={onChange}>
                {addressList.map((value) => (
                  <option value={value.id} key={value.id}>{value.title}</option>
                ))}
              </Select>
            )}

            <Textarea
              top="Адрес доставки"
              name="address"
              onChange={onChange}
              status={!error.address ? 'default' : 'error'}
              bottom={!error.address ? '' : 'Укажите или выберите из списка "Адрес"'}
              value={address}
            />

            <Textarea top="Комментарий для курьера" name="comment" onChange={onChange} value={comment}/>

            <Checkbox name="isSendBouqet" checked={isSendBouqet} onClick={onChangeCheckbox}>Прислать фото
              букета перед
              отправкой</Checkbox>
          </FormLayout>
        </div>
      </Fragment>
    );
  };

export default CheckoutDelivery;
