import React, {Fragment} from 'react';
import { View, ConfigProvider, ScreenSpinner, Alert, IOS, platform } from '@vkontakte/vkui';
import vkuiConnect from '@vkontakte/vkui-connect';
import '@vkontakte/vkui/dist/vkui.css';
import {connect as connectRedux} from 'react-redux';
import {Offline, Online} from "react-detect-offline";


import Authorization from './components/authorization/Authorization';
import EpicNavigation from './components/epic/EpicNavigation';
import Checkout from './components/checkout/Checkout';
import Preloader from './components/preloader/Preloader';
import StaticInfoPage from "./components/infopage/StaticInfoPage";
import Payment from "./components/payment/Payment";
import ConnectionLoss from "./components/connectionLoss/ConnectionLoss";


import {checkLocalStorage} from "./utils/checkLocalStorage";
import {setFavorites, getFavorites} from "./actions/favoritesAction";
import connect from '@vkontakte/vkui-connect';
import {getAmountMessage} from "./actions/chatAction";
import {getQueryVariable} from './utils/prepare';

const osname = platform();

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activePanel: 'Preloader',//'Preloader', //: 'EpicNavigation',
            history: ['EpicNavigation'], //:'EpicNavigation'
            nameInfoPage: "",
            lastDataStory: "",
            popout: null,
            data: [null],
            dataStory: null,
            prevState: 'HomePage',
            activeStory: (localStorage.getItem('isConfirmCity') === 'true' && checkLocalStorage('codeCity')) ? 'HomePage' : 'SelectCity',
            amountMessage: 0,
        };

        this.loadSpinner = this.loadSpinner.bind(this);
        this.onStoryChange = this.onStoryChange.bind(this);
        this.closePopout = this.closePopout.bind(this);
        this.openDefault = this.openDefault.bind(this);
        this.openDefaultError = this.openDefaultError.bind(this);
        this.uploadAmountMessages = this.uploadAmountMessages.bind(this);
        this.opedDefaultAccessToken = this.opedDefaultAccessToken.bind(this);
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //     console.log('nextState', nextState.activePanel === "Checkout");
    //     if (nextState.activePanel === "Checkout")
    //         window.scrollTo(0,0);
    //     return true;
    // }

    componentDidMount() {

        connect.send('VKWebAppGetUserInfo', {});
        connect.subscribe(e => {
            switch (e.detail.type) {
                case 'VKWebAppGetUserInfoResult':
                    !!e.detail.data.city.title && localStorage.setItem('nameCity', e.detail.data.city.title);
                    break;
                default:
                    console.log(e.detail.type);
            }
        });
        if (localStorage.getItem('isConfirmCity') === 'true' && checkLocalStorage('codeCity') && checkLocalStorage('nameCity')) {
            this.setState({
                activeStory: 'HomePage'
            })
        } else {
            this.setState({
                activeStory: 'SelectCity'
            })
        }

        this.setState({
            activePanel: 'EpicNavigation'
        });
        if (checkLocalStorage('token') && checkLocalStorage('userId')) {
            this.uploadAmountMessages();
            this.uploadFavoritesAmount();
        }
    }

    closePopout() {
        this.setState({popout: null});
    }

    openDefault() {
        this.setState({
            popout:
                <Alert
                    actions={[{
                        title: 'Ок',
                        autoclose: true,
                    }]}
                    onClose={this.closePopout}
                >
                    <h2>Код подтверждения отправлен</h2>
                </Alert>
        });
    }

    openDefaultError() {
        this.setState({
            popout:
                <Alert
                    actions={[{
                        title: 'Ок',
                        autoclose: true,
                    }]}
                    onClose={this.closePopout}
                >
                    <h2>Ошибка отправки кода подтверждения</h2>
                </Alert>
        });
    }

    opedDefaultAccessToken() {
        this.setState({
            popout:
                <Alert
                    actions={[{
                        title: 'Ок',
                        autoclose: true,
                    }]}
                    onClose={this.closePopout}
                >
                    <h2>Разрешите доступ к профилю для авторизации и избежания повторного входа с других устройств</h2>
                </Alert>
        });
    }

    loadSpinner(isLoad) {
        this.setState({
            popout: isLoad ? <ScreenSpinner/> : null,
        });
    }

    uploadAmountMessages() {
        this.props.onGetMessages();
    }

    onStoryChange(e, data = null) {
        this.setState({dataStory: data});
        if (e.currentTarget !== undefined) {
            this.setState({
                prevState: e.currentTarget.dataset.story
            });
            if ((localStorage.getItem('isConfirmCity') !== 'true' || !checkLocalStorage('codeCity') || !checkLocalStorage('nameCity')) && e.currentTarget.dataset.story === 'HomePage') {
                this.setState({
                    activeStory: 'SelectCity'
                });
            } else if (!checkLocalStorage("token") && !checkLocalStorage("userId")) {
                if (
                    e.currentTarget.dataset.story === 'Chat' ||
                    e.currentTarget.dataset.story === 'PromoCode' ||
                    e.currentTarget.dataset.story === 'MyAddresses' ||
                    e.currentTarget.dataset.story === 'ProfileUser' ||
                    e.currentTarget.dataset.story === 'MyOrders' ||
                    e.currentTarget.dataset.story === 'Checkout' ||
                    e.currentTarget.dataset.story === 'Likes'
                ) {
                    this.onStoryChange('AccessError');
                } else {
                    this.setState({
                        activeStory: e.currentTarget.dataset.story
                    });
                }
            } else {
                this.setState({
                    activeStory: e.currentTarget.dataset.story,
                });
            }
        }
        if (e.currentTarget === undefined) {
            if (e === 'HomePage' && localStorage.getItem('isConfirmCity') !== 'true' && checkLocalStorage('codeCity')) {
                this.setState({
                    activeStory: 'SelectCity'
                })
            } else {
                this.setState({
                    activeStory: e
                });
            }
        }
    }

    go = (e, value = null) => {
        const {data} = this.state;
        data.push(value);
        this.setState({data});
        if (e.currentTarget !== undefined) {
            this.setState({
                lastDataStory: this.state.activePanel,
                activePanel: e.currentTarget.dataset.to
            });
            if (e.currentTarget.name !== undefined) {
                this.setState({
                    nameInfoPage: e.currentTarget.name,
                })
            }
        }
        if (e.currentTarget === undefined)
            this.setState({
                lastDataStory: this.state.activePanel,
                activePanel: e
            });

    };


    uploadFavoritesAmount() {
        this.props.onGetFavorites();
    }

    goBack = () => {
        const {data, prevState, dataStory, activeStory} = this.state;
        const history = [...this.state.history];
        console.log("history data", data, history, dataStory);

        history.pop();
        data.pop();
        const activePanel = history[history.length - 1];
        if (activePanel === 'main') {
            vkuiConnect.send('VKWebAppDisableSwipeBack');
        }
        this.setState({history, activePanel, data});
        console.log('PREV_STATE', prevState);
        console.log('PREV_STATE', checkLocalStorage("token"));
        console.log('PREV_STATE', checkLocalStorage("userId"));
        console.log('PREV_STATE', !checkLocalStorage("token") && !checkLocalStorage("userId"));

        if (!checkLocalStorage("token") && !checkLocalStorage("userId")) {
            if (prevState === 'Chat' ||
                prevState === 'PromoCode' ||
                prevState === 'MyAddresses' ||
                prevState === 'ProfileUser' ||
                prevState === 'MyOrders' ||
                prevState === 'Checkout' ||
                prevState === 'Likes'
            ) {
                this.onStoryChange('AccessError')
            } else {
                //this.onStoryChange('HomePage');

                this.onStoryChange(activeStory, dataStory);

            }
        } else {
            this.onStoryChange(activeStory === 'AccessError' ? prevState : activeStory, dataStory);

            //this.onStoryChange(prevState);
        }

    };

    goForward = (activePanel, newData = null) => {
        const {data} = this.state;
        data.push(newData);
        this.setState({data});
        const history = [...this.state.history];
        history.push(activePanel);
        if (this.state.activePanel === 'main') {
            vkuiConnect.send('VKWebAppEnableSwipeBack');
        }
        this.setState({history, activePanel});
    };

    onSwipeBack = (e) => {
        connect.send('VKWebAppDisableSwipeBack');
        e.preventDefault();
    };

    render() {
        const {
            nameInfoPage,
            lastDataStory,
            popout,
            data: newData,
            history,
            prevState,
            activeStory,
            dataStory,
            amountMessages
        } = this.state;
        let indexData = 0;
        let data = null;
        if (!!newData) {
            indexData = newData.length === 1 ? 0 : newData.length - 1;
            data = newData[indexData];
        }

        const isMobileSafari = getQueryVariable('vk_platform') === 'mobile_web';

        return (
            <Fragment>
                {isMobileSafari && (
                  <div style={{padding: '32px 16px', textAlign: 'center'}}>Cервис доступен на других платформах</div>
                )}
                {!isMobileSafari && (
                  <Online>
                      <ConfigProvider isWebView={false}>
                          <View
                            header={false}
                            onSwipeBack={this.onSwipeBack}
                            history={this.state.history}
                            popout={popout}
                            activePanel={this.state.activePanel}
                            id='viewWrap'
                          >
                              <Preloader id="Preloader"/>
                              <Authorization
                                id="Authorization"
                                go={this.go}
                                goBack={this.goBack}
                                goForward={this.goForward}
                                openPopout={this.openDefault}
                                openDefaultError={this.openDefaultError}
                                opedDefaultAccessToken={this.opedDefaultAccessToken}
                                size="big"
                              />
                              <Checkout
                                id="Checkout"
                                go={this.go}
                                data={data}
                                goBack={this.goBack}
                                loadSpinner={this.loadSpinner}
                                onStoryChange={this.onStoryChange}
                              />
                              <EpicNavigation
                                id="EpicNavigation"
                                fetchedUser={this.state.fetchedUser}
                                goForward={this.goForward}
                                data={dataStory}
                                loadSpinner={this.loadSpinner}
                                activeStory={activeStory}
                                onStoryChange={this.onStoryChange}
                                prevState={prevState}
                                go={this.go}
                                amountMessages={this.props.amountMessages}
                                uploadAmountMessages={this.uploadAmountMessages}
                              />
                              <StaticInfoPage
                                id="StaticInfoPage"
                                loadSpinner={this.loadSpinner}
                                go={this.go}
                                goBack={this.goBack}
                                nameInfoPage={nameInfoPage}
                                lastDataStory={lastDataStory}
                              />
                              <Payment
                                id="Payment"
                                data={data}
                                goBack={this.goBack}
                              />
                          </View>
                      </ConfigProvider>
                  </Online>
                )}
                <Offline>
                    <ConnectionLoss
                        id="ConnectionLoss"
                        data={data}
                        go={this.go}
                    />
                </Offline>
            </Fragment>
        );
    }
}

export default (connectRedux(
    state => ({
        amountFavorites: state.favorites.amountFavorites,
        amountMessages: state.chat.amountMessages
    }),
    dispatch => ({
        onSetFavorites: (value) => {
            dispatch(setFavorites(value));
        },
        onGetFavorites: () => {
            dispatch(getFavorites());
        },
        onGetMessages: () => {
            dispatch(getAmountMessage());
        }
    }),
)(App));
