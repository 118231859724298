import React from 'react';
import {Spinner, Panel} from '@vkontakte/vkui';
import {connect} from 'react-redux';

class Preloader extends React.Component {
    render() {
        return (
            <Panel id="Preloader">
                <div className="preloaderWrap">
                  <div>
                    <Spinner size="large" style={{color: '#ffffff'}}/>
                    <div className="textSpinner">Идёт загрузка...</div>
                  </div>
                </div>
            </Panel>
        );
    }
}

export default (connect(
    state => ({
        isPreloader: state.main.isPreloader,
    }),
)(Preloader));