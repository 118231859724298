import React  from 'react';

import ShopsMapLayout from './map/ShopsMapLayout';
import ShopsList from './list/ShopsList';
/*address: "г.Ульяновск, ул. Орлова 10а"
avatar: "https://flawery.ru/imgbig.php?f=./upload/user/2169/avatar.jpg"
delivery_price: "300 руб."
id: "2169"
img: "https://flawery.ru/imgbig.php?f=./upload/shop.jpg"
lat: "50.6181501"
lng: "26.2782417"
min_price: "850.00"
open: 1
rating: "5.0"
title: "Салон цветов Катрин"
today_delivery_time: "с 08:00 до 20:00"
today_work_time: "с 08:00 до 20:00"*/
const ShopInMapLayout = (props) => {
  const { shopList, displayFormat } = props;
  if (shopList.length !== 0) {
    if (displayFormat === 'map') {
      return (
        <ShopsMapLayout
          {...props}
        />
      );
    }

    return (
      <ShopsList
        {...props}
      />
    );
  }

  return null;
};

export default ShopInMapLayout;