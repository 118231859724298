const initialState = {
    amountMessages: 0
};

export default function chat(state = initialState, action) {
    const value = action.payload;
    switch (action.type) {
        case 'COUNT_MESSAGE': {
            return {
                ...state,
                amountMessages: state.amountMessages + value,
            };
        }
        case 'SET_MESSAGES': {
            return {
                ...state,
                amountMessages: value,
            };
        }
        default:
    }
    return state;
}
