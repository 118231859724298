import React from 'react';

const Search = ({value, name, onChange, placeholder, onKeyDown}) => {
    return (
        <div className="searchWrap">
            <input
                id='inputSearch'
                className="searchField"
                name={name}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                onKeyDown={onKeyDown}
            />
        </div>
    );
};

export default Search;
