import React from 'react';
import {Cell, Group, HeaderButton, IOS, List, Panel, platform} from '@vkontakte/vkui';
import Icon24Done from '@vkontakte/icons/dist/24/done';
import Search from '../utils/Search';
import PanelHeader from '../utils/PanelHeader';
import LeftMenu from "../utils/LeftMenu";

import ButtonIcon from "../utils/ButtonIcon";

export default function SelectCityLayout(props) {
    const {id, go, cityList, changeCity, search, onChange, prevState, isUploaded} = props;
    console.log('prevState', prevState);
    return (
        <Panel id={id}>
            <PanelHeader bigSelectCity
                //              left={
                //     <ButtonIcon type="back" onClick={() => go(`${prevState}`)}/>
                // }
            >
                <Search value={search} onChange={onChange} placeholder="Поиск города"/>
            </PanelHeader>
            <Group className='mt-0'>
                <List>
                    {!!cityList ? cityList.map(value => (
                            <Cell
                                key={value[0]}
                                onClick={() => {
                                    changeCity(value[1], value[0]);
                                    //selectedCity
                                }}
                                asideContent={
                                    value[1] === localStorage.getItem('nameCity') ? (
                                        <Icon24Done fill="var(--accent)"/>
                                    ) : null
                                }
                            >
                                {value[1]}
                            </Cell>
                        )) :
                        isUploaded && <Cell style={{display: 'flex', justifyContent: 'center'}}>
                            В данном городе нет доставки
                        </Cell>
                    }
                </List>
            </Group>
        </Panel>
    );
}
