import React, {Fragment} from 'react';
import {Button, FormStatus, Tooltip, Input} from '@vkontakte/vkui';
import InputMask from 'react-input-mask';


export default function WinAuth(props) {
    console.log('typeAuth', typeAuth);

    const {
        typeAuth, changeAuth, validFromServer, closeTooltip, goAuth, sendSms, onChange, phone, email, sendSmsEmail,
        code, disabledSend, go, goBack, focusPhone, focusEmail, disabledAuth
    } = props;

    console.log('typeAuth', typeAuth)
    return (
        <Fragment>
            <button className={typeAuth === 'phone' ? 'btnAuthActive p-0 unselectable' : 'btnAuth p-0 unselectable'}
                    onClick={(e) => changeAuth(e, 'phone')}>Телефон
            </button>
            <button className={typeAuth === 'email' ? 'btnAuthActive p-0 unselectable' : 'btnAuth p-0 unselectable'}
                    onClick={(e) => changeAuth(e, 'email')}>E-mail
            </button>

            <div className='formAuthContainer'>

                {typeAuth === 'phone' ?
                    <div>
                        <div className="d-flex flex-row fieldInput mt-4 unselectable">
                            <InputMask
                                onChange={onChange}
                                value={phone}
                                onFocus={focusPhone}
                                mask="+7 (999) 999-99-99"
                                maskChar=" ">
                                {() => (
                                    <input
                                        name='phone'
                                        className='w-100 inputAuth'
                                        type="tel"
                                        placeholder="+7 "
                                    />
                                )}
                            </InputMask>
                            <Tooltip
                                text={validFromServer.msgError}
                                isShown={validFromServer.isValidSendField}
                                onClose={closeTooltip}
                                offsetX={-280}
                                cornerOffset={10}
                                offsetY={10}>
                                <button
                                    onClick={sendSms}
                                    className="btnDone ml-3 unselectable"
                                    disabled={disabledSend.phone}>
                                    ✓
                                </button>
                            </Tooltip>
                        </div>
                        <Tooltip
                            text={validFromServer.msgError}
                            isShown={validFromServer.isConfirmCode}
                            onClose={closeTooltip}
                            offsetX={0}
                            cornerOffset={10}
                            offsetY={10}
                        >
                            <div className='fieldInput'>
                                <input name='code' type="tel" value={code} onChange={onChange}
                                       placeholder="Введите код подтверждения"
                                       className='inputAuth raz'/>
                            </div>
                        </Tooltip>
                        <Button disabled={disabledAuth.phone || code.length <= 3} className="btnSignIn unselectable" size="xl" onClick={goAuth}
                                data-to="EpicNavigation unselectable">Войти</Button>
                    </div>
                    :
                    <div>
                        <div className="d-flex flex-row fieldInput mt-4">
                            <input onFocus={focusEmail} name='email' value={email} type='email' onChange={onChange}
                                   className="w-100 inputAuth"
                                   placeholder="Введите e-mail"/>
                            <Tooltip
                                text={validFromServer.msgError}
                                isShown={validFromServer.isValidSendField}
                                onClose={closeTooltip}
                                offsetX={-280}
                                cornerOffset={10}
                                offsetY={10}>
                                <button
                                    onClick={sendSmsEmail}
                                    className="btnDone ml-3 unselectable"
                                    disabled={disabledSend.email}>
                                    ✓
                                </button>
                            </Tooltip>
                        </div>
                        <Tooltip
                            text={validFromServer.msgError}
                            isShown={validFromServer.isConfirmCode}
                            onClose={closeTooltip}
                            offsetX={0}
                            cornerOffset={10}
                            offsetY={10}>
                            <div className='fieldInput'>
                                <input className='inputAuth raz' type="tel" value={code} name='code' onChange={onChange}
                                       placeholder="Введите код подтверждения"/>
                            </div>
                        </Tooltip>
                        <Button disabled={disabledAuth.email || code.length <= 3} className="btnSignIn unselectable" size="xl" onClick={goAuth}
                                data-to="EpicNavigation unselectable">Войти</Button>

                    </div>
                }
                <div className='mb-2'>
                    <button onClick={go} data-to="StaticInfoPage" name="pagePrivacy"
                            className="сonfidentiality unselectable"><u>
                        Положение о
                        конфиденциальности</u>
                    </button>
                </div>
            </div>
        </Fragment>
    )

}