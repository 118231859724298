import React from 'react';
import {Panel, Div} from '@vkontakte/vkui';
import PanelHeader from '../utils/PanelHeader';
import '../../scss/MainStyle.scss';
import BouquetCard from '../utils/BouquetCard';


export default function LikesLayout({id, go, favoritesList, gridView, changeGrid, deleteFavorites}) {
    return (
        <Panel id={id}>
            <PanelHeader>
                Избранные
            </PanelHeader>
            <div className='m-3'>
                <Div className="blockInfoCategory p-0">
                    <div className='mb-1'>
                                        <span
                                            className='titleCategoryBouquet'>Избранные ({Object.keys(favoritesList).length})</span>
                    </div>
                    <div>
                        <div className='pl-0 d-flex justify-content-end'>
                            {<div className={gridView === 'list' ? 'gridTypeViewIcon active' : 'gridTypeViewIcon'}
                                  onClick={() => changeGrid('list')}/>
                            }
                            {<div className={gridView === 'scroll' ? 'listTypeViewIcon active' : 'listTypeViewIcon'}
                                  onClick={() => changeGrid('scroll')}/>}
                        </div>
                    </div>
                </Div>
                <div className={gridView === 'list' ? 'row rowWrap' : 'row rowWrapList'}>
                    {Object.keys(favoritesList).map((key, index) => (
                        <BouquetCard
                            key={key}
                            go={go}
                            index={index}
                            value={favoritesList[key]}
                            changeFavorite={deleteFavorites}
                            gridView={gridView}
                            urlBack='Likes'
                        />
                    ))}
                </div>
            </div>
        </Panel>
    );
}
