import React from 'react';
import {
    Div,
    Group,
    HeaderButton,
    IOS,
    Panel,
    platform
} from '@vkontakte/vkui';

import '../../scss/MainStyle.scss';

import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back';
import Icon24Back from '@vkontakte/icons/dist/24/back';
import WinAuth from './WinAuth';

const osname = platform();
export default function AuthorizationForm(props) {
    const {id} = props;


    return (
        <Panel id={id}>
            <div className="AuthStyle m-0">
                <Div className="HeaderAuth">
                    <HeaderButton
                        className="btnAuthBack unselectable"
                        onClick={() => {
                            props.goBack();
                        }}
                        data-to="EpicNavigation"
                    >
                        {osname === IOS ? <Icon28ChevronBack/> : <Icon24Back/>}
                    </HeaderButton>
                </Div>
                <Div className="windowAuth">
                    <WinAuth
                        {...props}
                    />
                </Div>
            </div>
        </Panel>
    );
}
