import React from 'react';
import '../../scss/MainStyle.scss';
import '../../scss/_orders-list.scss';
import freeDelivery from "../../img/free-delivery.png";
import moment from 'moment';
import "moment/locale/ru";

export default function OrderCardForm(props) {
    const {value, index, go} = props;
    moment.locale('ru');
    const date = moment(new Date(value.time * 1000)).format('LLL');
    return (
        <div key={index} onClick={(e) => go(e, {idOrder: value.id})} data-story="OrderInformation" className="orderMainBlock">
            <div className="card d-flex ml-2 mr-2 mt-1 mb-1 orderCard">
                <div className='d-flex row m-2 numberOrder'>
                    <div className='d-flex col-8 p-0'>
                        <div className='orderTime'>{date}</div>
                    </div>
                    <div className='d-flex col-4 p-0 justify-content-end'>
                        <p className='orderId mb-0'># {value.id}</p>
                    </div>
                </div>
                <div className="orderBodyCard  m-2">
                    <img alt='orderImg' className="orderImg" src={value.img}/>
                    <div className="ml-2 orderDescription">
                        <div className='ml-2 mt-2 orderTitle mb-0'>
                            {value.title.replace(/&quot;/g, '"')}
                        </div>
                        <div className='ml-2 orderStructure'>
                            <b>Состав: </b>
                            {value.flowers.replace(/&quot;/g, '"')}
                        </div>
                        <div className='ml-2 orderPrice'>
                            {Math.round(value.price).toLocaleString()}
                            <span className="rub">&#8381;</span>
                        </div>
                        <div className='ml-2 orderStatus' style={{background: value.status_color}}>
                            {value.status_title}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}