import React from 'react';
import {Panel} from '@vkontakte/vkui';
import LeftMenu from '../utils/LeftMenu';
import PanelHeader from '../utils/PanelHeader';
import PromoCode from '../utils/PromoCode';
import '../../scss/_promocode-page.scss';



export default function PromoCodeLayout(props) {
    const {id, go, goAuth, goForward, promoCodeList, openedPromoCode, openPromoCodeDescription} = props;
    return (
        <Panel id={id}>
            <PanelHeader left={
                <LeftMenu go={go} goForward={goForward} goAuth={goAuth}/>
            }>
                Промокоды
            </PanelHeader>
            {!!promoCodeList && Object.keys(promoCodeList).map((key, index) => (
                <div key={index} className="promoCodeCardForm" onClick={() => openPromoCodeDescription(key)}>
                    <div className='promoCodeTextDescription'>
                        {promoCodeList[key]}
                    </div>
                    {openedPromoCode === key ?
                        <PromoCode name='inputPromocode' value={key}/>
                    :
                        null
                    }
                </div>
            ))
            }
        </Panel>
    );
}